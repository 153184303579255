import { ExternalLink } from 'components';

import { ReactComponent as TwitterLogo } from 'assets/ic_sns_twitter.svg';
import { ReactComponent as TelegramLogo } from 'assets/ic_sns_telegram.svg';
import { ReactComponent as GitHubLogo } from 'assets/ic_sns_github.svg';
import { ReactComponent as MediumLogo } from 'assets/ic_sns_medium.svg';

import * as styles from './Footer.style';


const SocialMedias = [
  { Icon: TwitterLogo, name: 'Twitter', href: 'http://twitter.com/pylon_protocol' },
  { Icon: TelegramLogo, name: 'Telegram', href: 'https://t.me/pylon_protocol' },
  { Icon: GitHubLogo, name: 'GitHub', href: 'https://github.com/pylon-protocol' },
  { Icon: MediumLogo, name: 'Medium', href: 'https://medium.com/terra-money/tagged/pylon-protocol' },
];

const Outlinks = [
  { name: 'Documentation', href: 'https://docs.pylon.money' },
  { name: 'Forum', href: 'https://forum.pylon.money' },
  { name: 'Website', href: 'https://pylon.money' },
  { name: 'App', href: 'https://app.pylon.money' },
];


const Footer = () => (
  <styles.Container>
    <styles.Grid>
      <styles.SNSList>
        {SocialMedias.map(({ Icon, name, href }) => (
          <li key={name}>
            <ExternalLink to={href}>
              <Icon />
            </ExternalLink>
          </li>
        ))}
      </styles.SNSList>
      <styles.OutlinkList>
        {Outlinks.map(({ name, href }) => (
          <li key={name}>
            <ExternalLink to={href}>
              {name}
            </ExternalLink>
          </li>
        ))}
      </styles.OutlinkList>
    </styles.Grid>
  </styles.Container>
);

export default Footer;
