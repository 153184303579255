import PsiBdpTokenLogo from 'assets/logo/psi-bdp-token.png';
import { Backdrop, DisclaimerBlock } from 'components';
import * as styles from 'components/tx-broadcasting-modal/TxBroadcastingModal.style';
import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
  onClose: () => void;
}

const BDPTokenDisclaimerModal = ({ onClose }: Props) => {
  return (
    <>
      <styles.Container>
        <styles.Modal>
          <styles.Header
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <p css={styles.title}>Disclaimer</p>
            <DpTokenLogo alt="Nexus Liquid" src={PsiBdpTokenLogo} />
          </styles.Header>
          <DisclaimerBlock disclaimerField="Please Note">
            When you deposit UST to Liquid Nexus Pool for $Psi, you will receive
            the bPsiDP-24m token, which represents ownership over the deposited
            UST in the Liquid Pylon Pool for $Psi with the accruing $Psi token
            rewards. By trading the bPsiDP-24m token, you are also trading the
            right to claim both the $Psi token rewards and the deposited UST
            into the Liquid Nexus Pool for $Psi at the maturity of the pool.
          </DisclaimerBlock>
          <styles.CloseButton
            type="button"
            intent="success"
            onClick={() => {
              const hallswapLink =
                'https://coinhall.org/charts/terra/terra167gwjhv4mrs0fqj0q5tejyl6cz6qc2cl95z530';
              const page = window.open(hallswapLink, '_blank');
              page?.focus();
              onClose();
            }}
          >
            Move to Hallswap
          </styles.CloseButton>
          <styles.CloseButton
            type="button"
            intent="success"
            onClick={onClose}
            css={css`
              background-color: transparent;
              border: 1px solid #00eefa;
              color: #00eefa;
            `}
          >
            Close
          </styles.CloseButton>
        </styles.Modal>
      </styles.Container>
      <Backdrop />
    </>
  );
};

export default BDPTokenDisclaimerModal;

const DpTokenLogo = styled.img`
  width: 128px;
  height: 128px;
`;
