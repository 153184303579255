import { Tooltip } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { toFixed } from 'utils';

import * as styles from './TokenPageSwapInfo.style';

const DatetimeFormat = 'YYYY MMM DD HH:mm[Z]';

interface Props {
  symbol: string;
  isSwapStarted?: boolean;
  isLockupFinished?: boolean;
  totalSold: number;
  totalSaleAmount: number;
  finishDate: Date;
  vestingFinishDate?: Date;
  lockupFinishDateFromApi: string; // TODO: distribution 시간과 분리
  minRequirement?: number;
  minRequirementInMine?: number;
  maxAllocation?: number | 'Unlimited';
  maxAllocationDescription?: string;
}

const TokenPageSwapInfo = ({
  symbol,
  isSwapStarted = false,
  isLockupFinished = false,
  totalSold,
  totalSaleAmount,
  finishDate,
  vestingFinishDate,
  lockupFinishDateFromApi,
  minRequirement,
  minRequirementInMine,
  maxAllocation,
  maxAllocationDescription,
}: Props) => {
  const lockUpFinishDate = dayjs.utc(finishDate);

  const percent = totalSold && totalSaleAmount && totalSold / totalSaleAmount;

  const isSoldout = totalSold >= totalSaleAmount;

  const isDetailVisible = isSwapStarted && !isSoldout && !isLockupFinished;

  return (
    <div css={styles.container}>
      <div css={styles.infoContainer}>
        <div>
          <p css={styles.subheading}>Tokens Swapped</p>
          <p>
            {totalSold.toLocaleString()}
            {isDetailVisible && ` / ${totalSaleAmount.toLocaleString()}`}
            {isDetailVisible && (
              <span css={styles.distributionPercent}>
                {`(${toFixed(percent * 100, 1)}%)`}
              </span>
            )}
          </p>
        </div>
        <div>
          <p css={styles.subheading}>Lock-up Finish Date</p>
          <p>
            {symbol.toLowerCase() !== 'psi' ? (
              lockUpFinishDate.format(DatetimeFormat)
            ) : (
              <>
                {`25% at ${dayjs
                  .utc(lockupFinishDateFromApi)
                  .format(DatetimeFormat)}`}
                <br />
                {`75% at ${dayjs
                  .utc(vestingFinishDate)
                  .format(DatetimeFormat)}`}
              </>
            )}
          </p>
        </div>
      </div>
      <div css={styles.infoContainer}>
        {!!minRequirement && (
          <div>
            <p css={styles.subheading}>Minimum Requirement</p>
            <p>
              <span css={styles.tooltipText}>
                {`$${minRequirement.toLocaleString()} UST`}
                {!!minRequirementInMine &&
                  ` / ${minRequirementInMine.toLocaleString()} MINE`}
              </span>
              {!maxAllocation && maxAllocationDescription && (
                <Tooltip>{maxAllocationDescription.split('\n')}</Tooltip>
              )}
            </p>
          </div>
        )}
        {typeof maxAllocation !== 'undefined' && (
          <div>
            <p css={styles.subheading}>Available Allocation</p>
            <p>
              <span css={styles.tooltipText}>
                {maxAllocation === 'Unlimited'
                  ? 'Unlimited'
                  : `$${maxAllocation?.toLocaleString()} UST`}
              </span>
              {maxAllocationDescription && (
                <Tooltip>{maxAllocationDescription.split('\n')}</Tooltip>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenPageSwapInfo;
