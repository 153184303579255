import { css, SerializedStyles } from '@emotion/react';


export const globalStyle = css`
  html,
  body {
    background: linear-gradient(0deg, #000000 0%, #0852f6 100%),
      linear-gradient(180deg, #01d9f7 0%, #0d72fd 100%),
      linear-gradient(0deg, #171717, #171717);
  }
`;

export const pageBackground: Record<string, SerializedStyles> = {
  loop: css`
    html,
    body {
      background: radial-gradient(
          92.83% 92.83% at 19.13% 9.68%,
          rgba(250, 44, 171, 0.33) 0%,
          rgba(18, 4, 16, 0) 100%
        ),
        linear-gradient(0deg, #120410, #120410), #ffffff;
    }
  `,
  twd: css`
    html,
    body {
      background: linear-gradient(0deg, #09c9e5 0%, #000000 100%, #0b031b 100%);
    }
  `,
  psi: css`
    html,
    body {
      background: linear-gradient(0deg, #ffabbf 14.58%, #0b0023 100%);
    }
  `,
  vkr: css`
    html,
    body {
      background: radial-gradient(
          92.83% 92.83% at 19.13% 9.68%,
          rgba(255, 230, 70, 0.2) 0%,
          rgba(18, 4, 16, 0) 100%
        ),
        linear-gradient(0deg, rgb(26 26 30), rgb(18, 4, 16));
    }
  `,
  orion: css`
    html,
    body {
      background:
        radial-gradient(
          107.24% 294.23% at 36.09% 2.34%,
          rgba(30, 82, 48, 0.88) 0%,
          rgba(0, 2, 1, 0) 100%
        ),
        linear-gradient(
          268.2deg,
          #0E4D70 2.08%,
          #13526A 96.11%
        );
    }
  `,
  whale: css`
    html,
    body {
      background: linear-gradient(172.23deg, #191A1A 10.56%, #284C32 54.98%, #1F3525 100.33%);
    }
  `,
  glow: css`
    html,
    body {
      background: linear-gradient(179.71deg, #5C31B1 0.27%, #412381 32.96%, #150D30 99.77%);
    }
  `,
  sayve: css`
    html,
    body {
      background: linear-gradient(180deg, #191919 0%, rgba(32, 49, 37, 0) 100%), linear-gradient(180deg, #9BB069 0%, #5FA75A 100%);
    }
  `,
  xdefi: css`
    html,
    body {
      background: linear-gradient(172.23deg, #132573 10.56%, #1F45DA 54.98%, #132577 100.33%);
    }
  `,
  gfi: css`
    html,
    body {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), radial-gradient(77.95% 69.38% at 92.67% 5.97%, rgba(0, 111, 194, 0.1072) 0%, rgba(60, 65, 255, 0.4757) 100%), radial-gradient(79.63% 77.06% at 44.31% 22.94%, #2BEFEF 0%, #A1AFF7 57.25%, #0070C3 100%);
    }
  `,
  arts: css`
    html,
    body {
      background: radial-gradient(50.77% 50.77% at 50% 42.31%, rgba(226, 144, 110, 0.1) 26.09%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(180deg, #12012B 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%, #2C0427 100%), #371000;
    }
  `,
  wcoin: css`
    html,
    body {
      background: linear-gradient(180deg, #12012B 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%, #2C0427 100%), radial-gradient(98.93% 98.93% at 50% 100%, #6D61AA 0%, #82D0CB 100%);
    }
  `,
};


export const loadingSpinner = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const container = css`
  display: grid;
  grid-column-gap: 96px;
  grid-template-columns: 1fr 1fr;

  max-width: 1600px;
  min-height: 100vh;

  margin: 0 auto;
  padding: 144px 8% 160px;

  @media all and (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-column-gap: 48px;
  }

  @media all and (max-width: 1024px) {
    padding: 144px 5% 400px;
  }

  @media all and (max-width: 840px) {
    display: block;
    padding: 144px 5% 120px;
  }
`;

export const introductionSection = css`
  @media all and (max-width: 640px) {
    margin-bottom: 84px;
  }
`;

export const tradeSection = css`
  min-width: 450px;
  padding-top: 114px;

  @media all and (max-width: 1024px) {
    min-width: auto;
    padding-top: 118px;
  }

  @media all and (max-width: 640px) {
    min-width: auto;
    padding-top: 0;
  }
`;

export const sideContainer = css`
  margin-bottom: 72px;

  @media all and (max-width: 1024px) {
    margin-bottom: 36px;
  }
`;

export const disclamer = css`
  margin-bottom: 8px;
`;

export const sideHeading = css`
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  @media all and (max-width: 1024px) {
    margin-bottom: 12px;

    font-size: 24px;
    line-height: 24px;
  }

  @media all and (max-width: 840px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const disclaimer = css`
  margin-bottom: 16px;
`;
