import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  @media all and (max-width: 640px) {
    height: 36px;
  }
`;

export const TabBase = styled.button<{
  current?: boolean;
}>(({ current, disabled }) => `
  position: relative;
  padding: 0px 12px;
  height: 30px;

  font-size: 20px;
  line-height: 1;
  color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);

  transition: .12s all ease;

  ${disabled ? `
    cursor: not-allowed;
  ` : ''}

  @media all and (max-width: 640px) {
    font-size: 16px;
  }
`);

export const Label = styled.div<{
  current?: boolean;
}>(
  ({ current }) => `
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  font-size: 20px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.33);

  transition: .12s all ease;
  z-index: 1;

  @media all and (max-width: 640px) {
    font-size: 16px;
  }

  ${current ? `
    color: white;
  ` : ''}
`,
);

export const FocusArea = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 30px;
  margin-bottom: -1px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.66);
`;
