import { LogoMap } from 'utils/logoMap';

import styled from '@emotion/styled';

interface Props {
  symbol: string;
}

const Image = styled.img`
  display: block;
  height: 80px;

  margin-bottom: 36px;

  @media all and (max-width: 640px) {
    height: 60px;

    margin-bottom: 24px;
  }
`;

const TokenPageLogo = ({ symbol: baseSymbol }: Props) => {
  const symbol = baseSymbol.toLowerCase();
  const src = LogoMap[symbol];

  if (!src) {
    return null;
  }

  return <Image alt={`${symbol} logo`} src={src} />;
};

export default TokenPageLogo;
