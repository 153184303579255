import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import Color from 'color';

import { Button } from 'components';


type CommonProps = {
  tier: number;
};


const colorByTiers: Record<number, string> = {
  0: '#00EEFA',
  1: '#0CFADF',
  2: '#00FFB4',
};


export const Container = styled.div<CommonProps>(({
  tier,
}) => {
  const color = colorByTiers[tier];
  const rgba = Color(color).alpha(0.2).rgb().string();

  return `
    display: flex;
    padding: 28px 24px;
    margin-bottom: 16px;

    border-radius: 8px;
    background: linear-gradient(0deg, ${rgba}, ${rgba}), rgba(255, 255, 255, 0.1);

    .tier__symbol {
      flex-shrink: 0;

      width: auto;
      height: 24px;
      margin-right: 10px;

      path {
        fill: ${colorByTiers[tier]};
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media all and (max-width: 840px) {
      background: rgba(0, 0, 0, 0.33);
    }

    @media all and (max-width: 640px) {
      display: block;
      padding: 24px 20px 16px;
      margin-bottom: 10px;

      .tier__symbol {
        height: 20px;
        margin-bottom: 6px;
      }
    }
  `;
});

export const DepositButton = styled(Button)<CommonProps>(({
  tier,
  disabled,
}) => `
  background: ${colorByTiers[tier]};
  padding: 0;

  ${disabled ? `
    opacity: 0.5;
  ` : ''}

  @media all and (max-width: 640px) {
    width: 100% !important;
  }
`);

export const CampaignButton = styled(Button)<CommonProps>(({
  disabled,
}) => `
  padding: 0;
  background-color: #ffdf60;

  ${disabled ? `
    opacity: 0.5;
  ` : ''}

  @media all and (max-width: 640px) {
    width: 100% !important;
  }
`);


export const contentContainer = css`
  flex-grow: 1;
`;

export const infoContainer = css`
  display: flex;

  margin-bottom: 16px;

  @media all and (max-width: 640px) {
    display: block;
  }
`;

export const info = css`
  flex-grow: 1;
  margin-right: 30px;

  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);

  @media all and (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 12px;

    font-size: 14px;
    line-height: 18px;
  }
`;

export const tierTitle = css`
  margin-bottom: 8px;

  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: white;

  @media all and (max-width: 640px) {
    margin-bottom: 4px;

    font-size: 20px;
    line-height: 20px;
  }
`;

export const apyContainer = css`
  display: flex;
  align-items: center;

  > div {
    flex-grow: 1;
    flex-shrink: 0;

    &:last-of-type {
      text-align: right;
    }
  }
`;

export const detailsButton = css`
  display: flex;
  align-items: center;

  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);

  span {
    margin-right: 5px;
  }

  @media all and (max-width: 640px) {
    font-size: 13px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.5);

    path {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const caret = css`
  transition: .25s all ease;
`;

export const caretRotated = css`
  transform: rotate(180deg);
`;

export const apy = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;

  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const apyText = css`
  margin-right: 8px;
`;


export const variants = {
  initial: {
    height: 0,
    opacity: 0,
    marginTop: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    marginTop: '8px',
    transition: { duration: 0.12 },
  },
  exit: {
    height: 0,
    opacity: 0,
    marginTop: 0,
    transition: { duration: 0.12 },
  },
};

export const DetailContainer = styled(motion.div)`
  overflow: hidden;
`;

export const detailList = css`
  display: flex;
  padding-right: 36px;

  font-size: 14px;
  line-height: 14px;

  li {
    flex-shrink: 0;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media all and (max-width: 640px) {
    display: block;
    padding: 0;

    li {
      display: block;
      margin-bottom: 8px;
    }
  }
`;

export const detailTokenDistributedColumn = css`
  flex-grow: 1;
  flex-shrink: none;
`;

export const detailHeading = css`
  margin-bottom: 8px;

  font-weight: 500;
  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    margin-bottom: 4px;
  }
`;

export const availableAllocationContainer = css`
  display: flex;
  align-items: center;
  font-weight: 500;
`
export const availableAllocation = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.8);
`
export const availableAllocationField = css`
  margin-left: 6px;
  margin-right: 8px;

  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.66);
`

export const campaignButtons = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`
