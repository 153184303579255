import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DisclaimerBlock } from 'components';
import React, { useMemo } from 'react';

import * as styles from './StakeInfo.style';


const getNumberOfTickets = (stakedAmount: number, exchangeRatio: number) =>
  Math.floor(stakedAmount / exchangeRatio);

const formatAmount = (amount: number) =>
  (Math.trunc(amount * 100) / 100).toLocaleString()

export type Props = {
  stakedAmount?: number,
  totalStaked?: number,
  stakingTokenSymbol?: string,
  exchangeRatio?: number,
  showTickets?: boolean,
}

const StakeInfo: React.FC<Props> = ({
  stakedAmount = 0,
  totalStaked = 0,
  stakingTokenSymbol,
  exchangeRatio = 0,
  showTickets,
}) => {
  const [numberOfTickets, totalNumberOfTickets] = useMemo(() => [
    getNumberOfTickets(stakedAmount, exchangeRatio),
    getNumberOfTickets(totalStaked, exchangeRatio),
  ], [stakedAmount, totalStaked, exchangeRatio])

  // const chanceOfWinning = useMemo(() => (
  //   numberOfTickets < 1
  //     ? 0
  //     : numberOfTickets / totalNumberOfTickets * 100
  // ), [numberOfTickets, totalNumberOfTickets])

  const optionalDollarSign = stakingTokenSymbol === 'ust' ? '$' : ''

  return (
    <div css={styles.container}>
      <div css={styles.infoContainer}>
        <div>
          <p css={styles.subheading}>
            {stakingTokenSymbol === 'mine'
              ? 'Gov-Staked Amount'
              : 'Staked Amount'}
          </p>
          <p>
            <InlineBlock>
              {`${optionalDollarSign}${formatAmount(stakedAmount)} ${stakingTokenSymbol?.toUpperCase()}`}
            </InlineBlock>
            {showTickets && (
              <React.Fragment>
                <br />
                <InlineBlock style={{ color: 'rgba(255,255,255,0.7)'}}>
                  {`(${numberOfTickets.toLocaleString()} Tickets)`}
                </InlineBlock>
              </React.Fragment>
            )}
          </p>
        </div>
        <div>
          <p css={styles.subheading}>
            Total Staked
          </p>
          <p>
            <InlineBlock>
              {`${optionalDollarSign}${formatAmount(totalStaked)} ${stakingTokenSymbol?.toUpperCase()}`}
            </InlineBlock>
            {showTickets && (
              <React.Fragment>
                <br />
                <InlineBlock style={{ color: 'rgba(255,255,255,0.7)'}}>
                  {`(${totalNumberOfTickets.toLocaleString()} Tickets)`}
                </InlineBlock>
              </React.Fragment>
            )}
          </p>
        </div>
      </div>
      {showTickets && (
        <div css={styles.infoContainer}>
          <div>
            <p css={styles.subheading}>
              Minimum Increment
            </p>
            <p>
              {`${optionalDollarSign}${exchangeRatio.toLocaleString()} ${stakingTokenSymbol?.toUpperCase()}`}
            </p>
          </div>
        </div>
      )}
      {showTickets && (
        <DisclaimerBlock>
          {`Deposits of less than $${exchangeRatio.toLocaleString()} ${stakingTokenSymbol?.toUpperCase()} are not reflected as tickets.`}
        </DisclaimerBlock>
      )}
      {stakingTokenSymbol === 'ust' && (
        <DisclaimerBlock css={showTickets && css`
          margin-top: 12px;
        `}>
          {`The ticket for lottery is reflected by calculating the amount and time period deposited.

          If you withdraw ${stakingTokenSymbol?.toUpperCase()} in the middle or make a deposit right before NFT drop, it would not be reflected for the upcoming NFT drop.`}
        </DisclaimerBlock>
      )}
      {stakingTokenSymbol === 'mine' && (
        <DisclaimerBlock css={[css`
          background-color: rgba(255, 15, 73, 0.24);
          border-color: rgba(255, 15, 73, 0.35);
          color: rgba(255, 255, 255, 0.45);
        `,
        showTickets && css`
          margin-top: 12px;
        `]}>
          {'In accordance with governance proposal #7, there has been implemented a 14 day undelegation period for MINE unstaking.'}
        </DisclaimerBlock>
      )}
    </div>
  );
};

export default StakeInfo;

const InlineBlock = styled.span`
  display: inline-block;
`;
