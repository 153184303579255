import React, { useMemo } from 'react';
import { Terrascope } from 'utils/terrascope';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Draw } from '@pylon-protocol/sdk/lib/pylon-api';

type PreviousLotteryCardProps = Draw & {
  nftSymbol: string;
  variant?: 'primary' | 'secondary';
  chainID?: string;
};

const PreviousLotteryCard: React.FC<PreviousLotteryCardProps> = ({
  nftSymbol,
  variant = 'primary',
  at: drawAt,
  chainID = 'columbus-5',
  ...drawInfo
}) => {
  const { owner, name, image } = drawInfo.nft ?? {};

  const terrascopeAddressLink = useMemo(
    () => (!!owner ? Terrascope.getAddressLink(chainID, owner) : undefined),
    [chainID, owner],
  );

  return (
    <Container variant={variant}>
      <Image
        nftSymbol={nftSymbol}
        src={`https://cloudflare-ipfs.com/ipfs/${image?.replace(
          'ipfs://',
          '',
        )}`}
      />
      {nftSymbol === 'lunabulls' && (
        <Image
          nftSymbol={nftSymbol}
          src="https://cloudflare-ipfs.com/ipfs/QmdJQub8TRScEKp2VbRaPyLLDHDMCMEtnczKq2t5eahyzP"
          style={{ marginLeft: 10 }}
        />
      )}
      <Information>
        <div>
          <Title>Previous Lottery</Title>
          <Name>{name}</Name>
        </div>
        {!!owner && (
          <DetailContainer className="winner">
            <DetailField>Winner</DetailField>
            <DetailValue
              css={css`
                text-decoration: underline;
              `}
            >
              <a href={terrascopeAddressLink} target="_blank" rel="noreferrer">
                {`${owner?.slice(0, 22)}…`}
              </a>
            </DetailValue>
            <span
              css={css`
                display: none;
              `}
            >
              {owner}
            </span>
          </DetailContainer>
        )}
        <DetailContainer className="details">
          <DetailField>Draw at</DetailField>
          <DetailValue>{drawAt}</DetailValue>
        </DetailContainer>
      </Information>
    </Container>
  );
};

export default PreviousLotteryCard;

type ContainerProps = {
  variant?: 'primary' | 'secondary';
};
const Container = styled.li<ContainerProps>`
  padding: 10px;
  height: 96px;
  border-radius: 8px;
  display: flex;

  ${({ variant }) =>
    variant === 'primary'
      ? css`
          background-color: rgba(0, 0, 0, 0.33);
        `
      : css`
          background-color: rgba(255, 255, 255, 0.15);
        `};
`;

type ImageProps = {
  nftSymbol: string;
};
const Image = styled.img<ImageProps>`
  height: 100%;
  aspect-ratio: 1;

  border-radius: 8px;
  background-color: white;

  user-select: none;
  -webkit-user-drag: none;

  ${({ nftSymbol }) =>
    nftSymbol === 'lunabulls' &&
    css`
      background: url(image.png),
        radial-gradient(90.04% 90.04% at 41.6% -5.66%, #541a2c 0%, #060506 100%);
      border: 0.73px solid #eb356d;
      box-shadow: 0px 2.92px 5.83px -1.1px rgba(235, 53, 109, 0.27);
    `};

  ${({ nftSymbol }) =>
    nftSymbol === 'terrabots' &&
    css`
      background: url(image.png),
        radial-gradient(90.04% 90.04% at 41.6% -5.66%, #17223f 0%, #0a0e1a 100%);
      border: 0.73px solid #ffffff;
      box-shadow: 0px 8px 16px -3px rgba(255, 255, 255, 0.27);
    `};
`;
const Information = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  color: #ffffff;
`;
const Name = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.66);
`;

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > span {
    font-size: 12px;
    line-height: 12px;
  }
`;
const DetailField = styled.span`
  width: 60px;
  color: rgba(255, 255, 255, 0.66);
`;
const DetailValue = styled.span`
  color: #ffffff;
`;
