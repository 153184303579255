import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ExternalLink from '../external-link';


type IntentProps = {
  intent: 'success' | 'failure';
};


export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;

  display: block;
  width: 360px;

  z-index: 210;
`;

export const Modal = styled.div`
  position: relative;

  width: 100%;
  padding: 32px 28px 30px;

  background: #010a1e;
  color: white;

  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  filter: drop-shadow(0px 20px 120px rgba(0, 0, 0, 0.6));
  transform: translate(-50%, -50%);

  overflow: hidden;
`;


export const spinner = css`
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: 1000;
`;


export const Header = styled.div`
  margin-bottom: 24px;
`;

export const title = css`
  margin-bottom: 6px;

  font-size: 18px;
  color: white;
  font-weight: 500;
`;

export const description = css`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.66);
  line-height: 20px;
`;


export const Image = styled.img`
  display: block;

  height: 225px;
  margin: 0 auto 24px;

  mix-blend-mode: color-dodge;
  pointer-events: none;
  user-select: none;
`;


export const Receipt = styled.ul`
  font-size: 14px;
  line-height: 1;

  li {
    display: grid;
    grid-template-columns: 80px 1fr;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const listHeader = css`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
`;

export const Link = styled(ExternalLink)<IntentProps>(({
  intent = 'success',
}) => `
  text-decoration: underline;

  ${intent === 'success' ? `
    color: #00eefa;
  ` : ''}

  ${intent === 'failure' ? `
    color: #ff125d;
  ` : ''}
`);


export const ErrorLog = styled.div`
  padding: 8px 12px;

  font-size: 14px;
  line-height: 18px;
  word-break: break-all;

  color: #ff125d;
  border: 1px solid rgba(255, 18, 93, 0.3);
  border-radius: 10px;
`;


export const CloseButton = styled.button<IntentProps>(({
  intent = 'success',
}) => `
  width: 100%;
  height: 48px;

  margin-top: 24px;

  font-weight: 500;
  font-size: 18px;
  line-height: 1;

  border-radius: 10px;
  transition: 0.18s opacity ease;

  &:hover,
  &:focus-visible {
    opacity: 0.66;
  }

  ${intent === 'success' ? `
    background: #00eefa;
    box-shadow: 0px 0px 8px 2px rgba(0, 238, 250, 0.3);
  ` : ''}

  ${intent === 'failure' ? `
    background: #ff125d;
    box-shadow: 0px 0px 8px 2px rgba(255, 18, 93, 0.3);
    color: white;
  ` : ''}
`);
