import React from 'react';
import ReactDOM from 'react-dom';

import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';

import { WalletProvider, getChainOptions } from '@terra-money/wallet-provider';

import Routes from './pages';
import './vendor';

const queryClient = new QueryClient();

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <WalletProvider {...chainOptions}>
            <Routes />
          </WalletProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
