import { css } from '@emotion/react';


export const emptyMessage = css`
  font-size: 16px;
  line-height: 16px;

  opacity: 0.66;

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
  }
`;
