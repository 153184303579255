import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';


interface Props {
  interval?: number;
  to?: string | Date;
  onChange?: (datetime: dayjs.Dayjs) => void;
  onEnd?: () => void;
}


const Countdown = ({
  interval = 1000,
  to,
  onChange,
  onEnd,
}: Props) => {
  const tick = useRef(0);


  useEffect(() => {
    const timer = window.setInterval(() => {
      if (!to) {
        return
      }
      const endDate = dayjs.utc(to);
      const now = dayjs.utc();

      const isAfter = now.isAfter(endDate);
      if (!tick.current && isAfter) {
        window.clearInterval(timer);
        return;
      }

      if (tick.current && isAfter) {
        onEnd?.();

        window.clearInterval(timer);
        return;
      }

      tick.current += 1;
      onChange?.(now);
    }, interval);

    return () => {
      window.clearInterval(timer);
    };
  }, [interval, onChange, onEnd, to]);


  return null;
};

export default Countdown;
