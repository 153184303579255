import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { chainIdAtom } from 'recoils/ChainIdRecoil';
import { usePylon } from 'utils';

import { CW20Token } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import { VALKYRIE_ADDRESSES } from './valkyrieConstants';

type Options = {
  skip: boolean;
};

export const useValkyrieReferral = ({ skip }: Options) => {
  const pylon = usePylon();
  const wallet = useConnectedWallet();
  const chainID = useRecoilValue(chainIdAtom) as 'columbus-5' | 'bombay-12';

  const [qualifiable, setQualifiable] = useState<boolean>(false);
  const [stakedAmount, setStakedAmount] = useState<number>(0);
  const [vkrBalance, setVkrBalance] = useState<number>(0);

  const isDataFetched = useRef<boolean>(false);

  useEffect(() => {
    if (skip) {
      return;
    }
    if (!wallet?.terraAddress || isDataFetched.current || !pylon) {
      return;
    }
    isDataFetched.current = true;

    // for condition `hasStakedAtLeast500mine`
    pylon.governance
      .stakedBalance(wallet.terraAddress)
      .then(setStakedAmount)
      .catch(console.log);

    // for condition `hasAtLeast50vkr`
    const token = new CW20Token(
      pylon.lcd,
      VALKYRIE_ADDRESSES['vkr'][chainID],
      'vkr',
    );
    token.balanceOf(wallet.terraAddress).then(setVkrBalance).catch(console.log);

    // for condition `qualifiable`
    pylon.lcd.wasm
      .contractQuery(VALKYRIE_ADDRESSES['campaign'][chainID], {
        actor: {
          address: wallet.terraAddress,
        },
      })
      .then((result: any) => {
        setQualifiable(result.participation_count === 0);
      })
      .catch(console.log);
  }, [pylon, wallet?.terraAddress, skip, chainID]);

  return {
    qualifiable,
    hasAtLeast50vkr: vkrBalance >= 50,
    hasStakedAtLeast500mine: stakedAmount >= 500,
  };
};
