import React from 'react';

import styled from '@emotion/styled';

interface Props extends React.ComponentProps<typeof DisclaimerContainer> {
  symbol?: string;
  showEstimatedWarning?: boolean;
  disclaimerField?: string;
  disclaimerPrefix?: string;
}

const DisclaimerContainer = styled.p`
  display: block;
  padding: 12px;

  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.4);

  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;

  @media all and (max-width: 640px) {
    padding: 8px 12px;
    font-size: 13px;
    line-height: 18px;
  }
`;

const DisclaimerBlock = ({
  symbol = '',
  disclaimerField = 'Disclaimer',
  disclaimerPrefix = '',
  showEstimatedWarning = false,
  children,
  ...props
}: Props) => (
  <DisclaimerContainer {...props}>
    <u>{(disclaimerPrefix ? `${disclaimerPrefix} ` : '') + disclaimerField}</u>
    {': '}
    {children ?? (
      <>
        {`${symbol} rewards may vary significantly depending on the size of total UST deposits. `}
        {showEstimatedWarning &&
          'Estimated Returns is currently calculated based on the total UST deposits remaining.'}
      </>
    )}
  </DisclaimerContainer>
);

export default DisclaimerBlock;
