import styled from '@emotion/styled';


export const Wrapper = styled.div`
  position: fixed;
  top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  list-style: none;
  z-index: 500;

  @media all and (min-width: 1200px) {
    right: calc(50% - 600px + 24px);
  }

  @media all and (max-width: 1200px) {
    right: 6%;
  }

  @media all and (max-width: 640px) {
    top: auto;
    bottom: 20px;
    width: 88%;
  }
`;
