import { Input, Modal, Spinner, TxBroadcastingModal } from 'components';
import dayjs from 'dayjs';
import { useRefetchUserInfo } from 'queries';
import { SwapDepositLog } from 'queries/useDepositLog';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { symbolAtom } from 'recoils/ProjectRecoil';
import { coin, toFixed, usePylon, useTxDispatch } from 'utils';

import { decimalToNumber } from '@pylon-protocol/sdk';
import { TxResult, useConnectedWallet } from '@terra-money/wallet-provider';

import * as styles from './TokenPageTradeConfirm.style';

type Props = {
  type: 'buy' | 'sell' | 'claim';
  info: SwapDepositLog;
  amount: number;
  onClose: () => void;
};

const TokenPageTradeConfirm = ({
  type,
  info,
  amount: propsAmount,
  onClose,
}: Props) => {
  const pylon = usePylon();
  const wallet = useConnectedWallet();

  const symbol = useRecoilValue(symbolAtom);

  const { forceUpdate } = useRefetchUserInfo();
  const { isTransacting, send } = useTxDispatch();

  const [estimatedReturns, setEstimatedReturns] = useState(-1);
  const [amount, setAmount] = useState(0);
  const [txResult, setTxResult] = useState<TxResult | undefined>(undefined);

  const swap = info.swap;

  const address = useMemo(
    () => wallet?.terraAddress ?? '',
    [wallet?.terraAddress],
  );

  const handleSubmitBuy = async () => {
    try {
      const data = await swap.purchase(address, amount);
      if (!data) {
        return;
      }

      const action = `Swap ${amount.toLocaleString()} UST to $${symbol}`;
      const options = {
        action,
        fee: Number(data.transactionFee?.amount ?? 0),
        tax: Number(data.tax?.amount ?? 0),
        callback: forceUpdate,
      };

      const result = await send(data.transactions, options);
      if (!result?.payload) {
        return;
      }

      setTxResult(result.payload);
    } catch {}
  };

  const handleSubmitSell = async () => {
    try {
      const fabricatedTransactions = await swap.withdraw(address, propsAmount);
      if (!fabricatedTransactions) {
        return;
      }

      const action = `Swap ${amount.toLocaleString()} $${symbol} to $UST`;
      const options = {
        action,
        fee: Number(fabricatedTransactions.transactionFee?.amount ?? 0),
        tax: Number(fabricatedTransactions.tax?.amount ?? 0),
        callback: forceUpdate,
        autoEstimated: true,
      };

      const result = await send(fabricatedTransactions.transactions, options);
      if (!result?.payload) {
        return;
      }

      setTxResult(result.payload);
    } catch {}
  };

  useEffect(() => {
    if (type === 'buy') {
      const exchangeRate = swap.priceInUst ?? 1;
      setEstimatedReturns(toFixed(amount * exchangeRate, 3));
      setAmount(propsAmount);
      return;
    }

    if (type === 'sell') {
      (async () => {
        // const returns = await swap.estimateUstReturnsOnWithdraw(amount);
        // setEstimatedReturns(returns);
        setAmount(propsAmount);
      })();
      return;
    }

    if (type === 'claim') {
      (async () => {
        const depositor = await swap.statusOf(address);
        setEstimatedReturns(depositor.claimableRewards);
        setAmount(depositor.claimableRewards);
      })();
    }
  }, [type, amount]); // eslint-disable-line

  const [tax, setTax] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (!pylon) {
      return;
    }
    pylon.lcd.utils
      .calculateTax(coin(propsAmount, 'uusd'))
      .then((value) => {
        const taxAsNumber = toFixed(
          decimalToNumber(value.amount.toString() ?? '0'),
          3,
        );
        setTax(taxAsNumber);
      })
      .catch(console.log);
  }, [propsAmount, pylon]);

  if (txResult) {
    return (
      <TxBroadcastingModal
        isTransacting={isTransacting}
        type={txResult?.success ? 'success' : 'failure'}
        details={txResult}
        onClose={onClose}
      />
    );
  }

  const exchangeRate = info.swap.priceInUst ?? 1;

  return (
    <Modal onClose={onClose}>
      <h1 css={styles.modalHeading}>
        {type === 'buy' && `Trade $UST to $${symbol}`}
        {type === 'sell' && `Trade $${symbol} to $UST`}
        {type === 'claim' && `Redeem $${symbol}`}
      </h1>
      <div css={styles.vestingInfoContainer}>
        {type === 'buy' &&
          `Locked up until ${dayjs
            .utc(swap.vesting[0]?.releaseFinishTime)
            .format('YYYY MMM DD')}`}
        {type === 'sell' &&
          `If you swap your $${symbol} to $UST before the initial lock-up period is concluded, you may not receive your original $UST amount in full.`}
      </div>
      <div css={styles.depositContainer}>
        <Input
          css={styles.input}
          label={type === 'buy' ? 'UST' : symbol}
          type="text"
          placeholder="0.00"
          value={amount ? amount.toLocaleString() : ''}
          disabled
        />
        {type === 'buy' && (
          <p>You can reclaim this deposit once your pledged duration ends.</p>
        )}
      </div>
      <article css={styles.feeContainer}>
        <table css={styles.feeTable}>
          <tbody>
            {type === 'buy' && (
              <tr>
                <th>Price</th>
                <td>{`${exchangeRate} ${symbol} per UST`}</td>
              </tr>
            )}
            {type !== 'claim' && (
              <tr>
                <th>Estimated Returns</th>
                <td>
                  {estimatedReturns === -1
                    ? '-'
                    : `${toFixed(estimatedReturns, 3).toLocaleString()} ${
                        type === 'sell' ? 'UST' : symbol
                      }`}
                </td>
              </tr>
            )}
            <tr>
              <th>{'Transaction Fee & Tax'}</th>
              <td>{typeof tax === 'number' ? `0.5 UST & ${tax} UST` : tax}</td>
            </tr>
          </tbody>
        </table>
      </article>
      <styles.SubmitButton
        tradeType={type}
        disabled={isTransacting || !exchangeRate || !amount}
        onClick={type === 'buy' ? handleSubmitBuy : handleSubmitSell}
      >
        {isTransacting ? (
          <Spinner color={type !== 'sell' ? '#000000' : '#ffffff'} />
        ) : (
          'Confirm'
        )}
      </styles.SubmitButton>
    </Modal>
  );
};

export default TokenPageTradeConfirm;
