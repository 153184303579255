import { css, SerializedStyles } from '@emotion/react';


export const pageBackground: Record<string, SerializedStyles> = {
  'galactic-punks': css`
    html,
    body {
      background:
        linear-gradient(0deg, #0D2027 0%, #172F3B 54.69%, #25414D 74.64%, #081A24 89.26%, #091418 100%);
    }
  `,
  'deviants-factions': css`
    html,
    body {
      background:
        linear-gradient(172.23deg, #150D11 10.56%, #782E19 45.97%, #6F4024 58.76%, #401C1C 76.36%, #0C0406 100.33%);
    }
  `,
  lunabulls: css`
    html,
    body {
      background: radial-gradient(49.98% 49.98% at 50% 50.02%, rgba(235, 53, 109, 0.1) 26.09%, rgba(0, 0, 0, 0) 100%), radial-gradient(71.25% 39.52% at 10.59% 8.54%, rgba(244, 247, 229, 0.33) 0%, rgba(0, 0, 0, 0.33) 100%, rgba(0, 0, 0, 0.33) 100%, rgba(0, 0, 0, 0) 100%), #000000;
    }
  `,
  terrabots: css`
    html,
    body {
      background: radial-gradient(49.98% 49.98% at 50% 50.02%, rgba(255, 255, 255, 0.1) 26.09%, rgba(0, 0, 0, 0) 100%), radial-gradient(74.5% 68.14% at 50% 3.37%, rgba(68, 102, 194, 0.33) 0%, rgba(0, 0, 0, 0.33) 100%, rgba(0, 0, 0, 0.33) 100%, rgba(0, 0, 0, 0) 100%), #000000;
    }
  `,
};

export const loadingSpinner = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const container = css`
  max-width: 1600px;
  min-height: 100vh;

  margin: 0 auto;
  padding: 144px 8% 160px;

  @media all and (max-width: 1024px) {
    padding: 144px 5% 400px;
  }

  @media all and (max-width: 840px) {
    padding: 144px 5% 120px;
  }
`

export const topContainer = css`
  width: 100%;
  display: grid;
  grid-column-gap: 96px;
  grid-template-columns: 1fr 1fr;

  @media all and (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-column-gap: 48px;
  }

  @media all and (max-width: 840px) {
    display: block;
  }
`;

export const introductionSection = css`
  @media all and (max-width: 640px) {
    margin-bottom: 84px;
  }
`;

export const tradeSection = css`
  min-width: 450px;
  padding-top: 114px;

  @media all and (max-width: 1024px) {
    min-width: auto;
    padding-top: 118px;
  }

  @media all and (max-width: 640px) {
    min-width: auto;
    padding-top: 0;
  }
`;

export const sideContainer = css`
  margin-bottom: 72px;

  @media all and (max-width: 1024px) {
    margin-bottom: 36px;
  }
`;

export const disclamer = css`
  margin-bottom: 8px;
`;

export const sideHeading = css`
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  @media all and (max-width: 1024px) {
    margin-bottom: 12px;

    font-size: 24px;
    line-height: 24px;
  }

  @media all and (max-width: 840px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const disclaimer = css`
  margin-bottom: 16px;
`;
