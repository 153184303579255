import axios from 'axios';
import { useQuery } from 'react-query';

type GasPriceResponse = Record<string, number>;

const DEFAULT_GAS = 0.48;
const DEFAULT_GAS_PRICE_ENDPOINT = {
  mainnet: 'https://fcd.terra.dev/v1/txs/gas_prices',
};

export const fetchGas = async () => {
  /*
  // TODO: get gasPrice from testnet fcd
  const { chainProvider } = LCD.loader();

  const endpoint = chainProvider[1].chainID.startsWith('columbus-')
    ? 'mainnet' as const
    : 'testnet' as const;
  */

  try {
    const response = await axios.get<GasPriceResponse>(
      DEFAULT_GAS_PRICE_ENDPOINT.mainnet,
    );

    return response.data?.uusd ?? DEFAULT_GAS;
  } catch {
    return DEFAULT_GAS;
  }
};

const useFetchGas = () => {
  return useQuery('@global/fcd-gas', fetchGas);
};

export default useFetchGas;
