import { Spinner } from 'components';
import groupBy from 'lodash.groupby';
import { useListedProjects } from 'queries/useListedProjects';
import React, { useMemo } from 'react';

import { Banner } from './MainPageBanner.query';
import * as styles from './MainPageBanner.style';

const projectTypes = ['token', 'nft'];

const MainPageBanner = () => {
  const { isLoading, listedProjects } = useListedProjects();

  const projectsByGroup = useMemo(
    () => groupBy(listedProjects, (v) => v.type),
    [listedProjects],
  );

  return (
    <section css={styles.container}>
      {isLoading && (
        <div css={styles.loadingIndicator}>
          <Spinner size={64} />
        </div>
      )}
      {projectTypes.map((groupedType) => (
        <React.Fragment>
          {/* {groupedType === 'dao' && <h2 css={styles.title}>DAOs</h2>} */}
          {groupedType === 'token' && (
            <h2 css={styles.title}>Project Tokens</h2>
          )}
          {groupedType === 'nft' && <h2 css={styles.title}>NFTs</h2>}
          {groupedType === 'dao' ? (
            // featured
            projectsByGroup[groupedType]?.map((item) => (
              <Banner
                key={item.symbol}
                {...item}
                projectDefinition={item}
                primary
              />
            ))
          ) : (
            // default view
            <div css={styles.subgrid}>
              {projectsByGroup[groupedType]?.map((item) => (
                <Banner key={item.symbol} {...item} projectDefinition={item} />
              ))}
            </div>
          )}
        </React.Fragment>
      ))}
    </section>
  );
};

export default MainPageBanner;
