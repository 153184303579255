import styled from '@emotion/styled';
import { Badge, ExternalLink } from 'components';

import TerrabotsLogo from 'assets/logo/terrabots.png';
import GalacticPunksLogo from 'assets/logo/galactic-punks.png';
import LunabullsLogo from 'assets/logo/lunabulls.png';
import DeviantsFactionsLogo from 'assets/logo/deviants-factions.png';
import * as styles from '../../token-page/token-page-header/TokenPageHeader.style';
import { useRecoilValue } from 'recoil';
import { projectSelector, symbolAtom } from 'recoils/ProjectRecoil';
import dayjs from 'dayjs';

import React, { useMemo } from 'react';


type NftPageHeaderProps = {
  numberOfDistributedPunks: number | undefined,
  numberOfTotalPunks: number | undefined,
}

const getLogo = (symbol: string) => {
  if (symbol === 'terrabots') {
    return { alt: 'Terrabots Logo', src: TerrabotsLogo }
  }
  if (symbol === 'lunabulls') {
    return { alt: 'Lunabulls Logo', src: LunabullsLogo }
  }
  if (symbol === 'galactic-punks') {
    return { alt: 'Galactic Punks Logo', src: GalacticPunksLogo }
  }
  return { alt: 'Deviant\'s Factions Logo', src: DeviantsFactionsLogo }
}

const NftPageHeader: React.FC<NftPageHeaderProps> = ({ numberOfDistributedPunks, numberOfTotalPunks }) => {
  const symbol = useRecoilValue(symbolAtom);
  const project = useRecoilValue(projectSelector(symbol));
  const projectDefinition = project?.projectDefinition

  const startDate = dayjs.utc(projectDefinition?.startsAt);
  const isLaunchStarted = dayjs.utc().isAfter(startDate);

  const logo = useMemo(() => getLogo(symbol), [symbol])

  const percentage = useMemo(() => {
    return (numberOfDistributedPunks ?? 1) /(numberOfTotalPunks ?? 1) * 100
  }, [numberOfDistributedPunks, numberOfTotalPunks])

  return (
    <header css={styles.container}>
      <Image alt={logo.alt} src={logo.src} />
      <div css={styles.subInfo}>
        <span css={styles.symbol}>
          {`NFT`}
        </span>
        {isLaunchStarted && (
          <Badge css={styles.badge}>
            {`Live since ${startDate.format('YYYY MMM DD')}`}
          </Badge>
        )}
      </div>
      <div css={styles.section}>
        <h1 css={styles.title}>
          {projectDefinition?.name}
        </h1>
        <p css={styles.description}>
          {projectDefinition?.summary}
        </p>
      </div>

      {isLaunchStarted && (
        <div css={styles.section}>
          <p css={styles.subheading}>
            {symbol === 'galactic-punks'
              ? 'Total Punks Distributed'
              : (symbol === 'lunabulls' || symbol === 'terrabots')
                ? 'Total Tokens Distributed'
                : 'Total Packs Distributed'}
          </p>
          <div css={styles.progressbarDescription}>
            <p css={styles.subdescription}>
              {typeof numberOfDistributedPunks === 'undefined'
                ? 'loading...'
                : numberOfDistributedPunks.toLocaleString()}
            </p>
            <p css={styles.subdescription}>
              {typeof numberOfDistributedPunks !== 'undefined' && `${numberOfTotalPunks?.toLocaleString()} max.`}
            </p>
          </div>
          <div css={styles.progressbarContainer}>
            <div
              css={styles.progressbarStatus}
              style={{
                width: `${percentage}%`,
              }}
            />
          </div>
        </div>
      )}

      <ul css={styles.outlinkContainer}>
        {projectDefinition?.links.map(({ name, href }) => (
          <li key={href}>
            <ExternalLink to={href}>
              {name}
            </ExternalLink>
          </li>
        ))}
      </ul>
    </header>
  );
};

export default NftPageHeader;

const Image = styled.img`
  display: block;
  height: 80px;

  margin-bottom: 36px;

  @media all and (max-width: 640px) {
    height: 60px;

    margin-bottom: 24px;
  }
`;
