import { motion } from 'framer-motion';
import styled from '@emotion/styled';


export const variants = {
  initial: {
    opacity: 0,
    marginTop: '16px',
  },
  animate: {
    opacity: 1,
    marginTop: 0,
    transition: { duration: 0.12 },
  },
  exit: {
    opacity: 0,
    marginTop: '16px',
    transition: { duration: 0.12 },
  },
};


export const Container = styled.div`
  position: relative;

  display: inline-block;
  vertical-align: middle;
`;

export const Toggler = styled.button`
  opacity: 0.75;
  transition: 0.25s opacity ease;

  &:hover, &:focus-visible {
    opacity: 0.33;
  }
`;

export const DescriptionContainer = styled(motion.div)`
  position: absolute;
  left: 0;

  width: 300px;
  z-index: 50;
`;

export const Description = styled.div`
  padding: 8px;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(16px);
  border-radius: 6px;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;

  transform: translateX(-50%);
`;

export const Paragraph = styled.div`
`;
