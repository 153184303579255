import { useEffect, useState } from 'react';
import { usePylon } from 'utils';

import { ProjectDefinition } from '@pylon-protocol/sdk/lib/pylon-api';

export const useListedProjects = () => {
  const pylon = usePylon();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [listedProjects, setListedProjects] = useState<ProjectDefinition[]>([]);
  useEffect(() => {
    if (!pylon) {
      return;
    }
    setLoading(true);
    pylon.gateway
      .listProjects()
      .then((projects) => {
        setLoading(false);
        setListedProjects(projects.reverse());
      })
      .catch(console.log);
  }, [pylon]);

  return { isLoading, listedProjects };
};
