import { QueryFunction } from 'react-query';
import client from '../client';
import { GetNftCollectionsResponse } from './types';

export const getNftCollections: QueryFunction<Nft[]> = async ({
  queryKey,
}) => {
  const [, chainID, symbol] = queryKey as [string, string, string]
  if (!symbol) {
    return []
  }
  const { data } = await client(chainID).get<GetNftCollectionsResponse>(
    `api/gateway/v1/projects/${symbol}/collections`,
  );

  return data?.nfts
};
