import LunArts from 'assets/logo/arts.png';
import DeviantsFactions from 'assets/logo/deviants-factions.png';
import GalacticPunks from 'assets/logo/galactic-punks.png';
import GFI from 'assets/logo/gfi.png';
import Glow from 'assets/logo/glow.png';
import LOOP from 'assets/logo/loop.png';
import Lunabulls from 'assets/logo/lunabulls.png';
import MINE from 'assets/logo/mine.png';
import OrionMoney from 'assets/logo/orion.png';
import Nexus from 'assets/logo/psi.png';
import SayveProtocol from 'assets/logo/sayve.png';
import Terrabots from 'assets/logo/terrabots.png';
import TerraWorld from 'assets/logo/twd.png';
import Valkyrie from 'assets/logo/vkr.png';
import WCOIN from 'assets/logo/wcoin.png';
import WhiteWhale from 'assets/logo/whale.png';
import XDEFI from 'assets/logo/xdefi.png';

export const LogoMap: Record<string, string> = {
  mine: MINE,
  loop: LOOP,
  twd: TerraWorld,
  psi: Nexus,
  vkr: Valkyrie,
  orion: OrionMoney,
  whale: WhiteWhale,
  glow: Glow,
  sayve: SayveProtocol,
  xdefi: XDEFI,
  gfi: GFI,
  'galactic-punks': GalacticPunks,
  'deviants-factions': DeviantsFactions,
  lunabulls: Lunabulls,
  terrabots: Terrabots,
  arts: LunArts,
  wcoin: WCOIN,
};
