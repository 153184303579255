import { Tooltip } from 'components';
import { usePoolInfo } from 'queries';
import React from 'react';
import { ceil, toFixed } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';

import * as styles from './TokenPagePoolOption.style';

export const TokenPagePoolAPY = ({
  pool,
  symbol,
  tokenPrice,
  isPoolClosed,
}: {
  pool: PylonGatewayPool | null;
  symbol: string;
  tokenPrice: number;
  isPoolClosed?: boolean;
}) => {
  const skip = isPoolClosed ?? false;
  const { data } = usePoolInfo({ pool, symbol, skip });

  const lowerCaseSymbol = symbol.toLowerCase();
  const isMINE = lowerCaseSymbol === 'mine';

  const apy = isPoolClosed ? undefined : data?.apy;

  // FIXME: divide post/pre token launch
  const isPreTokenLaunch = ['twd', 'psi', 'vkr', 'orion'].includes(
    lowerCaseSymbol,
  );

  return (
    <>
      <span css={[!isMINE && styles.apyText]}>
        {isPoolClosed
          ? 'Pool Closed'
          : isFinite(apy ?? 0)
          ? `${apy?.toLocaleString() ?? '-'}% APR`
          : '-% APR'}
      </span>
      {!isMINE && (
        <Tooltip>
          {isPreTokenLaunch
            ? `The current APR is calculated based on the circulating market price estimate of 1 ${symbol} = ${toFixed(
                tokenPrice,
                3,
              )} UST`
            : `The current APR is calculated based on the estimate of 1 ${symbol} = ${toFixed(
                tokenPrice,
                3,
              )} UST`}
        </Tooltip>
      )}
    </>
  );
};

export type TokenPagePoolDetailProps = {
  totalDepositsInUst?: number;
  distributedSupply?: number;
  totalSupply?: number;
};

// view structure for pool detail page
export const TokenPagePoolDetail = ({
  totalDepositsInUst,
  distributedSupply,
  totalSupply,
}: TokenPagePoolDetailProps) => {
  return (
    <ul css={styles.detailList}>
      <li>
        <p css={styles.detailHeading}>Total Deposits</p>
        <p>{`${totalDepositsInUst?.toLocaleString() ?? '-'} UST`}</p>
      </li>
      <li css={styles.detailTokenDistributedColumn}>
        <p css={styles.detailHeading}>Tokens Distributed</p>
        <p>
          {`${
            typeof distributedSupply === 'number'
              ? Math.max(distributedSupply, 0).toLocaleString()
              : '-'
          } / ${
            typeof totalSupply === 'number'
              ? ceil(totalSupply, 8).toLocaleString()
              : '-'
          }`}
        </p>
      </li>
    </ul>
  );
};

// query pool detail info
export const TokenPageDetailQuery = ({
  pool,
  symbol,
}: {
  pool: PylonGatewayPool | null;
  symbol: string;
}) => {
  const lowerCaseSymbol = symbol.toLowerCase();
  const isPsi = lowerCaseSymbol === 'psi';
  const isOrion = lowerCaseSymbol === 'orion';
  const { data } = usePoolInfo({ pool, symbol: lowerCaseSymbol });

  return (
    <TokenPagePoolDetail
      totalDepositsInUst={isPsi ? 2_000_000 : data?.totalDepositsInUst}
      distributedSupply={
        isPsi ? 0 : data?.distributedSupply * (isOrion ? 100 : 1)
      }
      totalSupply={
        isPsi ? 200_000_000 : data?.totalSupply * (isOrion ? 100 : 1)
      }
    />
  );
};
