import { PylonSwap } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';
import { useQuery } from 'react-query';


const useRealtimeSwapReward = (
  swap: PylonSwap,
) => {
  const wallet = useConnectedWallet();

  const address = wallet?.terraAddress;

  return useQuery(
    ['realtime-swap-reward', swap.address],
    async () => {
      try {
        if (!address) {
          return undefined;
        }

        const {
          totalRewards,
          claimableRewards,
        } = await swap.statusOf(address);
        return {
          claimableRewards,
          remainingRewards: totalRewards - claimableRewards,
        };
      } catch {
        return undefined;
      }
    },
    {
      // refetch rewards in every minute
      refetchInterval: 60_000,
    },
  );
};

export default useRealtimeSwapReward;
