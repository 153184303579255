import { TxInfo } from '@terra-money/terra.js';
import { atom } from 'recoil';


const createKey = (key: string) => `@global/${key}`;

type BalanceType = {
  ust: number;
  mine: number;
  connected: boolean;
};


export const ustBalanceAtom = atom<number>({
  key: createKey('ust'),
  default: 0,
});

export const mineBalanceAtom = atom<number>({
  key: createKey('mine'),
  default: 0,
});

export const balanceAtom = atom<BalanceType>({
  key: createKey('balance'),
  default: {
    ust: 0,
    mine: 0,
    connected: false,
  },
});

export const balanceUpdater = atom({
  key: createKey('balance-updater'),
  default: {},
});



type TransactionHistoryType = {
  id: string;
  action: string;
  hash: string;
  chainID: string;
  callback?: (tx: TxInfo) => void;
};

export const transactionHistoryAtom = atom<TransactionHistoryType[]>({
  key: createKey('transaction-history'),
  default: [],
});



type ToastType = {
  id: string;
  intent?: 'success' | 'failure';
  data?: Omit<TransactionHistoryType, 'id'>;
};

export const toastAtom = atom<ToastType[]>({
  key: createKey('toast'),
  default: [],
});
