import { useEffect, useState } from 'react';
import { ceil } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';

type UsePoolInfoOptions = {
  pool: PylonGatewayPool | null;
  symbol: string;

  // FIXME: add support
  skip?: boolean;
};
export type PoolInfo = {
  apy: number;
  totalDepositsInUst: number;
  distributedSupply: number;
  totalSupply: number;
};

export const usePoolInfo = ({
  pool,
  symbol,
  skip = false,
}: UsePoolInfoOptions) => {
  const [isFetching, setFetching] = useState<boolean>(false);
  const [poolInfo, setPoolInfo] = useState<PoolInfo>({
    apy: 0,
    totalDepositsInUst: 0,
    distributedSupply: 0,
    totalSupply: 0,
  });

  useEffect(() => {
    if (!pool || skip) {
      return;
    }
    // FIXME: check methods
    const getPoolInfo = async () => ({
      totalDepositsInUst: await pool.totalDepositAmount(),
      ...(await pool.info()),
    });

    setFetching(true);
    getPoolInfo()
      .then((poolInfo) => {
        setFetching(false);

        const totalSupply = ceil(poolInfo.totalSupply ?? 0);
        const distributedSupply = poolInfo.distributedSupply ?? 0;
        const totalDepositsInUst = poolInfo.totalDepositsInUst ?? 0;

        const isFromWormhole = ['orion'].includes(symbol?.toLowerCase() ?? '');

        const apy = parseFloat(
          (poolInfo.apy * 100 * (!isFromWormhole ? 1 : 100)).toFixed(2),
        );

        setPoolInfo({
          apy,
          totalSupply,
          distributedSupply,
          totalDepositsInUst,
        });
      })
      .catch(console.log);
  }, [pool, skip, symbol]);

  return { isFetching, data: poolInfo };
};
