import styled from '@emotion/styled';

import { ExternalLink } from 'components';


export const Container = styled.div`
  position: absolute;
  top: 100%;
  right: 0;

  padding: 16px 16px 8px;
  margin-top: 10px;

  width: 320px;
  background: white;
  text-align: left;

  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  z-index: 150;

  @media all and (max-width: 640px) {
    position: fixed;
    top: 60px;
    right: 6%;

    width: ${100 - (6 * 2)}%;
    height: 100%;

  }
`;


export const Heading = styled.h5`
  margin-bottom: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  color: rgba(0, 0, 0, .5);
`;


export const List = styled.ul`
  li {
    margin-bottom: 2px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;


export const Link = styled.span`
  display: inline-block;

  font-size: 14px;
  color: #0d72fd;

  transition: 0.18s opacity ease;
`;

export const Card = styled(ExternalLink)`
  display: block;

  padding: 8px 10px;
  margin: 0 -10px;

  border-radius: 6px;
  background: transparent;
  transition: 0.18s background ease;

  &:hover,
  &:focus-visible {
    background: rgba(0, 0, 0, 0.04);
  }
`;


export const Action = styled.p`
  line-height: 14px;
  font-size: 14px;

  color: rgba(0, 0, 0, 0.66);
`;
