import styled from '@emotion/styled';


const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(1, 10, 30, 0.75);

  z-index: 200;
`;

export default Backdrop;
