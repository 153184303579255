import { css } from '@emotion/react';


export const depositContainer = css`
  margin-bottom: 24px;
`;

export const inputContainer = css`
  margin-bottom: 8px;
`;

export const descriptionContainer = css`
  display: flex;

  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;

export const description = css`
  flex-grow: 1;
  margin-right: 20px;
`;

export const launchWaitDescription = css`
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.75);

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const availableUST = css`
  flex-shrink: 0;
  align-self: flex-start;

  color: inherit;
  font-size: inherit;
  text-decoration: underline;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const errorMessage = css`
  color: #ff0f49;
`;
