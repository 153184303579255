import { ReactComponent as WalletIcon } from 'assets/ic_wallet.svg';
import { Spinner } from 'components';
import { useBalance } from 'queries';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { balanceAtom } from 'recoils';
import { copy, getShortenAddress, humanizeBalance, useOutsideClick } from 'utils';

import {
  ConnectType, useConnectedWallet, useWallet, WalletStatus
} from '@terra-money/wallet-provider';

import WalletConnectedPopup from './WalletConnectedPopup';
import * as styles from './WalletConnector.style';
import WalletDisconnectedPopup from './WalletDisconnectedPopup';

const WalletConnector = () => {
  const container = useRef<HTMLDivElement>(null);

  const wallet = useConnectedWallet();
  const { status, connect, disconnect, availableConnectTypes } = useWallet();

  const [isOpened, setIsOpened] = useState(false);

  const address = useMemo(
    () => wallet?.terraAddress ?? '',
    [wallet?.terraAddress],
  );

  const handleToggleWallet = useCallback(() => {
    setIsOpened((prev) => {
      const nextIsOpened = !prev;

      if (
        nextIsOpened &&
        !wallet &&
        window.innerWidth <= 640 &&
        availableConnectTypes.includes(ConnectType.WALLETCONNECT)
      ) {
        connect(ConnectType.WALLETCONNECT);
      }

      return nextIsOpened;
    });
  }, [availableConnectTypes, connect, wallet]);

  const handleTogglePopup = useCallback((nextIsOpened: boolean) => {
    setIsOpened(nextIsOpened);
  }, []);

  const handleDisconnectWallet = useCallback(() => {
    const hasAgreed = window.confirm('Disconnect from Terra station?');
    if (hasAgreed) {
      disconnect();
      setTimeout(() => window.location.reload());
    }

    setIsOpened(false);
  }, [disconnect]);

  const handleCopyAddress = useCallback(() => {
    copy(address);
    setIsOpened(false);
  }, [address]);

  useOutsideClick(container, () => {
    setIsOpened(false);
  });

  const isWalletConnected = !!wallet;

  return (
    <div css={styles.container}>
      <styles.WalletButton
        connected={isWalletConnected}
        type="button"
        onClick={handleToggleWallet}
      >
        <WalletIcon css={styles.walletDefaultIcon} />
        <React.Suspense fallback={<Spinner size={12} />}>
          <WalletDetail address={address} status={status} />
        </React.Suspense>
      </styles.WalletButton>
      <React.Suspense fallback={null}>
        {!isWalletConnected && isOpened && (
          <WalletDisconnectedPopup
            ref={container}
            onToggle={handleTogglePopup}
          />
        )}
        {isWalletConnected && isOpened && (
          <WalletConnectedPopup
            ref={container}
            address={address}
            network={wallet?.network.chainID ?? 'columbus-5'}
            onCopy={handleCopyAddress}
            onDisconnect={handleDisconnectWallet}
          />
        )}
      </React.Suspense>
    </div>
  );
};

const WalletDetail = ({
  address = '',
  status,
}: {
  address?: string;
  status: WalletStatus;
}) => {
  const { data: balance } = useBalance(address);

  const setBalance = useSetRecoilState(balanceAtom);
  const resetBalance = useResetRecoilState(balanceAtom);

  useEffect(() => {
    if (!address || !balance) {
      resetBalance();
      return;
    }

    setBalance({ ...balance, connected: true });
  }, [address, balance]); // eslint-disable-line

  if (status === WalletStatus.WALLET_CONNECTED) {
    return (
      <>
        <span css={[styles.hideAtMobile, styles.walletAddress]}>
          {getShortenAddress(address)}
        </span>
        <span css={styles.walletBalance}>
          {`${humanizeBalance(balance?.ust ?? 0).toLocaleString()} UST`}
        </span>
      </>
    );
  }

  return (
    <span css={[styles.hideAtMobile, styles.walletAddress]}>
      Connect Wallet
    </span>
  );
};

export default WalletConnector;
