import React from 'react'
import styled from '@emotion/styled';

import { Button } from 'components';
import NFTAttributesTooltip from './NFTAttributesTooltip';

import { ReactComponent as ArrowIcon } from 'assets/ic_arrow_forward.svg';
import SoldoutIllust from 'assets/illust/soldout.png'

type NftCollectionItemProps = {
  tokenId: string,
  tokenName: string,
  name: string,
  image: string,
  punk_number?: number,
  serialNumber?: string,
  soldout?: boolean,
  attributes: NftAttributes[],
}

const NftCollectionItem: React.FC<NftCollectionItemProps> = ({
  tokenId,
  tokenName,
  name,
  image,
  soldout,
  attributes,
  ...props
}) => {
  return (
    <Container>
      <ImageWrapper>
        <Image src={`https://cloudflare-ipfs.com/ipfs/${image?.replace('ipfs://', '')}`} />
        {soldout && <SoldoutOverlay src={SoldoutIllust} />}
      </ImageWrapper>
      <Title>
        {`${typeof props.serialNumber !== 'undefined'
          ? `${tokenName} #${props.serialNumber}`
          : name}`}
      </Title>

      <Information>
        {!soldout && (
          <React.Fragment>
            <LotteryUpcoming>
              Lottery Upcoming
            </LotteryUpcoming>
            <NFTAttributesTooltip attributes={attributes} />
          </React.Fragment>
        )}
        {soldout && (
          <React.Fragment>
            <LotteryEnded>Lottery Ended</LotteryEnded>
            <a
              href={`https://randomearth.io/items/${tokenName === 'Punks'
                ? 'terra103z9cnqm8psy0nyxqtugg6m7xnwvlkqdzm4s4k'
                : tokenName === 'LunaBulls'
                  ? 'terra1trn7mhgc9e2wfkm5mhr65p3eu7a2lc526uwny2' // Lunabulls
                  : 'terra1hj0dga445h3faxu85gm0293608qntsj3a727ck' // Terrabots
              }_${tokenId}`}
              target="_blank"
              rel="noreferrer"
            >
              <MarketplaceButton>
                MarketPlace <ArrowIcon />
              </MarketplaceButton>
            </a>
          </React.Fragment>
        )}
      </Information>
    </Container>
  )
}

export default NftCollectionItem;

const Container = styled.div`
  min-width: 0;
  padding: 24px 24px 32px;
  background-color: rgba(0, 0, 0, 0.33);
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  @media all and (max-width: 1350px) {
    padding: 20px 20px 24px;
  }

  @media all and (max-width: 1024px) {
    padding: 18px 18px 20px;
  }

  @media all and (max-width: 640px) {
    padding: 16px 16px 18px;
  }

  @media all and (max-width: 400px) {
    padding: 12px 12px 16px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  aspect-ratio: 1;
  background-image: radial-gradient(90.04% 90.04% at 41.6% -5.66%, #164B78 0%, #11132A 100%);
`
const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  user-select: none;
  -webkit-user-drag: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const SoldoutOverlay = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  user-select: none;
  -webkit-user-drag: none;
`

const Title = styled.h3`
  margin-top: 25px;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #FFFFFF;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  @media all and (max-width: 1350px) {
    margin-top: 18px;
    font-size: 20px;
    line-height: 20px;
  }

  @media all and (max-width: 400px) {
    font-size: 18px;
    line-height: 18px;
  }
`

const LotteryUpcoming = styled.span`
  margin-top: 4px;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #00EEFA;
  text-shadow: 0px 2px 12px rgba(0, 238, 250, 0.4);

  @media all and (max-width: 1024px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media all and (max-width: 640px) {
    font-size: 18px;
    line-height: 20px;
  }

  @media all and (max-width: 400px) {
    font-size: 16px;
    line-height: 18px;
  }
`
const LotteryEnded = styled(LotteryUpcoming)`
  color: rgba(255, 255, 255, 0.66);
  text-shadow: none;
`

const Information = styled.div`
  width: 100%;
  height: 120px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media all and (max-width: 590px) {
    height: 100px;
  }
`

const MarketplaceButton = styled(Button)`
  width: 100%;
  height: unset;
  padding: 16px 0;
  border-radius: 8px;
  border: 1px solid white;
  background-color: transparent;

  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: white;

  & > svg {
    margin-right: 0;
    margin-left: 8px;
  }

  @media all and (max-width: 590px) {
    font-size: 16px;
    line-height: 16px;
    padding: 14px 0;
  }

  @media all and (max-width: 400px) {
    padding: 12px 0;
    font-size: 14px;
    line-height: 14px;

    & > svg {
      margin-left: 4px;
      height: 12px;
    }
  }
`
