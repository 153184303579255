import { css } from '@emotion/react';
import styled from '@emotion/styled';


export const Container = styled.button`
  display: inline-flex;
  align-items: center;
  vertical-align: top;

  padding: 8px 12px;
  border-radius: 8px;

  font-size: 16px;
  line-height: 16px;
  background: #333333;
  color: white;

  transition: 0.2s all ease;
`;

export const label = css`
  margin-left: 8px;

  @media all and (max-width: 640px) {
    display: none;
  }
`;
