import React from 'react'
import styled from '@emotion/styled';

import { ReactComponent as GridIcon } from 'assets/dashboard/ic-grid.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/dashboard/ic-arrow-right.svg';
import { ReactComponent as UserCircleIcon } from 'assets/dashboard/ic-user-circle.svg';
import AreaClosedChart from './AreaClosedChart';
import { ParentSize } from '@visx/responsive';

type TotalValueLockedCardProps = {
  formattedValue: string;
  historicals: { date: string, value: number }[]
}
export const TotalValueLockedCard: React.FC<TotalValueLockedCardProps> = ({
  formattedValue,
  historicals,
}) => {

  return (
    <Card>
      <Title>Total Value Locked (TVL)</Title>
      <Value>{`$${formattedValue} UST`}</Value>

      <ParentSize debounceTime={0}>
        {parent => (
          <AreaClosedChart width={parent.width} height={213} historicals={historicals} />
        )}
      </ParentSize>
    </Card>
  )
}

type TotalValuesTransferredCardProps = {
  formattedValues: {
    usersToProjects: string,
    projectsToUsers: string,
  }
}
export const TotalValuesTransferredCard: React.FC<TotalValuesTransferredCardProps> = ({
  formattedValues,
}) => {
  return (
    <Card>
      <Title>Total Values Transferred</Title>
      <TransferGroup>
        <TransferCategory>
          <UserCircleIcon /> User <ArrowRightIcon /> Project
        </TransferCategory>
        <Value>{`$${formattedValues.usersToProjects} UST`}</Value>
      </TransferGroup>
      <TransferGroup>
        <TransferCategory>
          <GridIcon /> Project <ArrowRightIcon /> User
        </TransferCategory>
        <Value>{`${formattedValues.projectsToUsers} UST`}</Value>
      </TransferGroup>
    </Card>
  )
}

const Card = styled.div`
  padding: 22px 20px 24px;
  flex: 1;

  display: flex;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.22);
  border: 1px solid rgba(0, 238, 250, 0.18);
  border-radius: 8px;
  backdrop-filter: blur(38px);
`

const Title = styled.h3`
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
`

const Value = styled.span`
  font-weight: normal;
  font-size: 36px;
  line-height: 100%;
  color: #FFFFFF;

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`

const TransferGroup = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`
const TransferCategory = styled.span`
  margin-bottom: 4px;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 238, 250, 0.85);

  display: flex;
  align-items: flex-end;

  & > svg {
    margin: 0 4px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`
