import { Int, MsgExecuteContract } from "@terra-money/terra.js";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";

import { chainIdAtom } from "recoils/ChainIdRecoil";
import { numberToDecimal } from "utils";
import { VALKYRIE_ADDRESSES } from "./valkyrieConstants";

type Options = {
  referrer: {
    address?: string;
    compressed?: string,
  } | null
}
export const useValkyrieCampaign = ({ referrer }: Options) => {
  const wallet = useConnectedWallet()
  const chainID = useRecoilValue(chainIdAtom) as 'columbus-5' | 'bombay-12'

  const deposit = async () => {
    if (!wallet?.terraAddress) {
      return
    }

    const transactions = [
      new MsgExecuteContract(
        wallet.terraAddress,
        VALKYRIE_ADDRESSES['qualifier'][chainID],
        { prepare: {} },
      ),

      new MsgExecuteContract(
        wallet.terraAddress,
        VALKYRIE_ADDRESSES['vkr'][chainID],
        {
          "send": {
            "contract": VALKYRIE_ADDRESSES['campaign'][chainID],
            "amount": new Int(numberToDecimal(50)).toString(),
            "msg": "eyJkZXBvc2l0Ijoge319" // "deposit": {}
          }
        },
      ),
      new MsgExecuteContract(
        wallet.terraAddress,
        VALKYRIE_ADDRESSES['campaign'][chainID],
        {
          participate: {
            actor: wallet.terraAddress,
            referrer,
          }
        },
      )
    ];

    return transactions
  };

  return deposit
}
