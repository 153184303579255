import { fetchGas } from 'queries';
import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { toastAtom, transactionHistoryAtom } from 'recoils';
import { getShortenAddress } from 'utils';

import { Coin, Fee, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';
import { TxResult, useConnectedWallet } from '@terra-money/wallet-provider';

import { getDefaultGas } from './coins';
import useLCDClient from './useLCDClient';

const DEFAULT_TX_TEMPLATE: TxResult = {
  msgs: [],
  success: false,
  result: {
    height: -1,
    raw_log: '',
    txhash: '',
  },
};

type Options = Partial<{
  action: string;
  gas: number; // = transactionFee
  tax: number;
  fee: number;
  callback?: (tx: TxInfo) => void;
  autoEstimated?: boolean;
}>;

const useTxDispatch = () => {
  const wallet = useConnectedWallet();
  const client = useLCDClient();

  const [isFetching, setIsFetching] = useState(false);
  const setToast = useSetRecoilState(toastAtom);
  const setHistory = useSetRecoilState(transactionHistoryAtom);

  const send = useCallback(
    async (msgs: MsgExecuteContract[], options?: Options) => {
      if (!wallet || !client) {
        return null;
      }

      setIsFetching(true);

      const tax = options?.tax ?? '0';
      const gas = options?.gas ?? getDefaultGas();

      let fee = options?.fee ?? 0;
      if (!fee) {
        const gasPrice = await fetchGas();
        fee = Math.round(gas * gasPrice);
      }

      const feeAmount = new Coin('uusd', fee).add(new Coin('uusd', tax));
      const stdFee = new Fee(gas, feeAmount.toString());

      try {
        const response = await wallet.post(
          !options?.autoEstimated
            ? { msgs, fee: stdFee }
            : {
                msgs,
                gasPrices: [new Coin('uusd', 1.5)],
              },
        );

        let { success } = response;
        if (!success) {
          throw new Error();
        }

        const { txhash } = response.result;
        const history = {
          id: new Date().toISOString(),
          action:
            options?.action ?? `Transaction of ${getShortenAddress(txhash)}`,
          hash: txhash,
          chainID: client.config.chainID,
          callback: options?.callback,
        };

        const toast = {
          id: `${new Date().toISOString()}-toast`,
          intent: 'success' as const,
          title: 'Transaction broadcasted!',
        };

        setToast((prev) => [toast, ...prev]);
        setHistory((prev) => [history, ...prev]);

        return {
          payload: response,
          failed: false as const,
          message: success ? '' : response.result.raw_log,
        };
      } catch (err: any) {
        console.log(err);
        const payload: TxResult = { ...DEFAULT_TX_TEMPLATE };
        const message =
          err?.message ?? 'There was an error while processing the data.';

        payload.result.raw_log = message;

        return {
          payload,
          message,
          failed: true as const,
        };
      } finally {
        setIsFetching(false);
      }
    },
    [client, wallet, setToast, setHistory],
  );

  return {
    send,
    isTransacting: isFetching,
  };
};

export default useTxDispatch;
