import { useQuery } from 'react-query';
import { usePylon } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { createFabricatedTransactions } from '@pylon-protocol/sdk/lib/types';
import { MsgExecuteContract } from '@terra-money/terra.js';
import { useConnectedWallet } from '@terra-money/wallet-provider';

const usePoolDeposit = (
  pool: PylonGatewayPool | null,
  amount: number,
  additionalTransactions: MsgExecuteContract[] | undefined = undefined,
) => {
  const pylon = usePylon();
  const wallet = useConnectedWallet();

  return useQuery(
    'poolDeposit',
    async () => {
      if (!wallet?.terraAddress || !pool || !pylon) {
        return null;
      }

      try {
        const result = await pool.deposit(wallet?.terraAddress, amount);
        if (additionalTransactions) {
          const [qualifierTransaction, ...otherTransactions] =
            additionalTransactions;
          const transactions = [
            qualifierTransaction,
            ...result.transactions,
            ...otherTransactions,
          ];

          const fabricatedTransactions = await createFabricatedTransactions(
            pylon,
            transactions,
            amount,
          );
          return fabricatedTransactions;
        }
        return result;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    {
      suspense: true,
    },
  );
};

export default usePoolDeposit;
