import { ExternalLink } from 'components';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { balanceAtom } from 'recoils';
import { getShortenAddress, humanizeBalance } from 'utils';
import { Terrascope } from 'utils/terrascope';

import * as styles from './WalletPopup.style';

type Props = {
  address: string;
  network: string;
  onCopy: () => void;
  onDisconnect: () => void;
};

const WalletConnectedPopup = React.forwardRef<HTMLDivElement, Props>(
  ({ address, network, onCopy, onDisconnect }, ref) => {
    const { ust, mine } = useRecoilValue(balanceAtom);

    return (
      <styles.PopupContainer ref={ref}>
        <h3 css={styles.popupAddress}>{getShortenAddress(address)}</h3>
        <ul>
          <li>
            <button
              css={styles.addressActionItem}
              type="button"
              onClick={onCopy}
            >
              Copy Address
            </button>
          </li>
          <li>
            <ExternalLink
              css={styles.addressActionItem}
              to={Terrascope.getAddressLink(network, address)}
            >
              View on TerraFinder
            </ExternalLink>
          </li>
          <li>
            <button
              css={styles.addressActionItem}
              type="button"
              onClick={onDisconnect}
            >
              Disconnect Wallet
            </button>
          </li>
        </ul>

        <h4 css={styles.subtitle}>Current Balance</h4>
        <ul>
          <li css={styles.currencyItem}>
            {`${humanizeBalance(ust).toLocaleString()} UST`}
          </li>
          <li css={styles.currencyItem}>
            {`${humanizeBalance(mine).toLocaleString()} MINE`}
          </li>
          {/* <li>
          <button
            css={styles.sendButton}
            type='button'
            onClick={onDisconnect}
          >
            Send
          </button>
        </li> */}
        </ul>
      </styles.PopupContainer>
    );
  },
);

export default WalletConnectedPopup;
