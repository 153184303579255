import { css } from '@emotion/react';
import styled from '@emotion/styled';


export const variants = {
  initial: {
    height: 0,
    opacity: 0,
    marginTop: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    marginTop: '16px',
    transition: { duration: 0.12 },
  },
  exit: {
    height: 0,
    opacity: 0,
    marginTop: 0,
    transition: { duration: 0.12 },
  },
};


export const Container = styled.div<{
  active: boolean;
}>`
  padding: 24px 32px;
  margin-bottom: 12px;

  background: ${(props) =>
    props.active ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.25)'};
  border-radius: 8px;

  font-size: 16px;
  line-height: 22px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media all and (max-width: 640px) {
    padding: 16px 20px;

    font-size: 14px;
    line-height: 18px;
  }
`;

export const header = css`
  margin-bottom: 20px;

  @media all and (max-width: 640px) {
    margin-bottom: 16px;
  }
`;

export const heading = css`
  margin-bottom: 6px;

  font-weight: 500;
  font-size: 22px;
  line-height: 22px;

  @media all and (max-width: 640px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const subheading = css`
  margin-bottom: 8px;

  font-weight: 500;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);

  @media all and (max-width: 640px) {
    margin-bottom: 4px;
    line-height: 14px;
  }
`;

export const poolInfo = css`
  font-size: 18px;
  line-height: 18px;

  color: rgba(255, 255, 255, 0.5);

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const claimSummaryContainer = css`
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-column-gap: 12px;
  margin-bottom: 16px;

  > div:last-of-type {
    text-align: right;
  }

  @media all and (max-width: 640px) {
    display: initial;
  }
`;

export const claimSummaryContainerSwap = css`
  margin-bottom: 0;
`;

export const claimButton = css`
  display: inline-flex;
  min-width: 160px;

  margin-top: 6px;
  padding: 0 12px;
  white-space: no-wrap;

  font-size: 14px !important;

  &:disabled {
    opacity: 0.5;
  }

  @media all and (max-width: 640px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 12px;
  }
`;

export const detailsButton = css`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);

  span {
    margin-right: 5px;
  }

  @media all and (max-width: 640px) {
    font-size: 13px;
    line-height: 13px;

    color: rgba(255, 255, 255, 0.5);

    path {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const caret = css`
  width: auto;
  height: 10px;

  transition: 0.25s all ease;
`;

export const caretRotated = css`
  transform: rotate(180deg);
`;


export const detailList = css`
  li {
    display: inline-block;
    margin-right: 20px;

    font-size: 14px;
  }

  .css-${subheading.name} {
    margin-bottom: 2px !important;
  }

  @media all and (max-width: 640px) {
    li {
      display: block;
      margin-bottom: 6px;
      font-size: 13px;
    }
  }
`;

export const withdrawContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const withdrawButton = css`
  padding: 8px 12px;
  margin-right: 16px;

  color: white;
  font-size: 14px;
  line-height: 14px;

  background: rgba(0, 0, 0, 0.25);

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const withdrawDescription = css`
  font-size: 13px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.66);
`;
