import { ReactComponent as ArrowIcon } from 'assets/ic_arrow_forward.svg';
import { Badge } from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
// import { toFixed } from 'utils';
import { illustMap } from 'utils/illustMap';

import { css } from '@emotion/react';
// import { PylonGatewayPool, PylonGatewayPoolInfo, PylonGatewayProject } from '@pylon-protocol/sdk';
import { ProjectDefinition } from '@pylon-protocol/sdk/lib/pylon-api';

import * as styles from './MainPageBanner.style';

const getIllust = (originSymbol: string) =>
  illustMap[originSymbol.toLowerCase()];

type BannerProps = {
  projectDefinition: ProjectDefinition;
  primary?: boolean;
};

export const Banner = ({ projectDefinition, primary = false }: BannerProps) => {
  const isPending = false;

  const illustrationStyles = useMemo(() => {
    const lowerCaseSymbol = projectDefinition.symbol.toLowerCase();
    return [
      primary ? styles.tokenIllust : styles.secondaryTokenIllust,
      lowerCaseSymbol === 'galactic-punks' &&
        css`
          top: -20px;
          right: -41px;
        `,
    ];
  }, [primary, projectDefinition.symbol]);

  const children = (
    <article css={[styles.featuredBanner, styles.featuredBannerPrimary]}>
      <div css={styles.contentArea}>
        <div css={styles.badgeContainer}>
          <LiveBadge
            symbol={projectDefinition.symbol}
            startsAt={projectDefinition.startsAt}
          />
        </div>
        <p css={styles.featuredSubheading}>
          {projectDefinition.type === 'nft' ? 'NFT' : projectDefinition.name}
        </p>
        <p css={styles.featuredHeading}>
          {projectDefinition.type === 'nft'
            ? projectDefinition.name
            : projectDefinition.symbol?.toLowerCase() === 'psi'
            ? '$Psi Liquid Pool'
            : `$${projectDefinition.symbol}${primary ? ' Launch' : ''}`}
        </p>
        <p
          css={[
            styles.featuredDescription,
            !primary && styles.secondaryDescription,
          ]}
        >
          {projectDefinition.summary}
        </p>
        <div
          css={[
            styles.featuredParticipate,
            isPending &&
              css`
                cursor: not-allowed;
              `,
          ]}
        >
          <span>{isPending ? 'Coming Soon' : 'Participate'}</span>
          {!isPending && <ArrowIcon />}
        </div>
        <img
          css={illustrationStyles}
          alt={`${projectDefinition.symbol} Illustration`}
          src={getIllust(projectDefinition.symbol)}
        />
      </div>
    </article>
  );

  return isPending ? (
    children
  ) : (
    <Link
      to={
        projectDefinition.type === 'nft'
          ? `/nft/${projectDefinition.symbol?.toLowerCase()}`
          : `/tokens/${projectDefinition.symbol?.toLowerCase()}`
      }
    >
      {children}
    </Link>
  );
};

const LiveBadge = ({
  startsAt,
  symbol,
}: {
  symbol?: string;
  startsAt: string;
}) => {
  const [, setTick] = useState(0);

  const startDate = useMemo(() => dayjs.utc(startsAt), [startsAt]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setTick((prev) => prev + 1);

      if (dayjs.utc().isAfter(startDate)) {
        window.clearInterval();
      }
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [startDate]);

  if (dayjs.utc().isBefore(startDate)) {
    return null;
  }

  return (
    <Badge>
      {symbol?.toLowerCase() === 'gfi'
        ? 'Coming Soon'
        : `Live since ${startDate.format('YYYY MMM DD')}`}
    </Badge>
  );
};

// type TierProps = {
//   project: PylonGatewayProject | null;
// };

// type PoolItem = {
//   key: string;
//   pool: PylonGatewayPool;
//   info: PylonGatewayPoolInfo;
// };

// const SECONDS = 1_000;
// const DAYS = 60 * SECONDS * 60 * 24;
// const MONTHS = DAYS * 30;

// type PoolTierProps = {
//   project: PylonGatewayProject | null;
//   pool: PylonGatewayPool;
//   info: PylonGatewayPoolInfo;
//   index: number;
// };
// const PoolTier = ({ project, pool, info, index }: PoolTierProps) => {
//   const isFromWormhole = useMemo(
//     () =>
//       ['orion'].includes(project?.projectDefinition.symbol.toLowerCase() ?? ''),
//     [project],
//   );

//   const apy = useMemo(
//     () => parseFloat((info.apy * 100 * (!isFromWormhole ? 1 : 100)).toFixed(2)),
//     [info.apy, isFromWormhole],
//   );

//   const [isPoolClosed, vestingMonths] = useMemo(() => {
//     try {
//       return [
//         !pool.isDepositable(),
//         toFixed(
//           (pool.withdrawTime[0].end.getTime() -
//             pool.withdrawTime[0].start.getTime()) /
//             MONTHS,
//           0,
//         ),
//       ];
//     } catch {
//       return [!pool.isDepositable(), NaN];
//     }
//   }, [pool]);

//   return (
//     <li>
//       <p css={styles.poolTitle}>{`Pool ${index + 1}`}</p>
//       <p>
//         {isPoolClosed
//           ? 'Pool Closed'
//           : isFinite(apy ?? 0)
//           ? `${apy?.toLocaleString() ?? '-'}% APR`
//           : '-% APR'}
//         <span css={styles.tiersVesting}>
//           <br />
//           {`${vestingMonths} month vesting`}
//         </span>
//       </p>
//     </li>
//   );
// };

// const Tier: React.FC<TierProps> = ({ project }) => {
//   const [poolItems, setPoolItems] = useState<PoolItem[]>([]);

//   const pools = useMemo(() => {
//     if (!project) {
//       return [];
//     }
//     return Object.entries(project.pools);
//   }, [project]);

//   useEffect(() => {
//     if (project?.projectDefinition.type === 'nft') {
//       setPoolItems([]);
//       return;
//     }
//     Promise.all(
//       pools.map(
//         ([index, pool]) =>
//           new Promise<PoolItem>((resolve) => {
//             pool.info().then((info) => {
//               resolve({ pool, info, key: index });
//             });
//           }),
//       ),
//     ) //
//       .then(setPoolItems);
//   }, [pools, project]);

//   return (
//     <ul css={styles.tierContainer}>
//       {poolItems.map(({ key, ...props }, index) => (
//         <PoolTier key={key} project={project} index={index} {...props} />
//       ))}
//     </ul>
//   );
// };
