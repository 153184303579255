import { Button, Input, Modal, Spinner, TxBroadcastingModal } from 'components';
import dayjs from 'dayjs';
import { useRefetchUserInfo } from 'queries';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getNumber, useTxDispatch } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { TxResult, useConnectedWallet } from '@terra-money/wallet-provider';

import * as styles from './TokenPagePoolWithdrawConfirm.style';

type Props = {
  pool: PylonGatewayPool;
  poolName: string;
  symbol: string;
  onClose: () => void;
};

const TokenPagePoolWithdrawConfirm = ({
  pool,
  poolName,
  symbol,
  onClose,
}: Props) => {
  const wallet = useConnectedWallet();

  const { forceUpdate } = useRefetchUserInfo();
  const { isTransacting, send } = useTxDispatch();

  const input = useRef<HTMLInputElement>(null);

  const [txResult, setTxResult] = useState<TxResult | undefined>(undefined);
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');

  const [stakedUst, setStakedUst] = useState<number>(0);
  useEffect(() => {
    if (!wallet?.terraAddress) {
      return;
    }
    pool.statusOf(wallet.terraAddress).then((status) => {
      setStakedUst(status.stakedUst);
    });
  }, [pool, wallet?.terraAddress]);

  const handleClickClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClickMaximumBalance = useCallback(() => {
    setValue(stakedUst);
    setError('');

    if (input.current) {
      input.current.value = `${stakedUst}`;
    }
  }, [stakedUst]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = getNumber(e.target.value);

      if (result.type !== 'success') {
        setValue(result.data);
        setError(result.message);
        return;
      }

      if (result.data > stakedUst) {
        setValue(result.data);
        setError('Exceed available UST.');

        return;
      }

      setValue(result.data);
      setError('');
    },
    [stakedUst],
  );

  const handleBlurInput = useCallback(() => {
    setError((prev) => {
      if (!prev) {
        return prev;
      }

      setValue(stakedUst);
      if (input.current) {
        input.current.value = `${stakedUst}`;
      }

      return '';
    });
  }, [stakedUst]);

  const handleClickSubmit = async () => {
    try {
      const data = await pool.withdraw(wallet?.terraAddress!, value);
      if (!data) {
        return;
      }

      const action = `Withdraw ${value.toLocaleString()} UST from $${symbol.toUpperCase()} ${poolName}`;
      const options = {
        action,
        fee: Number(data.transactionFee?.amount ?? 0),
        tax: Number(data.tax?.amount ?? 0),
        callback: forceUpdate,
      };

      const result = await send(data.transactions, options);
      if (!result?.payload) {
        return;
      }

      setTxResult(result.payload);
    } catch {}
  };

  if (txResult) {
    return (
      <TxBroadcastingModal
        isTransacting={isTransacting}
        type={txResult?.success ? 'success' : 'failure'}
        details={txResult}
        onClose={onClose}
      />
    );
  }

  const isSubmitDisabled = !wallet || isTransacting || !value || !!error;

  return (
    <Modal onClose={handleClickClose}>
      <h1 css={styles.modalHeading}>
        {`Withdraw your $${symbol.toUpperCase()} deposit`}
      </h1>
      <div css={styles.infoContainer}>
        {`Withdrawal option only available until ${dayjs
          .utc('2021-10-09 00:00')
          .format('YYYY MMM DD HH:mm[Z]')}`}
      </div>
      <div css={styles.depositContainer}>
        <Input
          ref={input}
          css={styles.input}
          type="text"
          label="UST"
          placeholder="0.00"
          onBlur={handleBlurInput}
          onChange={handleChangeInput}
        />
        <div css={styles.descriptionContainer}>
          <p css={[styles.description, !!error && styles.errorMessage]}>
            {error || 'You can withdraw a variable amount.'}
          </p>
          <button
            type="button"
            css={styles.availableUST}
            onClick={handleClickMaximumBalance}
          >
            {`${stakedUst.toLocaleString()} deposited UST available`}
          </button>
        </div>
      </div>
      <article css={styles.feeContainer}>
        <table css={styles.feeTable}>
          <tbody>
            <tr>
              <th>Transaction Fee</th>
              <td>1.5 UST</td>
            </tr>
          </tbody>
        </table>
      </article>
      <Button
        css={styles.submitButton}
        disabled={isSubmitDisabled}
        onClick={handleClickSubmit}
      >
        {isTransacting ? (
          <Spinner color="#000000" />
        ) : (
          `Withdraw from $${symbol.toUpperCase()} ${poolName}`
        )}
      </Button>
    </Modal>
  );
};

export default TokenPagePoolWithdrawConfirm;
