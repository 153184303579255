import { subscribe } from 'api/mailchimp';
import { ExternalLink, Spinner } from 'components';
import { useCallback, useRef, useState } from 'react';
import isEmail from 'validator/lib/isEmail';

import * as styles from './MainPageSubscribe.style';

const MainPageSubscribe = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const handleChangeInput = useCallback(() => {
    setError('');
  }, []);

  const handleSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!inputRef.current) {
        return false;
      }

      const email = inputRef.current.value;
      if (!isEmail(email)) {
        setError('Invalid email address');
        return false;
      }

      setIsFetching(true);

      const response = await subscribe(email);
      const [message = ''] = response.message?.split('<a') ?? [];

      setIsFetching(false);
      window.alert(message);

      if (response.type === 'success') {
        inputRef.current.value = '';
      }

      return false;
    },
    [],
  );

  return (
    <section css={styles.container}>
      <h3 css={styles.heading}>Stay up to date with the latest launches</h3>
      <form css={styles.formContainer} onSubmit={handleSubmitForm}>
        <input
          ref={inputRef}
          css={styles.input}
          type="email"
          name="email"
          disabled={isFetching}
          onChange={handleChangeInput}
          placeholder="Enter your email"
        />
        <button
          css={styles.submitButton}
          type="submit"
          disabled={!!error || isFetching}
        >
          {isFetching ? (
            <Spinner color="#0c3d3e" size={16} />
          ) : (
            'Get Launch Updates'
          )}
        </button>
      </form>
      {error && <p css={styles.errorMessage}>{error}</p>}
      <p css={styles.description}>
        <ExternalLink to="https://forum.pylon.money/t/for-projects-submitting-applications">
          Launching a project?
        </ExternalLink>
      </p>
    </section>
  );
};

export default MainPageSubscribe;
