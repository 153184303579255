import React from 'react';
import { DisclaimerBlock } from 'components';
import * as styles from './MainPageDisclaimer.style';

const MainPageDisclaimer: React.FC = () => {
  return (
    <DisclaimerBlock css={styles.disclamer}>
      Pylon Gateway is a permissionless platform that enables any project or
      platform to launch governance and/or utility tokens. Pylon Gateway does
      not involve itself in reviewing or auditing contract code for projects.
      Tokens launching on Pylon do not constitute investment advice. Legal
      treatment of assets purchased will depend on user/project jurisdiction,
      and users should partake in careful legal review before depositing any
      funds.
    </DisclaimerBlock>
  );
};

export default MainPageDisclaimer;
