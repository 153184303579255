import { DisclaimerBlock } from 'components';

import { css, Global } from '@emotion/react';

import MainPageBanner from './main-page-banner';
import MainPageDisclaimer from './main-page-disclaimer';
import MainPageSubscribe from './main-page-subscribe';
import * as styles from './MainPage.style';

const MainPage = () => (
  <div css={styles.container}>
    <header css={styles.header}>
      <h1 css={styles.hero}>Deposit to Invest.</h1>
      <div css={styles.description}>
        <p>
          Project crowdfunding and token launch platform for Terra, powered by
          Pylon Protocol.
        </p>
      </div>

      <DisclaimerBlock
        disclaimerField="Notice"
        css={css`
          color: rgba(8, 66, 199, 0.65);
          border-color: rgba(8, 66, 199, 0.45);

          a {
            font-weight: normal;
            text-decoration: underline;
          }
        `}
      >
        In light of recent events on Terra Classic, Pylon Protocol is continuing
        to explore all possible options moving forward. We are committed to our
        vision of platforms built around yield redirection and deposit
        contracts. Please check our{' '}
        <a
          href="https://twitter.com/pylon_protocol"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>{' '}
        or{' '}
        <a href="https://t.me/pylon_protocol" target="_blank" rel="noreferrer">
          Telegram
        </a>{' '}
        for more updates and information. By "principal-protected" and
        "lossless", this means a guarantee of minimum return equal to the
        investor's amount, not value, of initial investment.
      </DisclaimerBlock>
    </header>
    <MainPageBanner />
    <MainPageSubscribe />
    <MainPageDisclaimer />
    <Global styles={[styles.globalStyle]} />
  </div>
);

export default MainPage;
