import { Button, Input } from 'components';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useGovStakedAmount } from 'queries/useGovStakedAmount';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { balanceAtom } from 'recoils';
import { getNumber, humanizeBalance } from 'utils';

import { PylonSwap } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import TokenPageSwapInfo from '../token-page-swap-info';
import * as styles from '../token-page-trade/TokenPageTrade.style';

const DateFormat = 'MMM DD, HH:mm[Z]';

interface Props {
  symbol: string;
  swap: PylonSwap;
  totalClaims: number;
  isSwapStarted?: boolean;
  isLockupFinished?: boolean;
  maximumCapInUst?: number;
  fixedSwap?: boolean;
  onSubmit: (value: number) => void;
}

const TokenPageFixedSwap = ({
  symbol,
  swap,
  totalClaims,
  isSwapStarted = false,
  isLockupFinished = false,
  fixedSwap = false,
  maximumCapInUst,
  onSubmit,
}: Props) => {
  const govStakedAmount = useGovStakedAmount();

  const input = useRef<HTMLInputElement>(null);
  const wallet = useConnectedWallet();

  const balance = useRecoilValue(balanceAtom);

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');

  const maximumAllocation = useMemo(() => {
    let safeSwapCap = swap.depositCapStrategy.maxDepositCap;
    if (safeSwapCap >= Number.MAX_SAFE_INTEGER) {
      safeSwapCap = Infinity;
    }
    return Math.max(maximumCapInUst ?? Infinity, safeSwapCap);
  }, [maximumCapInUst, swap.depositCapStrategy.maxDepositCap]);

  const maximumBalance = useMemo(() => {
    let maximumCap: number = maximumAllocation;
    const hasMaximumCap = !!maximumCap;
    const maximum = Math.max(humanizeBalance(balance.ust) - 1, 0);

    if (maximumCap === Infinity) {
      return maximum;
    }
    if (hasMaximumCap) {
      return Math.min(maximum, maximumCap);
    }
    if (fixedSwap) {
      return Math.min(maximum, maximumCap);
    }

    return maximum;
  }, [balance.ust, fixedSwap, maximumAllocation]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = getNumber(e.target.value);
      setInputValue(e.target.value);

      if (result.type !== 'success') {
        setValue(result.data);
        setError(result.message);
        return;
      }

      if (result.data > maximumBalance) {
        setValue(result.data);
        setError(`Exceed available UST.`);

        return;
      }

      const minRequirementInUst = swap.depositCapStrategy.minDeposit ?? 0;
      if (minRequirementInUst && result.data < minRequirementInUst) {
        setValue(minRequirementInUst);
        setError(
          `Minimum deposit is $${minRequirementInUst.toLocaleString()}.`,
        );

        return;
      }

      setValue(result.data);
      setError('');
    },
    [maximumBalance, swap.depositCapStrategy.minDeposit],
  );

  const handleClickAllAmount = useCallback(() => {
    setInputValue(`${maximumBalance}`);
    setValue(maximumBalance);
    setError('');
  }, [maximumBalance]);

  const handleClickSubmit = () => {
    onSubmit(value);
  };

  const totalSaleAmount = swap.totalAmount;

  const isSwapEnded =
    !isSwapStarted || totalClaims >= totalSaleAmount || isLockupFinished;

  const isOnlyLockupFinished =
    totalClaims < totalSaleAmount && isLockupFinished;
  const isSubmitDisabled = !wallet || !!error || isSwapEnded || !inputValue;

  return (
    <>
      {!isSwapEnded && (
        <div css={styles.formContainer}>
          <Input
            ref={input}
            css={styles.inputContainer}
            type="number"
            disabled={!wallet || !isSwapStarted}
            label="UST"
            value={inputValue}
            placeholder={`0.00 ${
              maximumCapInUst
                ? `(Maximum ${numeral(maximumCapInUst)
                    .format('0.[0]a')
                    .toUpperCase()})`
                : ''
            }`}
            onChange={handleChangeInput}
          />
          <div css={styles.descriptionContainer}>
            <p css={[styles.description, !!error && styles.errorMessage]}>
              {error}
            </p>
            <button
              type="button"
              css={styles.availableUST}
              disabled={!wallet || !maximumBalance}
              onClick={handleClickAllAmount}
            >
              {`${maximumBalance.toLocaleString()} UST available`}
            </button>
          </div>
        </div>
      )}
      <TokenPageSwapInfo
        symbol={symbol}
        isSwapStarted={isSwapStarted}
        isLockupFinished={isLockupFinished}
        finishDate={swap.finish}
        vestingFinishDate={swap.vesting[0]?.releaseFinishTime}
        lockupFinishDateFromApi={swap.finish.toDateString()}
        totalSold={totalClaims}
        totalSaleAmount={totalSaleAmount}
        minRequirement={swap.depositCapStrategy.minDeposit}
        minRequirementInMine={
          swap.depositCapStrategy.isMineGovernanceAware
            ? swap.depositCapStrategy.calculateMaxCapByStakedBalance(
                govStakedAmount ?? 0,
              )
            : undefined
        }
        maxAllocation={
          maximumAllocation === Infinity
            ? 'Unlimited'
            : maximumAllocation || swap.depositCapStrategy.maxDepositCap
        }
        // maxAllocationDescription={
        //   (info as FixedSwapInfo)?.maxAllocationDescription
        // }
      />
      <Button
        background="#00eefa"
        css={styles.submitButton}
        disabled={isSubmitDisabled}
        onClick={isSubmitDisabled ? undefined : handleClickSubmit}
      >
        {isSwapStarted ? (
          <>
            {isSwapEnded
              ? !isOnlyLockupFinished
                ? 'Sold out'
                : 'Closed'
              : `Trade $UST to $${symbol}`}
          </>
        ) : (
          `Available on ${dayjs.utc(swap.start).format(DateFormat)}`
        )}
      </Button>
    </>
  );
};

export default TokenPageFixedSwap;
