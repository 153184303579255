export const BENEFICIARY_WALLETS = {
  mine: 'terra1azmtgucd9tk33sk368vqzlvh4vepncfqtywwqv',
  loop: 'terra1etwq0q8wwnmq7322kz4v6ff2dcvwfm634vdkqn',
  twd: 'terra1zd4p46gr33yxv72lfwnty2z6v3slp2chws4706',
  psi: 'terra1dramq5kchjr59gqkdyygy5elqdxnsymuyjlnpt',
  vkr: 'terra10dwfkcrh23lmm492juuh5fmkslktke4wrpuhhy',
  'galactic-punks': 'terra1ysfy3ws89c3zfspwpeuzxwv2nuhhm89yzup6hl',
  orion:'terra1d03k4ea90al2tzwpzfpj27vvd952saja76z62a',
  'deviants-factions': 'terra178wmvhxvlr6pqzz7tq5l5r49we6smztuw5jl6s',
  whale:	'terra1vjws6sa429u48dlw8s6mycr62nalyhakxc4v7v',
  glow: 'terra1dv2d2cxwzj93h8ju9frc5hufa97z7kq4w2fq03',
  sayve: 'terra17nwgfpcaml7hslyug72rdpfgnrcsgcf9g9kc0k',
  xdefi: 'terra12e9tfryuc9q40uw8wc487782hkzh4caly5qj8t',
  arts: 'terra1fjur4lmdt8vqrj3palr4kz3hqesl4mv39g8m4x',
  wcoin: '', // TODO: Not implemented yet
}
