import React, { useCallback } from 'react';

import {
  Connection,
  ConnectType,
  useWallet,
} from '@terra-money/wallet-provider';

import { ExternalLink } from 'components';

import * as styles from './WalletPopup.style';

type Props = {
  onToggle: (isOpened: boolean) => void;
};

const WalletDisconnectedPopup = React.forwardRef<HTMLDivElement, Props>(
  ({ onToggle }, ref) => {
    const { availableConnections, availableInstallations, connect } =
      useWallet();

    const connectTypes = availableConnections.filter(
      (item) => item.type !== ConnectType.READONLY,
    );

    const installTypes = availableInstallations.filter(
      (item) => item.type !== ConnectType.READONLY,
    );

    const handleClickConnect = useCallback(
      ({ type, identifier }: Connection) =>
        () => {
          connect(type, identifier);
          onToggle(false);
        },
      [connect, onToggle],
    );

    return (
      <styles.PopupContainer ref={ref} css={styles.hideAtMobile}>
        <ul>
          {connectTypes.map((item) => (
            <li key={item.identifier}>
              <button
                css={styles.walletOption}
                type="button"
                onClick={handleClickConnect(item)}
              >
                <img alt={item.name} css={styles.walletIcon} src={item.icon} />
                {item.name}
              </button>
            </li>
          ))}
          {installTypes.length > 0 && (
            <div css={styles.walletInstallationDivider} />
          )}
          {installTypes.map((item) => (
            <li key={item.identifier}>
              <ExternalLink
                key={item.identifier}
                css={styles.walletOption}
                to={item.url}
              >
                <img alt={item.name} css={styles.walletIcon} src={item.icon} />
                {`Install ${item.name}`}
              </ExternalLink>
            </li>
          ))}
        </ul>
      </styles.PopupContainer>
    );
  },
);

export default WalletDisconnectedPopup;
