import React from 'react';


interface Props extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> {
  to: string;
}

const ExternalLink = ({
  to,
  children,
  ...props
}: Props) => (
  <a
    {...props}
    href={to}
    target='_blank'
    rel='noreferrer noopener'
  >
    {children}
  </a>
);

export default ExternalLink;
