import { css } from '@emotion/react';

export const modalHeading = css`
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  @media all and (max-width: 640px) {
    margin-bottom: 12px;

    font-size: 24px;
    line-height: 24px;
  }
`;

export const infoContainer = css`
  margin-bottom: 48px;

  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);

  span {
    margin-right: 16px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 36px;

    font-size: 14px;
    line-height: 14px;

    span {
      margin-right: 12px;
    }
  }
`;

export const depositContainer = css`
  margin-bottom: 30px;

  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.33);

  @media all and (max-width: 640px) {
    margin-bottom: 16px;

    font-size: 12px;
  }
`;

export const input = css`
  margin-bottom: 8px;
`;

export const feeContainer = css`
  margin-bottom: 32px;

  @media all and (max-width: 640px) {
    margin-bottom: 24px;
  }
`;

export const feeTable = css`
  th,
  td {
    padding: 8px 0;

    font-size: 16px;
    line-height: 16px;
  }

  th {
    padding-right: 16px !important;

    text-align: right;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.66);
  }

  @media all and (max-width: 640px) {
    th,
    td {
      padding: 6px 0;

      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const disclaimer = css`
  margin-top: 12px;
`;

export const submitButton = css`
  width: 100%;
  height: 50px;
  background: #00eefa;
  font-size: 18px;

  &:disabled {
    opacity: 0.5;
  }

  @media all and (max-width: 640px) {
    height: 44px;
    font-size: 16px;
  }
`;

export const descriptionContainer = css`
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;

export const description = css`
  flex-grow: 1;
  margin-right: 20px;
`;

export const launchWaitDescription = css`
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.75);

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const availableUST = css`
  flex-shrink: 0;
  align-self: flex-start;

  color: inherit;
  font-size: inherit;
  text-decoration: underline;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const errorMessage = css`
  color: #ff0f49;
`;
