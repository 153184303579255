import styled from '@emotion/styled';


const Badge = styled.div`
  display: inline-block;
  padding: 8px 12px;

  color: #177D0C;
  font-size: 14px;
  line-height: 10px;
  font-weight: 500;

  background: #E6FEE3;
  border-radius: 48px;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: center;
    margin-right: 8px;

    width: 8px;
    height: 8px;

    background: #177D0C;
    border-radius: 50em;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;

export default Badge;
