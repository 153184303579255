import React from 'react';

import { ReactComponent as Symbol } from 'assets/ic_symbol.svg';

import * as styles from '../token-page-pool-option/TokenPagePoolOption.style';
import { css } from '@emotion/react';


interface Props {
  tier?: number;
  symbol: string;
  name: string;
  description: React.ReactNode;
};


const TokenPageUpcomingPoolOption = ({
  tier = 0,
  name,
  description,
}: Props) => {
  return (
    <styles.Container tier={tier}>
      <Symbol className='tier__symbol' />
      <div css={styles.contentContainer}>
        <div css={[styles.infoContainer, css`
          margin-bottom: 0;
        `]}>
          <div css={styles.info}>
            <p css={styles.tierTitle}>
              {name}
            </p>
            <p>
              {description}
            </p>
          </div>
          <styles.DepositButton
            width={160}
            tier={tier}
            disabled={true}
          >
            <Symbol />
            <span>
              Deposit via Pylon
            </span>
          </styles.DepositButton>
        </div>
      </div>
    </styles.Container>
  );
};

export default TokenPageUpcomingPoolOption;
