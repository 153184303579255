import LunArts from 'assets/illust/projects/arts.png';
import DeviantsFactionsLogo from 'assets/illust/projects/deviants-factions.png';
import GalacticPunks from 'assets/illust/projects/galactic-punks.png';
import GFI from 'assets/illust/projects/gfi.png';
import Glow from 'assets/illust/projects/glow.png';
import LOOP from 'assets/illust/projects/loop.png';
import Lunabulls from 'assets/illust/projects/lunabulls.png';
import MINE from 'assets/illust/projects/mine.png';
import OrionMoney from 'assets/illust/projects/orion.png';
import Nexus from 'assets/illust/projects/psi.png';
import Sayve from 'assets/illust/projects/sayve.png';
import Terrabots from 'assets/illust/projects/terrabots.png';
import TerraWorld from 'assets/illust/projects/twd.png';
import Valkyrie from 'assets/illust/projects/vkr.png';
import WCOIN from 'assets/illust/projects/wcoin.png';
import WhiteWhale from 'assets/illust/projects/whale.png';
import XDEFI from 'assets/illust/projects/xdefi.png';

export const illustMap: Record<string, string> = {
  loop: LOOP,
  twd: TerraWorld,
  mine: MINE,
  psi: Nexus,
  vkr: Valkyrie,
  'galactic-punks': GalacticPunks,
  'deviants-factions': DeviantsFactionsLogo,
  lunabulls: Lunabulls,
  terrabots: Terrabots,
  orion: OrionMoney,
  whale: WhiteWhale,
  glow: Glow,
  sayve: Sayve,
  xdefi: XDEFI,
  gfi: GFI,
  arts: LunArts,
  wcoin: WCOIN,
};
