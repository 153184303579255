import { useState, useCallback } from 'react';

import TxObserver from './tx-observer';
import TxHistory from './tx-history';

import * as styles from './TransactionObserver.style';



const TransactionObserver = () => {
  const [isOpened, setIsOpened] = useState(false);


  const handleToggleTrigger = useCallback(() => {
    setIsOpened(prev => !prev);
  }, []);

  const handleClickClose = useCallback(() => {
    setIsOpened(false);
  }, []);


  return (
    <styles.Container>
      <TxObserver onToggle={handleToggleTrigger} />
      {isOpened && (
        <TxHistory onClose={handleClickClose} />
      )}
    </styles.Container>
  );
};

export default TransactionObserver;
