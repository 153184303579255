import { useQuery } from 'react-query';
import { decimalToNumber, Denoms, usePylon } from 'utils';

const useBalance = (address: string, suspense: boolean = true) => {
  const pylon = usePylon();

  return useQuery(
    ['balance', address],
    async () => {
      if (!address || !pylon) {
        return;
      }

      try {
        const [uusd, mineBalance] = await Promise.all([
          pylon.lcd.bank.balance(address),
          pylon.mine.balanceOf(address),
        ]);

        const { amount = '0' } = uusd[0].get(Denoms.USD)?.toData() ?? {};
        const ustBalance = decimalToNumber(amount);

        return {
          ust: ustBalance,
          mine: mineBalance,
        };
      } catch {
        return {
          ust: 0,
          mine: 0,
        };
      }
    },
    { suspense },
  );
};

export default useBalance;
