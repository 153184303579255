import { Countdown, Input } from 'components';
import dayjs from 'dayjs';
import { usePylonPool } from 'queries';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { balanceAtom } from 'recoils';
import { chainIdAtom } from 'recoils/ChainIdRecoil';
import { projectSelector, symbolAtom } from 'recoils/ProjectRecoil';
import { createPriceProvider, getNumber, humanizeBalance, usePylon } from 'utils';

import { css } from '@emotion/react';
import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { MsgExecuteContract } from '@terra-money/terra.js';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import TokenPageLiquidPoolOption from '../token-page-liquid-pool-option';
import TokenPagePoolConfirm from '../token-page-pool-confirm';
import TokenPagePoolOption from '../token-page-pool-option';
import TokenPageUpcomingPoolOption from '../token-page-upcoming-pool-option';
import * as styles from './TokenPageEarn.style';

const LAUNCH_WAIT_DESCRIPTIONS = {
  waitDepositStart: {
    whale: '* WHALE Pool will open at Nov 22, 2021 13:00Z',
    glow: '* GLOW Pool will open at Dec 19, 2021 13:00Z',
    sayve: '* SAYVE Pool will open at Dec 21, 2021 13:00Z',
    xdefi: '* XDEFI Pool will open at Dec 22, 2021 13:00Z',
    arts: '* ARTS Pool will open at Mar 29, 2022 14:00Z',
    wcoin: '* WCOIN Pool will open at Apr 22, 2022 13:00Z',
  },
  waitDistributionStart: {
    whale: '* WHALE Token Distribution will start at Nov 23, 2021 13:00Z',
    glow: null,
    sayve: null,
    xdefi: '* XDEFI Token Distribution will start at Dec 23, 2021 13:00Z',
    arts: null,
    wcoin: null,
  },
};

const TokenPageEarn = () => {
  const input = useRef<HTMLInputElement>(null);

  const wallet = useConnectedWallet();

  const { ust } = useRecoilValue(balanceAtom);
  const symbol = useRecoilValue(symbolAtom);
  const chainID = useRecoilValue(chainIdAtom);
  const project = useRecoilValue(projectSelector(symbol));

  const [value, setValue] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [confirm, setConfirm] = useState<PylonGatewayPool | null>(null);
  const [confirmPoolID, setConfirmPoolID] = useState<number>(0);

  const maximumBalance = useMemo(
    () => Math.max(humanizeBalance(ust) - 1, 0),
    [ust],
  );

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = getNumber(e.target.value);

      if (symbol.toLowerCase() === 'mine' && result.data < 200) {
        setNote('Note: minimum deposit with Campaign should be above 200 UST');
      } else {
        setNote('');
      }

      if (result.type !== 'success') {
        setValue(result.data);
        setError(result.message);
        return;
      }

      if (result.data > maximumBalance) {
        setValue(result.data);
        setError('Exceed available UST.');

        return;
      }

      setValue(result.data);
      setError('');
    },
    [maximumBalance, symbol],
  );

  const handleClickMaximumBalance = useCallback(() => {
    setValue(maximumBalance);
    setError('');

    if (input.current) {
      input.current.value = `${maximumBalance}`;
    }
  }, [maximumBalance]);

  const [additionalTransactions, setAdditionalTransactions] = useState<
    MsgExecuteContract[] | undefined
  >();
  const handleOpenConfirm = useCallback(
    (poolID: number, transactions?: MsgExecuteContract[]) => {
      setAdditionalTransactions(transactions);
      const pool = Object.values(project?.pools ?? {})[poolID];
      if (!pool) {
        return;
      }
      setConfirm(pool);
      setConfirmPoolID(poolID);
    },
    [project?.pools],
  );

  const handleCloseConfirm = useCallback(() => {
    setConfirm(null);
    setConfirmPoolID(0);
  }, []);

  useEffect(() => {
    setValue(0);

    if (input.current) {
      input.current.value = '';
    }
  }, [wallet]); // eslint-disable-line

  const pylon = usePylon();

  // NOTE: for Psi liquid pools
  const isPsi = symbol.toLowerCase() === 'psi';
  const isNeutralLaunch = ['whale', 'glow', 'sayve', 'wcoin'].includes(
    symbol.toLowerCase(),
  );

  const skipLockupPoolQuery = useMemo(() => {
    if (['xdefi', 'orion', 'arts', 'psi'].includes(symbol.toLowerCase())) {
      return false;
    }
    if (!isNeutralLaunch) {
      return false;
    }
    return true;
  }, [isNeutralLaunch, symbol]);

  const firstPool = usePylonPool(symbol, 0, skipLockupPoolQuery);
  const hasQueriedContract = useRef<boolean>(false);
  const [depositStartAt, setDepositStartAt] = useState<Date | undefined>(
    undefined,
  );
  const [distributionStartAt, setDistributionStartAt] = useState<
    Date | undefined
  >(undefined);
  const [distributionEndAt, setDistributionEndAt] = useState<Date | undefined>(
    undefined,
  );
  const [availableAllocation, setAvailableAllocation] = useState<
    number | undefined
  >();

  const [tokenPrice, setTokenPrice] = useState<number>(0);
  useEffect(() => {
    if (!pylon) {
      return;
    }
    const priceProvider = createPriceProvider(
      pylon,
      chainID,
      project?.projectDefinition.tokenContract,
    );
    const getTokenPrice = priceProvider?.tokenPrice;
    if (!getTokenPrice) {
      setTokenPrice(0.05);
      return;
    }
    getTokenPrice().then(setTokenPrice);
  }, [pylon, symbol, chainID, project?.projectDefinition.tokenContract]);

  useEffect(() => {
    if (!firstPool || !!hasQueriedContract.current || !wallet?.terraAddress) {
      return;
    }
    hasQueriedContract.current = true;

    // FIXME: uncomment again after decorator issue
    firstPool
      .statusOf(wallet.terraAddress)
      .then((status) => {
        setAvailableAllocation(
          !status.availableCap ? Infinity : status.availableCap,
        );
      })
      .catch(console.log);

    if (!pylon) {
      return;
    }
    pylon.lcd.wasm
      .contractQuery(firstPool?.address, { config: {} })
      .then((result: any) => {
        const _depositStartTimestamp = result.deposit_config.time.start;
        const _depositStartAt = new Date(_depositStartTimestamp * 1000);
        setDepositStartAt(_depositStartAt);

        const _distributionStartTimestamp =
          result.distribution_config.time.start;
        const _distributionStartAt = new Date(
          _distributionStartTimestamp * 1000,
        );
        setDistributionStartAt(_distributionStartAt);

        const _distributionEndTimestamp =
          result.distribution_config.time.finish;
        const _distributionEndAt = new Date(_distributionEndTimestamp * 1000);
        setDistributionEndAt(_distributionEndAt);
      })
      .catch(console.log);
  }, [isNeutralLaunch, pylon, firstPool, wallet?.terraAddress]);

  const [currentTime, setCurrentTime] = useState(dayjs.utc());
  const handleSyncDate = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const isPoolDepositStarted = symbol.toLowerCase() !== 'wcoin';

  const isPoolDistributionStarted =
    !isNeutralLaunch || !isPsi
      ? true
      : currentTime.isAfter(dayjs.utc(distributionStartAt));

  const isPoolDistributionEnded = distributionEndAt
    ? currentTime.isAfter(dayjs.utc(distributionEndAt))
    : undefined;

  const isDisabled = useMemo(() => {
    if (!wallet) {
      return true;
    }
    if (!isPoolDepositStarted) {
      return true;
    }
    return (
      (isPsi &&
        (isPoolDistributionEnded ||
          !Object.values(project?.pools ?? {}).length)) ||
      symbol.toLowerCase() === 'gfi'
    );
  }, [
    isPoolDepositStarted,
    isPoolDistributionEnded,
    isPsi,
    project?.pools,
    symbol,
    wallet,
  ]);

  const launchWaitDescription = useMemo(() => {
    const lowerCaseSymbol = symbol.toLowerCase();
    const hasDescription = isNeutralLaunch || lowerCaseSymbol === 'xdefi';
    if (!hasDescription) {
      return null;
    }

    const filteredSymbol = lowerCaseSymbol as
      | 'whale'
      | 'glow'
      | 'arts'
      | 'wcoin'
      | 'sayve'
      | 'xdefi';

    return {
      waitDepositStart:
        LAUNCH_WAIT_DESCRIPTIONS.waitDepositStart[filteredSymbol],
      waitDistributionStart:
        LAUNCH_WAIT_DESCRIPTIONS.waitDistributionStart[filteredSymbol],
    };
  }, [symbol, isNeutralLaunch]);

  return (
    <>
      <div css={styles.depositContainer}>
        <Input
          ref={input}
          css={styles.inputContainer}
          type="number"
          disabled={isDisabled}
          label="UST"
          placeholder="0.00"
          onChange={handleChangeInput}
        />
        <div css={styles.descriptionContainer}>
          {symbol.toLowerCase() === 'gfi' ? (
            'Liquid Pool for Gateway Fund I - Coming Soon'
          ) : isPoolDepositStarted ? (
            <>
              <p
                css={[
                  styles.description,
                  !!error && styles.errorMessage,
                  css`
                    line-height: 1.45;
                  `,
                ]}
              >
                {error ||
                  note ||
                  (isPsi && isPoolDistributionEnded
                    ? 'Token distribution has ended.'
                    : 'You can reclaim this deposit once your pledged duration ends.')}
              </p>

              <button
                type="button"
                css={styles.availableUST}
                disabled={!wallet || isDisabled}
                onClick={handleClickMaximumBalance}
              >
                {`${maximumBalance.toLocaleString()} UST available`}
              </button>
            </>
          ) : // TODO: description with server-recieved date
          // null
          isPsi ? (
            <p css={[styles.description, styles.launchWaitDescription]}>
              {!isPoolDepositStarted && (
                <React.Fragment>
                  * Pool will be open 2 min in advance (UTC 12:58)
                  <br />
                </React.Fragment>
              )}
              * Rewards will start to be distributed &amp; claimable from UTC
              13:00 for 24 months
            </p>
          ) : symbol.toLowerCase() === 'xdefi' ? (
            <>
              {!isPoolDepositStarted
                ? launchWaitDescription?.waitDepositStart && (
                    <p css={[styles.description, styles.launchWaitDescription]}>
                      {launchWaitDescription.waitDepositStart}
                    </p>
                  )
                : !isPoolDistributionStarted
                ? launchWaitDescription?.waitDistributionStart && (
                    <p css={[styles.description, styles.launchWaitDescription]}>
                      {launchWaitDescription.waitDistributionStart}
                    </p>
                  )
                : null}
            </>
          ) : null}
        </div>
      </div>
      <div>
        <React.Suspense fallback={<></>}>
          {symbol.toLowerCase() === 'gfi' ? (
            <TokenPageUpcomingPoolOption
              symbol="gfi"
              name="Liquid Pool 1"
              description="24 months total vesting."
            />
          ) : symbol.toLowerCase() === 'psi' ? (
            <>
              {Object.values(project?.pools ?? []).map((item, index) => (
                <TokenPageLiquidPoolOption
                  disabled={!value || !!error}
                  startsAt={project?.projectDefinition.startsAt}
                  symbol={symbol}
                  tokenPrice={tokenPrice}
                  pool={item}
                  poolID={index}
                  onClick={error ? undefined : handleOpenConfirm}
                  availableAllocation={availableAllocation}
                  value={value}
                />
              ))}
            </>
          ) : (
            <>
              {Object.values(project?.pools ?? []).map((item, index) => (
                <TokenPagePoolOption
                  key={index}
                  poolID={index}
                  disabled={
                    !value ||
                    !!error ||
                    (typeof availableAllocation !== 'undefined' &&
                      value > availableAllocation)
                  }
                  startsAt={
                    isNeutralLaunch
                      ? depositStartAt
                      : project?.projectDefinition.startsAt
                  }
                  symbol={symbol}
                  tokenPrice={tokenPrice}
                  pool={item}
                  onClick={error ? undefined : handleOpenConfirm}
                  availableAllocation={availableAllocation}
                  value={value}
                />
              ))}
            </>
          )}
        </React.Suspense>
      </div>
      <Countdown
        to={depositStartAt}
        onChange={handleSyncDate}
        onEnd={() => {
          window.location.reload();
        }}
      />
      <Countdown
        to={distributionStartAt}
        onChange={handleSyncDate}
        onEnd={() => {
          window.location.reload();
        }}
      />
      {confirm && (
        <React.Suspense fallback={<></>}>
          <TokenPagePoolConfirm
            deposit={value}
            symbol={symbol}
            pool={confirm}
            poolID={confirmPoolID}
            additionalTransactions={additionalTransactions}
            onClose={handleCloseConfirm}
          />
        </React.Suspense>
      )}
    </>
  );
};

export default TokenPageEarn;
