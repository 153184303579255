import React from 'react';

import { Global } from '@emotion/react';

import LCDManager from './lcd-manager';
import Navigation from './navigation';
import ToastContainer from './toast-container';
import Footer from './footer';

import * as styles from './Layout.style';
import { PylonSubscriber } from 'recoils/Pylon';

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => (
  <>
    <PylonSubscriber />
    <LCDManager />
    <Navigation />
    <ToastContainer />
    <Global styles={[styles.reset]} />
    {children}
    <Footer />
  </>
);

export default Layout;
