import { useDepositLog } from 'queries';
import { SwapDepositLog } from 'queries/useDepositLog';
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as states from 'recoils/ProjectRecoil';

import { PylonGatewayPool } from '@pylon-protocol/sdk';

import { TokenPageClaimPool, TokenPageClaimSwap } from '../token-page-claim';
import TokenPageClaimConfirm from '../token-page-claim-confirm';
import TokenPagePoolWithdrawConfirm from '../token-page-pool-withdraw-confirm';
import TokenPageTradeConfirm from '../token-page-trade-confirm';
import * as styles from './TokenPageCurrentDeposit.style';

type ClaimConfirmType = SwapDepositLog;

const TokenPageCurrentDeposit = () => {
  const symbol = useRecoilValue(states.symbolAtom);
  const project = useRecoilValue(states.projectSelector(symbol));

  const { data: deposits } = useDepositLog(project);
  const [claimSwap, setClaimSwap] = useState<ClaimConfirmType | null>(null);
  const [claimPool, setClaimPool] = useState<PylonGatewayPool | null>(null);
  const [withdrawPool, setWithdrawPool] = useState<PylonGatewayPool | null>(
    null,
  );
  const [withdrawPoolName, setWithdrawPoolName] = useState<string>('');

  const handleWithdrawPool = useCallback(
    (item: PylonGatewayPool, poolName: string) => () => {
      setWithdrawPool(item);
      setWithdrawPoolName(poolName);
    },
    [],
  );

  const handleSubmitClaimPool = useCallback(
    (item: PylonGatewayPool) => () => {
      setClaimPool(item);
    },
    [],
  );

  // const hasMineRequires = ['orion', 'xdefi', 'arts'].includes(
  //   symbol.toLowerCase(),
  // );
  // const isPsi = symbol.toLowerCase() === 'psi';
  // const isContractBasedStartTime = hasMineRequires || isPsi;

  const handleSubmitClaimSwap = useCallback(
    (item: ClaimConfirmType) => () => {
      setClaimSwap(item);
    },
    [],
  );

  const handleCloseClaim = useCallback(() => {
    setClaimSwap(null);
    setClaimPool(null);
    setWithdrawPool(null);
    setWithdrawPoolName('');
  }, []);

  if (!project) {
    return null;
  }

  const hasDeposit = !!Object.values(deposits ?? {}).flat().length;
  if (!hasDeposit) {
    return <p css={styles.emptyMessage}>There's no deposits.</p>;
  }

  return (
    <>
      {deposits?.swap.map((swapDepositLog) => (
        <TokenPageClaimSwap
          {...swapDepositLog}
          key={swapDepositLog.swap.address}
          symbol={symbol}
          onClaim={handleSubmitClaimSwap(swapDepositLog)}
        />
      ))}
      {deposits?.pool.map((poolDepositLog) => {
        return (
          <TokenPageClaimPool
            key={poolDepositLog.pool.address}
            symbol={symbol}
            pool={poolDepositLog.pool}
            poolName={poolDepositLog.poolName}
            onClaim={handleSubmitClaimPool(poolDepositLog.pool)}
            onWithdraw={handleWithdrawPool(
              poolDepositLog.pool,
              poolDepositLog.poolName,
            )}
            rewardTokens={poolDepositLog.rewardTokens}
            balanceInUst={poolDepositLog.balanceInUst}
          />
        );
      })}
      {claimPool && (
        <TokenPageClaimConfirm
          symbol={symbol}
          pool={claimPool}
          onClose={handleCloseClaim}
        />
      )}
      {claimSwap && (
        <TokenPageTradeConfirm
          type="claim"
          info={claimSwap}
          amount={claimSwap.balanceInUst}
          onClose={handleCloseClaim}
        />
      )}
      {withdrawPool && (
        <TokenPagePoolWithdrawConfirm
          pool={withdrawPool}
          poolName={withdrawPoolName}
          symbol={symbol}
          onClose={handleCloseClaim}
        />
      )}
    </>
  );
};

export default TokenPageCurrentDeposit;
