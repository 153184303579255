import GradientUnderlayImage from 'assets/dashboard/gradient-underlay.png';
import { Button, Spinner, Tooltip, TxBroadcastingModal } from 'components';
import { useListedProjects } from 'queries/useListedProjects';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { usePylon, useTxDispatch } from 'utils';
import { BENEFICIARY_WALLETS } from 'utils/beneficiary';
import { LogoMap } from 'utils/logoMap';

import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { PylonGatewayProject } from '@pylon-protocol/sdk';
import { createFabricatedTransactions } from '@pylon-protocol/sdk/lib/types';
import { MsgExecuteContract } from '@terra-money/terra.js';
import { TxResult, useConnectedWallet } from '@terra-money/wallet-provider';

import { MobileProjectSelector } from './components/MobileProjectSelector';
import { TotalValueLockedCard, TotalValuesTransferredCard } from './components/OverviewCards';
import {
  ConfigCard, ProjectYieldCard, UserYieldCard, UserYieldPoolContent, UserYieldSwapContent
} from './components/ProjectInformationCards';
import { ProjectSelector } from './components/ProjectSelector';
import { getProjectStats } from './queries/getProjectStats';
import { getStatsOverview } from './queries/getStatsOverview';

const DashboardPage: React.FC = () => {
  const pylon = usePylon();
  const { data: statsOverviewData } = useQuery([], getStatsOverview);

  const totalValueLocked = useMemo(() => {
    if (!statsOverviewData) {
      return '-';
    }
    return statsOverviewData.response.totalValueLocked.current.toLocaleString();
  }, [statsOverviewData]);

  const totalValuesTransferred = useMemo(() => {
    if (!statsOverviewData) {
      return {
        usersToProjects: '-',
        projectsToUsers: '-',
      };
    }
    return {
      usersToProjects:
        statsOverviewData.response.totalValuesTransferred.usersToProjects.toLocaleString(),
      projectsToUsers:
        statsOverviewData.response.totalValuesTransferred.projectsToUsers.toLocaleString(),
    };
  }, [statsOverviewData]);

  const { listedProjects } = useListedProjects();

  const [selectedProjectSymbol, setSelectedProjectSymbol] =
    useState<string>('MINE');
  const projectDefinition = useMemo(
    () =>
      listedProjects.find(
        (project) => project.symbol === selectedProjectSymbol,
      ),
    [listedProjects, selectedProjectSymbol],
  );

  const [project, setProject] = useState<PylonGatewayProject | null>(null);
  useEffect(() => {
    if (!pylon || !projectDefinition) {
      setProject(null);
      return;
    }
    pylon.gateway
      .projectFromDefinition(projectDefinition)
      .then(setProject)
      .catch((error) => console.log(error));
  }, [pylon, projectDefinition]);

  const [refetchCount, setRefetchCount] = useState<number>(0);
  const {
    data: projectStats,
    isLoading: isLoadingStats,
    error,
  } = useQuery(['list', selectedProjectSymbol, refetchCount], getProjectStats);

  const formattedUserYieldInformation = useMemo(() => {
    if (!projectStats) {
      return {
        formattedTotalYield: '-',
        formattedTotalValueFromUST: '-',
        formattedTotalValueExchanged: '-',
      };
    }
    if (error || 'error' in projectStats) {
      return {
        formattedTotalYield: '-',
        formattedTotalValueFromUST: '-',
        formattedTotalValueExchanged: '-',
      };
    }
    const totalPoolValues = !!projectStats?.pools.length
      ? projectStats.pools.reduce((acc, v) => acc + v.totalTokensDistributed, 0)
      : 0;
    const formattedTotalYield = parseFloat(
      (
        totalPoolValues + (projectStats.swap?.totalTokensDistributed ?? 0)
      ).toFixed(3),
    ).toLocaleString();

    return {
      formattedTotalYield,
      formattedTotalValueFromUST: '-',
      formattedTotalValueExchanged: '-',
    };
  }, [error, projectStats]);

  const wallet = useConnectedWallet();
  const isClaimAvailable = useMemo(() => {
    if (!wallet?.terraAddress) {
      return false;
    }
    if (!projectStats) {
      return false;
    }
    if (!projectDefinition || !projectDefinition.poolContract) {
      return false;
    }
    const key =
      selectedProjectSymbol.toLowerCase() as keyof typeof BENEFICIARY_WALLETS;
    if (BENEFICIARY_WALLETS[key] !== wallet.terraAddress) {
      return false;
    }
    return true;
  }, [wallet, projectStats, projectDefinition, selectedProjectSymbol]);

  const { isTransacting, send } = useTxDispatch();
  const [txResult, setTxResult] = useState<TxResult | undefined>(undefined);

  const onClickClaim = useCallback(async () => {
    const poolContract = projectDefinition?.poolContract;

    if (!poolContract || !wallet?.terraAddress || !pylon) {
      return;
    }

    const transactions = [
      new MsgExecuteContract(wallet?.terraAddress, poolContract, { earn: {} }),
    ];

    const data = await createFabricatedTransactions(pylon, transactions);

    const action = `Claiming yield from ${selectedProjectSymbol}`;
    const options = {
      action,
      fee: Number(data.transactionFee?.amount ?? 0),
      tax: Number(data.tax?.amount ?? 0),
      callback: () => setRefetchCount((count) => count + 1),
      autoEstimated: true,
    };

    const result = await send(transactions, options);
    if (!result?.payload) {
      return;
    }

    setTxResult(result.payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDefinition, selectedProjectSymbol, wallet?.terraAddress]);

  return (
    <Container>
      <PageContent>
        <ProjectSelector
          selected={selectedProjectSymbol}
          setSelected={setSelectedProjectSymbol}
          projects={listedProjects}
        />
        <Main>
          <Section>
            <Subtitle>Pylon Gateway Overview</Subtitle>
            <CardContainer
              css={css`
                margin-top: 16px;
              `}
            >
              <TotalValueLockedCard
                formattedValue={totalValueLocked}
                historicals={
                  statsOverviewData?.response.totalValueLocked.historical ?? []
                }
              />
              <TotalValuesTransferredCard
                formattedValues={totalValuesTransferred}
              />
            </CardContainer>
          </Section>
          <Section>
            <Subtitle>Project Detail</Subtitle>
            <MobileProjectSelector
              selected={selectedProjectSymbol}
              setSelected={setSelectedProjectSymbol}
              projects={listedProjects}
            />
            <ProjectTitleRow>
              <ProjectLogo src={LogoMap[selectedProjectSymbol.toLowerCase()]} />
              <ProjectTitle>{projectDefinition?.name}</ProjectTitle>
            </ProjectTitleRow>
            {isLoadingStats ? (
              <SpinnerContainer>
                <Spinner size={64} />
              </SpinnerContainer>
            ) : (
              <CardListContainer>
                <SideCardList>
                  <ConfigCard stats={projectStats ?? undefined} />
                  <ProjectYieldCard stats={projectStats ?? undefined} />
                  {!!projectStats && (
                    <ClaimButtonRow>
                      <Tooltip>
                        Only users with wallets that match the beneficiary
                        address set in each project can use this feature.
                      </Tooltip>
                      <ClaimButton
                        disabled={!isClaimAvailable}
                        onClick={onClickClaim}
                      >
                        Claim Generated Yield
                      </ClaimButton>
                    </ClaimButtonRow>
                  )}
                </SideCardList>
                <SideCardList>
                  <UserYieldCard
                    type={projectDefinition?.type}
                    symbol={selectedProjectSymbol}
                    {...formattedUserYieldInformation}
                  >
                    {project &&
                      projectStats?.pools.map((_pool) => {
                        const pool = Object.values(project.pools)[_pool.id];
                        return (
                          <UserYieldPoolContent
                            key={_pool.id}
                            index={_pool.id}
                            pool={!!pool ? pool : null}
                            symbol={selectedProjectSymbol}
                            stats={projectStats}
                          />
                        );
                      })}

                    {projectStats?.swap && (
                      <UserYieldSwapContent
                        symbol={selectedProjectSymbol}
                        swap={projectStats?.swap}
                      />
                    )}
                  </UserYieldCard>
                </SideCardList>
              </CardListContainer>
            )}
          </Section>
        </Main>
      </PageContent>

      {txResult && (
        <TxBroadcastingModal
          isTransacting={isTransacting}
          type={txResult?.success ? 'success' : 'failure'}
          details={txResult}
          onClose={() => {
            setTxResult(undefined);
          }}
        />
      )}

      <Underlay>
        <UnderlayImageTop alt="" src={GradientUnderlayImage} />
        <UnderlayImageBottom alt="" src={GradientUnderlayImage} />
      </Underlay>

      <Global
        styles={css`
          html,
          body {
            background: #111111;
          }

          #root {
            overflow: hidden;
          }
        `}
      />
    </Container>
  );
};

export default DashboardPage;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const PageContent = styled.div`
  width: 100%;
  max-width: 1600px;
  height: 100%;
  min-height: 1500px;
  position: relative;
  z-index: 1;

  margin: 0 auto;
  padding: 128px 8% 160px;

  @media all and (max-width: 1024px) {
    padding: 128px 5% 160px;
  }

  @media all and (max-width: 840px) {
    padding: 128px 5% 120px;
  }

  display: flex;

  & > main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const Main = styled.main`
  width: 100%;
`;
const Section = styled.section``;
const CardContainer = styled.div`
  margin-bottom: 76px;
  display: flex;

  & > div:not(:last-of-type) {
    margin-right: 28px;
  }

  @media screen and (max-width: 1400px) {
    flex-direction: column;

    & > div:not(:last-of-type) {
      margin-bottom: 28px;
      margin-right: 0;
    }
  }
`;

const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
  width: fit-content;
`;

const ProjectTitleRow = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 800px) {
    align-items: center;
  }
`;
const ProjectLogo = styled.img`
  width: 42px;
  height: 42px;
  object-fit: contain;
  filter: drop-shadow(0px 8px 16px rgba(0, 153, 160, 0.08));
`;
const ProjectTitle = styled.h1`
  margin-left: 12px;
  font-weight: normal;
  font-size: 48px;
  line-height: 100%;

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const Underlay = styled.div`
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;

  &,
  & > img {
    user-select: none;
    -webkit-user-drag: none;
  }
`;
const UnderlayImageTop = styled.img`
  width: 1000px;
  height: 1500px;
  position: absolute;

  top: -546.44px;
  left: 0px;
  filter: saturate(120%) blur(120px);
  transform: rotate(-66deg);
`;
const UnderlayImageBottom = styled(UnderlayImageTop)`
  top: unset;
  left: unset;
  right: -500px;
  bottom: -500px;
  transform: rotate(137.01deg);
`;

const CardListContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-of-type {
    margin-right: 28px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;

    & > div:first-of-type {
      margin-right: 0;
    }
  }
`;

const SideCardList = styled.div`
  margin-top: 28px;
  flex: 1;

  & > div.project-yield-card {
    margin-top: 28px;
  }
`;

const ClaimButtonRow = styled.div`
  margin-top: 24px;

  align-items: center;
  justify-content: flex-end;
  display: flex;
`;
const ClaimButton = styled(Button)`
  margin-left: 8px;

  font-weight: 500;
  color: #000000;
  background-color: #00eefa;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.33;
    `};

  &:active {
    opacity: 0.65;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 200px;
  padding: 32px 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;
