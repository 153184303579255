import { Button, Countdown } from 'components';
import dayjs from 'dayjs';
import { useRealtimeSwapReward } from 'queries';
import { SwapDepositLog } from 'queries/useDepositLog';
import React, { useCallback, useMemo, useState } from 'react';

import { css } from '@emotion/react';

import * as styles from './TokenPageClaim.style';

type Props = SwapDepositLog & {
  symbol: string;
  onClaim: () => void;
};

const TokenPageClaimSwap = ({
  rewardTokens: cachedClaimableRewards,
  balanceInUst,
  symbol,
  swap,
  onClaim,
}: Props) => {
  const { isFetching, data: claimableRewards } = useRealtimeSwapReward(swap);
  const reward = claimableRewards?.claimableRewards ?? cachedClaimableRewards;
  const remaining = claimableRewards?.remainingRewards ?? 0;

  const [currentTime, setCurrentTime] = useState(dayjs.utc());

  const endsAt = useMemo(() => {
    return dayjs.utc(swap.lockup[0]?.releaseTime);
  }, [swap.lockup]);

  const isRemainingShown = ['psi', 'vkr'].includes(symbol.toLowerCase());
  const isClaimAvailable =
    symbol.toLowerCase() === 'psi' || currentTime.isAfter(endsAt);

  const handleSyncDate = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const handleClickClaim = useCallback(() => {
    onClaim();
  }, [onClaim]);

  const restTimeInText = endsAt.from(currentTime);
  return (
    <styles.Container active={isClaimAvailable || reward !== 0}>
      <Countdown to={endsAt.format()} onChange={handleSyncDate} />
      <div css={styles.header}>
        <p css={styles.heading}>
          {`${balanceInUst.toLocaleString()} UST swapped`}
        </p>
      </div>
      <div
        css={[styles.claimSummaryContainer, styles.claimSummaryContainerSwap]}
      >
        <div>
          <p css={styles.subheading}>
            {isRemainingShown ? 'Unlocked Tokens' : 'Accumulated Rewards'}
          </p>
          <p>{`${isFetching ? '-' : reward.toLocaleString()} ${symbol}`}</p>
          {isRemainingShown && (
            <React.Fragment>
              <p
                css={[
                  styles.subheading,
                  css`
                    margin-top: 24px;
                  `,
                ]}
              >
                Locked Tokens
              </p>
              <p>
                {`${isFetching ? '-' : remaining.toLocaleString()} ${symbol}`}
              </p>
            </React.Fragment>
          )}
        </div>
        <div>
          <Button
            css={styles.claimButton}
            disabled={!isClaimAvailable}
            onClick={handleClickClaim}
          >
            {isClaimAvailable
              ? `Redeem ${symbol}`
              : `Available ${
                  restTimeInText.startsWith('in') ? '' : 'at '
                }${restTimeInText}`}
          </Button>
        </div>
      </div>
    </styles.Container>
  );
};

export default TokenPageClaimSwap;
