import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ExternalLink } from 'components';


export const outlinks = css`
  margin-top: 40px;
`;

export const optionalLinkList = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  @media all and (max-width: 640px) {
    grid-column-gap: 6px;
  }
`;


export const fullDocsLink = css`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
  height: 50px;
  background: transparent;

  font-weight: 600;
  border: 1px solid white;
  border-radius: 8px;
  transition: 0.25s all ease;

  span {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
    height: 42px;

    margin-bottom: 8px;

    span {
      margin-right: 4px;
    }
  }
`;

export const markdown = css`
  font-size: 18px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.8);

  p {
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 48px;
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.9);
  }

  ul {
    padding-left: 30px;
    margin-bottom: 20px;
  }

  li {
    list-style-type: square;

    padding-left: 10px;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media all and (max-width: 1024px) {
    p {
      margin-bottom: 16px;
    }

    h2 {
      margin-top: 32px;
      margin-bottom: 8px;

      font-size: 28px;
      line-height: 35px;
    }

    ul {
      padding-left: 36px;
      margin-bottom: 20px;
    }

    li {
      padding-left: 6px;
      margin-bottom: 6px;
    }
  }

  @media all and (max-width: 640px) {
    font-size: 16px;
    line-height: 26px;

    p {
      margin-bottom: 12px;
    }

    h2 {
      margin-top: 24px;
      margin-bottom: 6px;

      font-size: 22px;
      line-height: 27px;
    }

    ul {
      padding-left: 24px;
      margin-bottom: 12px;
    }

    li {
      padding-left: 4px;
      margin-bottom: 6px;
    }
  }
`;


export const OutlinkContainer = styled.div`
  margin-top: 40px;
`;

export const OptionalLinkList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  @media all and (max-width: 640px) {
    grid-column-gap: 6px;
  }
`;

export const Link = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
  height: 50px;
  background: transparent;

  font-weight: 600;
  border: 1px solid white;
  border-radius: 8px;
  transition: 0.25s all ease;

  span {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
    height: 42px;

    margin-bottom: 8px;

    span {
      margin-right: 4px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > img {
    width: 50%;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: top center;
  }

  & > div {
    margin-left: 16px;
    word-break: keep-all;

    & > ul {
      margin-top: 8px;

      & > li {
        margin-bottom: 2px;
      }
    }
  }

  @media all and (max-width: 400px) {
    margin-top: 16px;
    flex-direction: column;

    & > img {
      width: 100%;
      max-width: 256px;
    }

    & > div {
      margin-top: 16px;
      margin-left: 0;
    }
  }
`;
