import { css } from '@emotion/react';

export const reset = css`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

  html,
  body {
    box-sizing: border-box;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: #010a1e;
    color: white;
  }

  * {
    font-family: 'degular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Helvetica Neue', Arial, sans-serif;
  }

  object,
  iframe,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  sub,
  sup,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  i,
  button {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
  }

  p,
  span {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    vertical-align: baseline;
  }

  button {
    cursor: pointer;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  group,
  menu,
  nav,
  section {
    display: block;
  }

  nav ul {
    list-style: none;
  }

  ul {
    list-style-type: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  block quote:before,
  block quote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  ol,
  ul {
    list-style-position: outside;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  code,
  pre {
    font-family: Roboto Mono, Monaco, 'Courier New', monospace;
  }

  a,
  button,
  input,
  textarea {
    -webkit-appearance: none;
    outline: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
