import jsonp from 'jsonp';


type Result = {
  isLoading: boolean;
  type: 'success' | 'failure';
  message?: string;
};

const Endpoint = 'https://money.us6.list-manage.com/subscribe/post?u=2f70d5ffbbeb541e1038ac5dd&amp&id=f5c5d4ddc0';


export const subscribe = (email: string) => {
  let interval: number;
  let result: Result = {
    isLoading: true,
    type: 'failure' as const,
    message: '',
  };

  return new Promise<Result>((resolve, reject) => {
    interval = window.setInterval(() => {
      if (result.isLoading) {
        return;
      }

      window.clearTimeout(interval);

      if (result.type === 'success') {
        resolve(result);
        return;
      }

      reject(result);
    }, 100);

    const requestURL = `${Endpoint}&EMAIL=${email}`.replace('/post?', '/post-json?');
    jsonp(requestURL, { param: 'c' }, (err, data) => {
      if (err || data?.result !== 'success') {
        result = {
          isLoading: false,
          type: 'failure' as const,
          message: data?.msg ?? err,
        };
      }

      result = {
        isLoading: false,
        type: 'success' as const,
        message: data?.msg,
      };
    });
  });
};
