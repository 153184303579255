import React, { useState, useCallback } from 'react';

import { AnimatePresence } from 'framer-motion';
import { ReactComponent as Icon } from 'assets/ic_help.svg';

import * as styles from './Tooltip.style';


interface Props {
  children: React.ReactNode;
}


const Tooltip = ({
  children,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);


  const handleFocusToggler = useCallback((
    nextIsOpened: typeof isOpened,
  ) => () => {
    setIsOpened(nextIsOpened);
  }, []);


  return (
    <styles.Container>
      <styles.Toggler
        type='button'
        onBlur={handleFocusToggler(false)}
        onFocus={handleFocusToggler(true)}
        onMouseEnter={handleFocusToggler(true)}
        onMouseLeave={handleFocusToggler(false)}
      >
        <Icon />
      </styles.Toggler>
      <AnimatePresence initial={false}>
        {isOpened && (
          <styles.DescriptionContainer
            variants={styles.variants}
            initial='initial'
            animate='animate'
            exit='exit'
            layout
          >
            <styles.Description>
              {React.Children.map(children, (child, idx) => (
                <styles.Paragraph key={idx}>
                  {child}
                </styles.Paragraph>
              ))}
            </styles.Description>
          </styles.DescriptionContainer>
        )}
      </AnimatePresence>
    </styles.Container>
  );
};

export default Tooltip;
