import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/logo.svg';

import WalletConnector from '../wallet-connector';
import TransactionObserver from '../transaction-observer';

import * as styles from './Navigation.style';


const ScrollBorder = 100;


const Navigation = () => {
  const [hasScrolled, setHasScrolled] = useState(false);


  const handleScrollWindow = useCallback(() => {
    setHasScrolled(window.scrollY > ScrollBorder);
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleScrollWindow, true);

    return () => {
      window.removeEventListener('scroll', handleScrollWindow, true);
    };
  }, [handleScrollWindow]);


  return (
    <styles.Container hasScrolled={hasScrolled}>
      <div css={styles.wrapper}>
        <div>
          <Link to='/'>
            <Logo css={styles.logo} />
          </Link>
        </div>
        <div css={styles.menu}>
          <div css={styles.txObserver}>
            <TransactionObserver />
          </div>
          <WalletConnector />
        </div>
      </div>
    </styles.Container>
  );
};

export default Navigation;
