import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from 'assets/ic_close.svg';

import Backdrop from '../backdrop';

import * as styles from './Modal.style';


interface Props {
  type?: 'success' | 'failure';
  children: React.ReactNode;
  onClose: () => void;
}

const Modal = ({
  type,
  children,
  onClose,
}: Props) => {
  const handleClickClose = useCallback(() => {
    onClose();
  }, [onClose]);


  useEffect(() => {
    Object.assign(document.body.style, {
      overflow: 'hidden',
    });

    return () => {
      Object.assign(document.body.style, {
        overflow: null,
      });
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <section css={styles.modal}>
        <styles.Container type={type}>
          <button
            css={styles.modalCloseButton}
            type='button'
            onClick={handleClickClose}
          >
            <CloseIcon />
          </button>
          {children}
        </styles.Container>
      </section>
      <Backdrop />
    </>,
    document.body,
  );
};

export default Modal;
