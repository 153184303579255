import React, { useState, useEffect } from 'react';

import { useConnectedWallet, TxResult } from '@terra-money/wallet-provider';
import { useTxDispatch } from 'utils';
import { useRefetchUserInfo } from 'queries';

import {
  Backdrop,
  Spinner,
  TxBroadcastingModal,
} from 'components';

import * as styles from './TokenPageClaimConfirm.style';
import { PylonGatewayPool } from '@pylon-protocol/sdk';


interface Props {
  pool: PylonGatewayPool;
  symbol: string;
  onClose: () => void;
}


const TokenPageClaimConfirm = ({
  symbol,
  pool,
  onClose,
}: Props) => {
  const wallet = useConnectedWallet();

  const { forceUpdate } = useRefetchUserInfo();
  const {
    isTransacting,
    send,
  } = useTxDispatch();

  const [txResult, setTxResult] = useState<TxResult | undefined>(undefined);


  const sendTransaction = async () => {
    if (!wallet) { return; }

    try {
      const data = await pool.claimRewards(wallet.terraAddress);
      const options = {
        action: `Claim rewards from $${symbol} Pool`,
        callback: forceUpdate,
      };

      const result = await send(data.transactions, options);
      if (!result?.payload) { return; }

      setTxResult(result.payload);
    } catch {
    }
  };


  useEffect(() => {
    sendTransaction();
  }, []); // eslint-disable-line


  if (txResult) {
    return (
      <TxBroadcastingModal
        isTransacting={isTransacting}
        type={txResult?.success ? 'success' : 'failure'}
        details={txResult}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      <Spinner css={styles.spinner} size={60} />
      <Backdrop />
    </>
  );
};

export default TokenPageClaimConfirm;
