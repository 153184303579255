import { css } from '@emotion/react';

export const globalStyle = css`
  html,
  body {
    background: radial-gradient(
        106.45% 97.37% at 50% 92.59%,
        #000000 0%,
        #0852f6 48.96%,
        #01d9f7 100%
      ),
      linear-gradient(180deg, #01d9f7 0%, #0d72fd 100%), #ffffff;

    overflow-x: hidden;
  }

  @media all and (max-width: 640px) {
    html,
    body {
      background: radial-gradient(
          151.11% 100% at 50% 100%,
          #000000 0%,
          #0852f6 48.96%,
          #01d9f7 100%
        ),
        linear-gradient(180deg, #01d9f7 0%, #0d72fd 100%), #ffffff;
    }
  }
`;

export const container = css`
  max-width: 1300px;
  min-height: 100vh;

  margin: 0 auto;
  padding: 0 8%;

  @media all and (max-width: 640px) {
    padding: 0 24px;
  }
`;

// Hero

export const header = css`
  padding-top: 160px;
  margin-bottom: 56px;
  text-align: center;

  @media all and (max-width: 640px) {
    padding-top: 120px;
    margin-bottom: 60px;
  }
`;

export const hero = css`
  margin-bottom: 24px;

  font-weight: 700;
  font-size: 128px;
  line-height: 128px;

  @media all and (max-width: 1024px) {
    font-size: 80px;
    line-height: 80px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 10px;

    font-size: 40px;
    line-height: 40px;
  }
`;

export const description = css`
  font-size: 18px;
  line-height: 26px;

  p {
    margin-bottom: 16px;
  }

  a {
    font-weight: 600;
  }

  @media all and (max-width: 1024px) {
    max-width: 480px;
    margin: 0 auto;
  }

  @media all and (max-width: 640px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
