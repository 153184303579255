import { Badge, Countdown, ExternalLink } from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { projectSelector, symbolAtom } from 'recoils/ProjectRecoil';
import { ceil, usePylon } from 'utils';

import { PylonGatewayProject, PylonSwap } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import * as styles from './TokenPageHeader.style';
import TokenPageLogo from './TokenPageHeader.symbol';

const TokenPageHeader = ({ swap: _swap }: { swap: PylonSwap }) => {
  const symbol = useRecoilValue(symbolAtom);
  const projectDefinition = useRecoilValue(
    projectSelector(symbol),
  )?.projectDefinition;

  const pylon = usePylon();
  const [project, setProject] = useState<PylonGatewayProject | null>(null);
  const [poolStatus, setPoolStatus] = useState<{
    totalSupply: number;
    soldAmount: number;
    totalDepositsInUst: number;
  }>({
    totalSupply: 0,
    soldAmount: 0,
    totalDepositsInUst: 0,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_swapStatus, setSwapStatus] = useState<{
    currentPrice: number;
    totalSold: number;
    balance: number;
    availableSwapCap: number;
    hasPassedKyc: boolean;
  }>({
    currentPrice: 0,
    totalSold: 0,
    balance: 0,
    availableSwapCap: 0,
    hasPassedKyc: false,
  });

  useEffect(() => {
    if (!pylon || !projectDefinition) {
      return;
    }
    pylon.gateway
      .projectFromDefinition(projectDefinition)
      .then(setProject)
      .catch(console.log);
  }, [pylon, projectDefinition]);

  const startDate = dayjs.utc(projectDefinition?.startsAt);
  const isLaunchStarted = dayjs.utc().isAfter(startDate);

  useEffect(() => {
    if (!project) {
      return;
    }
    const getPoolInfos = () =>
      Promise.all(
        Object.values(project.pools ?? []).map(async (pool) => ({
          totalDepositsInUst: await pool.totalDepositAmount(),
          ...(await pool.info()),
        })),
      );

    getPoolInfos().then((infos) => {
      const isWormhole = ['xdefi', 'orion'].includes(symbol.toLowerCase());
      const multiplier = isWormhole ? 100 : 1;

      const status = infos.reduce(
        (acc, poolInfo) => {
          acc.totalSupply += ceil(poolInfo.totalSupply ?? 0) * multiplier;
          acc.soldAmount += (poolInfo.distributedSupply ?? 0) * multiplier;
          acc.totalDepositsInUst += poolInfo.totalDepositsInUst ?? 0;
          return acc;
        },
        {
          totalSupply: 0,
          soldAmount: 0,
          totalDepositsInUst: 0,
        },
      );
      setPoolStatus(status);
    });
  }, [project, symbol]);

  const wallet = useConnectedWallet();
  useEffect(() => {
    const getStatus = async (address: string) => {
      if (!project) {
        return;
      }
      const swapInfos = await Promise.all(
        project.swaps.map(async (swap) => {
          const totalDeposits = await swap.totalDeposits();
          const status = await swap.statusOf(address);

          return {
            currentPrice: swap.priceInUst,
            totalSold: parseFloat(totalDeposits.toFixed(1)),
            balance: status.swappedUst,
            availableSwapCap: status.availableSwapCap,
            hasPassedKyc: status.whitelisted,
          };
        }),
      );
      setSwapStatus(swapInfos[0]);
    };

    if (!wallet?.terraAddress) {
      return;
    }
    getStatus(wallet.terraAddress);
  }, [project, wallet?.terraAddress]);

  const {
    // soldAmount: poolSoldAmount = 0,
    // totalSupply: poolTotalSupply = 0,
    totalDepositsInUst = 0,
  } = poolStatus;

  // const swapTotalSupply = (swap as any as FixedSwapInfo)?.totalSupply ?? 0;
  // const swapTotalSold = swapStatus?.totalSold ?? 0;

  // const isXDEFI = symbol.toLowerCase() === 'xdefi';
  // const soldAmount = (poolSoldAmount + swapTotalSold) / (isXDEFI ? 100 : 1);

  // FIXME: Remove this after contract integration
  // const isPsi = symbol.toLowerCase() === 'psi';
  // const totalSupply = useMemo(
  //   () =>
  //     isPsi
  //       ? 300_000_000
  //       : (poolTotalSupply + swapTotalSupply) / (isXDEFI ? 100 : 1),
  //   [isPsi, isXDEFI, poolTotalSupply, swapTotalSupply],
  // );

  // const safeSoldAmount = useMemo(() => {
  //   let value = soldAmount;
  //   if (symbol.toLowerCase() === 'mine' && value > 200_000_000) {
  //     value -= 200_000_000;
  //   } else {
  //     value = Math.min(value, totalSupply);
  //   }
  //   return value;
  // }, [soldAmount, symbol, totalSupply]);

  // const percent = useMemo(() => {
  //   if (!safeSoldAmount || !totalSupply) {
  //     return 0;
  //   }
  //   return Math.min(safeSoldAmount / totalSupply, 1);
  // }, [safeSoldAmount, totalSupply]);

  return (
    <header css={styles.container}>
      <TokenPageLogo symbol={symbol} />
      <div css={styles.subInfo}>
        <span css={styles.symbol}>{`$${symbol}`}</span>
        {isLaunchStarted && (
          <Badge css={styles.badge}>
            {symbol.toLowerCase() === 'gfi'
              ? 'Coming Soon'
              : `Live since ${startDate.format('YYYY MMM DD')}`}
          </Badge>
        )}
        {!isLaunchStarted && (
          <Countdown
            to={startDate.format()}
            onEnd={() => {
              window.location.reload();
            }}
          />
        )}
      </div>
      <div css={styles.section}>
        <h1 css={styles.title}>{projectDefinition?.name}</h1>
        <p css={styles.description}>{projectDefinition?.summary}</p>
      </div>
      {symbol.toLowerCase() === 'gfi' ? (
        <React.Fragment>
          <div css={[styles.section, styles.depositContainer]}>
            <div>
              <p css={styles.subheading}>Total Deposited</p>
              <p css={styles.subdescription}>
                {`${parseInt(
                  `${totalDepositsInUst}`,
                  10,
                ).toLocaleString()} UST`}
              </p>
            </div>
          </div>
          <div css={[styles.section, styles.depositContainer]}>
            <div>
              <p css={styles.subheading}>Yield Accumulated</p>
              <p css={styles.subdescription}>
                {/* FIXME: `totalDepositsInUst` -> to show generated yield */}
                {`${parseInt(
                  `${totalDepositsInUst}`,
                  10,
                ).toLocaleString()} UST`}
              </p>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {symbol.toLowerCase() !== 'psi' && (
            <div css={[styles.section, styles.depositContainer]}>
              <div>
                <p css={styles.subheading}>Total Deposited</p>
                <p css={styles.subdescription}>
                  {`${parseInt(
                    `${totalDepositsInUst}`,
                    10,
                  ).toLocaleString()} UST`}
                </p>
              </div>
            </div>
          )}
          {/* <div css={styles.section}>
            <p css={styles.subheading}>Tokens Distributed</p>
            <div css={styles.progressbarDescription}>
              <p css={styles.subdescription}>
                {`${safeSoldAmount.toLocaleString()} (${parseFloat(
                  (percent * 100).toFixed(1),
                )}%)`}
              </p>
              <p
                css={styles.subdescription}
              >{`${totalSupply.toLocaleString()} max.`}</p>
            </div>
            <div css={styles.progressbarContainer}>
              <div
                css={styles.progressbarStatus}
                style={{
                  width: `${percent * 100}%`,
                }}
              />
            </div>
          </div> */}
        </React.Fragment>
      )}
      <ul css={styles.outlinkContainer}>
        {projectDefinition?.links.map(({ name, href }) => (
          <li key={href}>
            <ExternalLink to={href}>{name}</ExternalLink>
          </li>
        ))}
      </ul>
    </header>
  );
};

export default TokenPageHeader;
