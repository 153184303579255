import { Countdown, InfoBlock, Tab, TabContainer } from 'components';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { symbolAtom } from 'recoils/ProjectRecoil';
import { useCaptcha } from 'utils';

import { css } from '@emotion/react';
import { PylonSwap, PylonSwapDepositor } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import TokenPageFixedSwap from '../token-page-fixed-swap';
import TokenPageReverseSwap from '../token-page-reverse-swap';
import TokenPageTradeConfirm from '../token-page-trade-confirm';
import * as styles from './TokenPageTrade.style';

type TradeType = 'buy' | 'sell';

interface Props {
  // info: SwapInfo | FixedSwapInfo;
  // fixedSwap?: boolean;
  swap: PylonSwap;
}

const DatetimeFormat = 'YYYY MMM DD HH:mm:ss[Z]';

const Tabs = {
  token: 'token',
  ust: 'ust',
};

const TokenPageTrade = ({ swap }: Props) => {
  const wallet = useConnectedWallet();

  const symbol = useRecoilValue(symbolAtom);
  // const { data: swap } = useSwapStatus(info, wallet?.terraAddress);

  const [tab, setTab] = useState(Tabs.token);
  const [confirm, setConfirm] = useState<TradeType | null>(null);
  const [value, setValue] = useState(0);
  const [currentTime, setCurrentTime] = useState(dayjs.utc());

  const { trigger } = useCaptcha();

  const handleChangeTab = useCallback(
    (nextTab: string) => () => {
      setTab(nextTab);
    },
    [],
  );

  const handleSubmitTrade = useCallback(
    (type: TradeType) => (amount: number) => {
      trigger();

      setConfirm(type);
      setValue(amount);
    },
    [trigger],
  );

  const handleCloseConfirm = useCallback(() => {
    setConfirm(null);
    setValue(0);
  }, []);

  const handleSyncCurrentTime = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const { isSwapStarted, swapStartDate, lockUpFinishDate, isLockupFinished } =
    useMemo(() => {
      const { start = new Date(), finish = new Date() } = swap;

      const lockUpFinishDate = dayjs.utc(finish);
      const isLockupFinished = currentTime.isAfter(lockUpFinishDate);

      let isSwapStarted = true;
      const swapStartDate = dayjs.utc(start);
      if (swapStartDate) {
        isSwapStarted = currentTime.isAfter(swapStartDate);
      }
      return {
        isSwapStarted,
        swapStartDate,
        lockUpFinishDate,
        isLockupFinished,
      };
    }, [currentTime, swap]);

  const [totalClaims, setTotalClaims] = useState<number>(0);
  const [depositor, setDepositor] = useState<PylonSwapDepositor | null>(null);
  useEffect(() => {
    swap
      .totalClaims()
      .then(setTotalClaims)
      .catch((err) => {
        console.error(err);
      });
    if (!wallet?.terraAddress) {
      return;
    }
    swap
      .statusOf(wallet.terraAddress)
      .then(setDepositor)
      .catch((err) => {
        console.error(err);
      });
  }, [swap, wallet?.terraAddress]);
  const isSoldout = useMemo(
    () => totalClaims >= swap.totalAmount,
    [swap.totalAmount, totalClaims],
  );

  const StartCountdown = (
    <Countdown
      to={swapStartDate.format(DatetimeFormat)}
      onChange={handleSyncCurrentTime}
      onEnd={() => {
        window.location.reload();
      }}
    />
  );

  const depositLog = useMemo(
    () => ({
      type: 'swap' as const,
      balanceInUst: depositor?.swappedUst ?? 0,
      rewardTokens: depositor?.claimableRewards ?? 0,
      remainingTokens:
        (depositor?.totalRewards ?? 0) - (depositor?.claimableRewards ?? 0),
      swap,
    }),
    [depositor, swap],
  );

  if (!swap || !wallet?.terraAddress) {
    return null;
  }

  if ((isSoldout || isLockupFinished) && (depositor?.swappedUst ?? 0) <= 0) {
    return (
      <InfoBlock>
        {`'Pylon Swap for $${symbol}' has ended. Only visible for participants.`}
      </InfoBlock>
    );
  }

  if (!isSwapStarted && depositor?.whitelisted) {
    return (
      <>
        <InfoBlock>
          {`'Pylon Swap for $${symbol}' will start at ${dayjs
            .utc(swapStartDate)
            .format(DatetimeFormat)}`}
        </InfoBlock>
        {StartCountdown}
      </>
    );
  }

  if (swap.isWhitelisted && !depositor?.whitelisted) {
    return (
      <InfoBlock>
        {`Pylon Swap for $${symbol} is only available with KYC Verification.`}
      </InfoBlock>
    );
  }

  const isPsi = symbol.toLowerCase() === 'psi';

  return (
    <div
      css={[
        styles.container,
        isPsi &&
          css`
            margin-bottom: 16px; // FIXME: note: this is for the disclaimer
          `,
      ]}
    >
      {/* {(info as FixedSwapInfo).description && (
        <div
          css={[
            styles.swapDescription,
            !isSwapStarted && styles.waitingForSwapStart,
          ]}
        >
          {(info as FixedSwapInfo).description}
        </div>
      )} */}
      <TabContainer css={styles.tab}>
        <Tab current={tab === Tabs.token} onClick={handleChangeTab(Tabs.token)}>
          {`To $${symbol}`}
        </Tab>
        {!isPsi && (
          <Tab
            current={tab === Tabs.ust}
            disabled={!isSwapStarted || isLockupFinished}
            onClick={handleChangeTab(Tabs.ust)}
          >
            {`To $${Tabs.ust.toUpperCase()}`}
          </Tab>
        )}

        {swap.isWhitelisted && (
          <span css={styles.exclusive}>KYC Users Only</span>
        )}
      </TabContainer>
      {tab === Tabs.token && (
        <TokenPageFixedSwap
          symbol={symbol}
          swap={swap}
          // info={info}
          totalClaims={totalClaims}
          isSwapStarted={isSwapStarted}
          isLockupFinished={isLockupFinished}
          maximumCapInUst={depositor?.availableSwapCap ?? 0}
          // fixedSwap={fixedSwap}
          onSubmit={handleSubmitTrade('buy')}
        />
      )}
      {tab === Tabs.ust && !isPsi && (
        <TokenPageReverseSwap
          symbol={symbol}
          swap={swap}
          depositor={depositor}
          isSwapStarted={isSwapStarted}
          isLockupFinished={isLockupFinished}
          totalClaims={totalClaims}
          maximumCapInUst={depositor?.availableSwapCap ?? 0}
          // fixedSwap={fixedSwap}
          onSubmit={handleSubmitTrade('sell')}
        />
      )}
      {isSwapStarted ? (
        <Countdown
          to={lockUpFinishDate.format(DatetimeFormat)}
          onChange={handleSyncCurrentTime}
        />
      ) : (
        StartCountdown
      )}
      {confirm && (
        <React.Suspense fallback={<></>}>
          <TokenPageTradeConfirm
            type={confirm}
            info={depositLog}
            amount={value}
            onClose={handleCloseConfirm}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default TokenPageTrade;
