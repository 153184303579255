import React, { useEffect, useRef } from 'react';

import lottie from 'lottie-web';
import type {
  AnimationItem,
  AnimationEventName,
  AnimationEventCallback,
} from 'lottie-web';


export interface Props {
  data: any,
  loop?: boolean;
  autoplay?: boolean;
  eventListener?: {
    [eventName: string]: any;
  };
}


const Lottie = ({
  data: animationData,
  loop = false,
  autoplay = true,
  eventListener = {},
  ...props
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animation = useRef<AnimationItem>();


  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    animation.current = lottie.loadAnimation({
      container: containerRef.current as Element,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
    });

    Object.entries(eventListener).forEach(([eventName, callback]) => {
      animation.current?.addEventListener(
        eventName as AnimationEventName,
        callback as AnimationEventCallback,
      );
    });

    animation.current.play();
  }, [animationData, autoplay, eventListener, loop]);


  return (
    <div
      ref={containerRef}
      {...props}
    />
  );
};

export default React.memo(Lottie);
