import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const container = css`
  position: relative;
  display: inline-block;
`;

export const WalletButton = styled.button<{
  connected?: boolean;
}>(
  ({ connected, disabled }) => `
  display: inline-flex;
  align-items: center;

  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  font-size: 16px;
  line-height: 16px;
  color: white;

  transition: 0.2s all ease;

  ${
    connected
      ? `
    background: #333333;
  `
      : ''
  }

  ${
    disabled
      ? `
    cursor: not-allowed;
  `
      : ''
  }

  @media all and (max-width: 640px) {
    padding: 6px 10px;
    font-size: 13px;
    line-height: 13px;
  }
`,
);

export const walletIcon = css``;

export const walletAddress = css`
  margin-left: 8px;
`;

export const walletBalance = css`
  margin-left: 10px;
`;

export const hideAtMobile = css`
  @media all and (max-width: 640px) {
    display: none;
  }
`;

export const walletDefaultIcon = css`
  path {
    fill: white;
  }
`;
