import { useEffect, useState } from 'react';
import { usePylon } from 'utils';

import { useConnectedWallet } from '@terra-money/wallet-provider';

export const useGovStakedAmount = () => {
  const pylon = usePylon();
  const wallet = useConnectedWallet();
  const [stakedBalance, setStakedBalance] = useState<number | null>(null);

  useEffect(() => {
    if (!wallet?.terraAddress || !pylon) {
      return;
    }

    pylon.governance
      .stakedBalance(wallet.terraAddress)
      .then(setStakedBalance)
      .catch(console.log);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.terraAddress]);

  return stakedBalance;
};
