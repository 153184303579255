import { css } from '@emotion/react';



export const container = css`
  margin: 24px 0;
`;

export const infoContainer = css`
  display: flex;

  margin-bottom: 24px;

  font-size: 15px;
  line-height: 15px;

  > div {
    padding-right: 32px;
    flex: 1;
  }

  @media all and (max-width: 1024px) {
    margin-top: 8px;
    margin-bottom: 16px;

    > div {
      padding-right: 24px;
    }
  }

  @media all and (max-width: 640px) {
    font-size: 13px;
    line-height: 13px;
  }
`;

export const subheading = css`
  margin-bottom: 8px;

  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    margin-bottom: 4px;
  }
`;

export const tooltipText = css`
  margin-right: 6px;
`;

export const distributionPercent = css`
  margin-left: 6px;

  color: rgba(255, 255, 255, 0.5);
`;
