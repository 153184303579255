import React, { useCallback, useEffect, useMemo } from 'react';
import { Terrascope } from 'utils/terrascope';

import { ReactComponent as CloseIcon } from './ic_close.svg';
import { ReactComponent as FailureIcon } from './ic_failure.svg';
import { ReactComponent as SuccessIcon } from './ic_success.svg';
import * as styles from './Toast.style';

interface Props {
  id: string;
  intent?: 'success' | 'failure';
  title?: string;
  data?: {
    action: string;
    hash?: string;
    chainID?: string;
  };
  onClose: (id: string) => void;
}

const Toast = ({ id, intent = 'success', title, data, onClose }: Props) => {
  const handleClickClose = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      handleClickClose();
    }, styles.Duration.CLOSE);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [handleClickClose]);

  const finderHref = useMemo(() => {
    if (data?.hash && data?.chainID) {
      return Terrascope.getTransactionLink(data.chainID, data.hash);
    }
    return '';
  }, [data]);

  return (
    <styles.Container
      animate="visible"
      exit="hidden"
      initial="initial"
      variants={styles.Variants}
    >
      <styles.IconArea intent={intent}>
        {intent === 'success' && <SuccessIcon />}
        {intent === 'failure' && <FailureIcon />}
      </styles.IconArea>
      <styles.ContentArea>
        <styles.Title>{title ?? data?.action ?? ''}</styles.Title>
        {finderHref && (
          <>
            <styles.Link to={finderHref}>View on Terra Finder</styles.Link>
          </>
        )}
      </styles.ContentArea>
      <styles.CloseButton type="button" onClick={handleClickClose}>
        <CloseIcon />
      </styles.CloseButton>
    </styles.Container>
  );
};

export default Toast;
