import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Icon } from 'assets/ic_info.svg';


interface Props extends React.ComponentProps<typeof Container> {
}


const icon = css`
  display: inline-block;
  flex-shrink: 0;

  margin-top: 4px;
  margin-right: 12px;

  width: 16px;
  height: 16px;
  opacity: 0.5;

  @media all and (max-width: 640px) {
    margin-right: 8px;

    width: 14px;
    height: 14px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 24px;
  margin-bottom: 8px;

  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.66);

  background: rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  @media all and (max-width: 640px) {
    padding: 12px 16px;
    font-size: 13px;
    line-height: 18px;
  }
`;


const InfoBlock = ({
  children,
  ...props
}: Props) => (
  <Container {...props}>
    <Icon css={icon} />
    <p>
      {children}
    </p>
  </Container>
);

export default InfoBlock;
