export const mainnet = {
  name: 'classic',
  chainID: 'columbus-5',
  lcd: 'https://columbus-lcd.terra.dev',
};

export const WalletConnectOptions = {
  bridge: 'https://walletconnect.terra.dev/',
};

export const loader = () => ({
  isProduction: true,
  chainProvider: { 0: mainnet },
  walletConnectOptions: WalletConnectOptions,
});
