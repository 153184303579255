import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { projectSelector } from 'recoils/ProjectRecoil';

type UseNftLotteryPoolParams = {
  symbol: string;
  stakingTokenSymbol: string;
};

export const useNftLotteryPool = ({
  symbol,
  stakingTokenSymbol,
}: UseNftLotteryPoolParams) => {
  const project = useRecoilValueLoadable(projectSelector(symbol));

  const pool = useMemo(() => {
    if (stakingTokenSymbol !== 'ust') {
      return null;
    }

    // wait for loading project info
    if (project.state !== 'hasValue' || !project.contents) {
      return null;
    }

    // TODO: Implement multiple lottery pools
    return Object.values(project.contents.pools)[0];
  }, [project, stakingTokenSymbol]);

  return pool;
};
