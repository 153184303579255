import { Button, DisclaimerBlock, Input, Modal, Spinner, TxBroadcastingModal } from 'components';
import dayjs from 'dayjs';
import { usePoolDeposit, useRefetchUserInfo } from 'queries';
import { usePoolInfo } from 'queries/usePoolInfo';
import React, { useCallback, useState } from 'react';
import { decimalToNumber, getEstimatedReturn, toFixed, useTxDispatch } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { MsgExecuteContract } from '@terra-money/terra.js';

import * as styles from './TokenPagePoolConfirm.style';

import type { TxResult } from '@terra-money/wallet-provider';
type Props = {
  deposit: number;
  symbol: string;
  pool: PylonGatewayPool;
  poolID: number;
  additionalTransactions?: MsgExecuteContract[];
  onClose: () => void;
};

const TokenPagePoolConfirm: React.FC<Props> = ({
  deposit,
  symbol,
  pool,
  poolID,
  additionalTransactions,
  onClose,
}) => {
  const { forceUpdate } = useRefetchUserInfo();
  const { isTransacting, send } = useTxDispatch();

  const { data, remove } = usePoolDeposit(
    pool,
    deposit,
    additionalTransactions,
  );

  const { isFetching, data: poolInfo } = usePoolInfo({ pool, symbol });
  const isXDEFI = symbol.toLowerCase() === 'xdefi';

  const [txResult, setTxResult] = useState<TxResult | undefined>(undefined);

  const handleClickClose = useCallback(() => {
    remove();
    onClose();
  }, [remove, onClose]);

  const handleClickSubmit = async () => {
    if (!data) {
      return;
    }

    const action = additionalTransactions
      ? `Depositing via Valkyrie Campaign`
      : `Deposit ${deposit.toLocaleString()} UST to $${symbol} Pool ${poolID}`;
    const options = {
      action,
      fee: Number(data.transactionFee?.amount ?? 0),
      tax: Number(data.tax?.amount ?? 0),
      callback: forceUpdate,
      autoEstimated: true,
    };

    const result = await send(data.transactions, options);
    if (!result?.payload) {
      return;
    }

    setTxResult(result.payload);
  };

  if (txResult) {
    return (
      <TxBroadcastingModal
        isTransacting={isTransacting}
        type={txResult?.success ? 'success' : 'failure'}
        details={txResult}
        onClose={handleClickClose}
      />
    );
  }

  const distributedPercent =
    poolInfo.distributedSupply &&
    poolInfo.totalSupply &&
    toFixed((poolInfo.distributedSupply / poolInfo.totalSupply) * 100, 2);

  const estimatedReturns =
    getEstimatedReturn(
      deposit,
      poolInfo.totalSupply ?? 0,
      poolInfo.totalDepositsInUst,
    ) / (isXDEFI ? 100 : 1);

  const vestingInMonths = Math.round(
    dayjs(pool.withdrawTime[0].end).diff(
      pool.withdrawTime[0].start,
      'month',
      true, // round
    ),
  );

  return (
    <Modal onClose={handleClickClose}>
      <h1 css={styles.modalHeading}>
        {additionalTransactions
          ? `Depositing via Valkyrie Campaign`
          : `Depositing for $${symbol} Pool ${poolID + 1}`}
      </h1>
      <div css={styles.vestingInfoContainer}>
        <span>{`${poolInfo.apy.toLocaleString()}% APR`}</span>
        <span>{`Vesting over ${vestingInMonths} months`}</span>
      </div>
      <ul css={styles.participantsContainer}>
        <li>
          <p css={styles.subHeading}>Tokens Distributed</p>
          <p>
            {!isFetching && (
              <>
                {`${toFixed(
                  poolInfo.distributedSupply / (isXDEFI ? 100 : 1),
                  2,
                ).toLocaleString()} / ${toFixed(
                  poolInfo.totalSupply / (isXDEFI ? 100 : 1),
                  2,
                ).toLocaleString()} `}
              </>
            )}
            <span css={styles.vestingPercent}>
              {`(${parseFloat(distributedPercent.toFixed(2))}%)`}
            </span>
          </p>
        </li>
      </ul>
      <div css={styles.depositContainer}>
        <Input
          css={styles.input}
          type="text"
          label="UST"
          placeholder="0.00"
          value={deposit.toLocaleString()}
          disabled
        />
        <p>You can reclaim this deposit once your pledged duration ends.</p>
      </div>
      <article css={styles.feeContainer}>
        <table css={styles.feeTable}>
          <tbody>
            <tr>
              <th>{'Transaction Fee & Tax'}</th>
              <td>
                {data
                  ? `${toFixed(
                      decimalToNumber(
                        data.transactionFee?.amount.toString() ?? '0',
                      ),
                      3,
                    )} UST & ${`${toFixed(
                      decimalToNumber(data.tax?.amount.toString() ?? '0'),
                      3,
                    )} UST`}`
                  : '-'}
              </td>
            </tr>
            <tr>
              <th>Estimated Returns</th>
              <td>
                {isFetching
                  ? '-'
                  : `${estimatedReturns.toLocaleString()} ${symbol}`}
              </td>
            </tr>
          </tbody>
        </table>
        <DisclaimerBlock
          css={styles.disclaimer}
          symbol={symbol}
          showEstimatedWarning
        />
      </article>
      <Button
        css={styles.submitButton}
        disabled={isFetching || isTransacting}
        onClick={handleClickSubmit}
      >
        {!data ? (
          'Preparing...'
        ) : (
          <>{isTransacting ? <Spinner color="#000000" /> : 'Confirm Deposit'}</>
        )}
      </Button>
    </Modal>
  );
};

export default TokenPagePoolConfirm;
