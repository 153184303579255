import { Coin, Dec, Denom, Int } from '@terra-money/terra.js';

const DECIMALS = 1e6;
const DEFAULT_GAS = 1_671_053;

export const Denoms = {
  USD: 'uusd',
};

export function decimalToNumber(dec: string): number {
  return new Dec(dec).div(DECIMALS).toNumber();
}

export function numberToDecimal(num: number): Dec {
  return new Dec(num).mul(DECIMALS);
}

export function coin(amount: number, denom: Denom): Coin {
  return new Coin(denom, new Int(numberToDecimal(amount)).toString());
}

export function getDefaultGas() {
  return DEFAULT_GAS;
}
