import { Button, Input, Modal, Spinner, TxBroadcastingModal } from 'components';
import { usePoolDeposit, usePoolWithdraw, useRefetchUserInfo } from 'queries';
import { useNftLotteryPool } from 'queries/useNftLotteryPool';
import useStake from 'queries/useStake';
import useUnstake from 'queries/useUnstake';
import React, { useCallback, useState } from 'react';
import { decimalToNumber, toFixed, useTxDispatch } from 'utils';

import { NftLotteryPoolDefinition } from '@pylon-protocol/sdk/lib/pylon-api';

import * as styles from './NftLotteryPoolConfirm.style';

import type { TxResult } from '@terra-money/wallet-provider';
type Props = {
  deposit: number;
  symbol: string;
  confirmAction: 'stake' | 'unstake';
  stakingTokenSymbol: string;
  pool: NftLotteryPoolDefinition;
  onClose: () => void;
};

const NftLotteryPoolConfirm = ({
  deposit,
  symbol,
  confirmAction,
  stakingTokenSymbol,
  pool,
  onClose,
}: Props) => {
  const { forceUpdate } = useRefetchUserInfo();
  const { isTransacting, send } = useTxDispatch();

  const { data: stakeData, remove: removeStake } = useStake({
    amount: deposit,
    enabled: stakingTokenSymbol === 'mine' && confirmAction === 'stake',
  });
  const { data: unstakeData, remove: removeUnstake } = useUnstake({
    amount: deposit,
    enabled: stakingTokenSymbol === 'mine' && confirmAction === 'unstake',
  });
  const lockupPool = useNftLotteryPool({
    symbol,
    stakingTokenSymbol: 'ust',
  });
  // const taxExcluded = stakingTokenSymbol === 'ust';
  const { data: lockupPoolDepositData, remove: lockupPoolDepositRemove } =
    usePoolDeposit(lockupPool, deposit);
  const { data: lockupPoolWithdrawData, remove: lockupPoolWithdrawRemove } =
    usePoolWithdraw(lockupPool, deposit);
  const data =
    stakeData ||
    unstakeData ||
    (confirmAction === 'stake'
      ? lockupPoolDepositData
      : lockupPoolWithdrawData);
  const remove =
    removeStake ||
    removeUnstake ||
    lockupPoolDepositRemove ||
    lockupPoolWithdrawRemove;

  const [txResult, setTxResult] = useState<TxResult | undefined>(undefined);

  const handleClickClose = useCallback(() => {
    remove();
    onClose();
  }, [remove, onClose]);

  const handleClickSubmit = async () => {
    if (!data) {
      return;
    }

    const action =
      confirmAction === 'stake'
        ? `Stake ${deposit.toLocaleString()} ${stakingTokenSymbol.toUpperCase()} to ${
            pool.name
          }`
        : `Unstake ${deposit.toLocaleString()} ${stakingTokenSymbol.toUpperCase()} from ${
            pool.name
          }`;
    const options = {
      action,
      fee: Number(data.transactionFee?.amount ?? 0),
      tax: Number(data.tax?.amount ?? 0),
      callback: forceUpdate,
    };

    const result = await send(data.transactions, options);
    if (!result?.payload) {
      return;
    }

    setTxResult(result.payload);
  };

  if (txResult) {
    return (
      <TxBroadcastingModal
        isTransacting={isTransacting}
        type={txResult?.success ? 'success' : 'failure'}
        details={txResult}
        onClose={handleClickClose}
      />
    );
  }

  return (
    <Modal onClose={handleClickClose}>
      <h1 css={styles.modalHeading}>
        {confirmAction === 'stake'
          ? `Stake to ${pool.name}`
          : `Unstake from ${pool.name}`}
      </h1>
      <div css={styles.depositContainer}>
        <Input
          css={styles.input}
          type="text"
          label={stakingTokenSymbol.toUpperCase()}
          placeholder="0.00"
          value={deposit.toLocaleString()}
          disabled
        />
      </div>
      <article css={styles.feeContainer}>
        <table css={styles.feeTable}>
          <tbody>
            <tr>
              <th>{'Transaction Fee & Tax'}</th>
              <td>
                {data
                  ? `${toFixed(
                      decimalToNumber(
                        data.transactionFee?.amount.toString() ?? '0',
                      ),
                      3,
                    )} UST & ${`${toFixed(
                      decimalToNumber(data.tax?.amount.toString() ?? '0'),
                      3,
                    )} UST`}`
                  : '-'}
              </td>
            </tr>
          </tbody>
        </table>
      </article>
      <Button
        css={styles.submitButton}
        disabled={!data || isTransacting}
        onClick={handleClickSubmit}
      >
        {!data ? (
          'Preparing...'
        ) : (
          <>
            {isTransacting ? (
              <Spinner color="#000000" />
            ) : (
              `Confirm ${confirmAction === 'stake' ? 'Stake' : 'Unstake'}`
            )}
          </>
        )}
      </Button>
    </Modal>
  );
};

export default NftLotteryPoolConfirm;
