import { css } from '@emotion/react';


export const spinner = css`
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: 1000;
`;
