import Layout from 'containers/layout';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import DashboardPage from './dashboard-page';
import MainPage from './main-page';
import NftPage from './nft-page';
import NotFoundPage from './not-found-page';
import OutagePage from './outage-page';
import TokenPage from './token-page';

const Routes = () => (
  <React.Suspense fallback={<></>}>
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Layout>
          <Switch>
            <Route path="/" component={MainPage} exact />
            <Route path="/tokens/:symbol" component={TokenPage} exact />

            <Route path="/info" component={DashboardPage} exact />
            <Route path="/nft/:symbol" component={NftPage} exact />
            <Route path="/500" component={OutagePage} exact />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Layout>
      </QueryParamProvider>
    </Router>
  </React.Suspense>
);

export default Routes;
