import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { useCaptcha } from 'utils';


const SITE_KEY = '6Lcb9hccAAAAAARFJjAqfTYOyhqMltKf9u3VaCM5';


const Captcha = () => {
  const ref = useRef<ReCAPTCHA>(null);

  const { onChange } = useCaptcha('execute', () => {
    ref.current?.execute();
  });


  useEffect(() => () => {
    ref.current?.reset();
  }, []);


  return (
    <ReCAPTCHA
      ref={ref}
      size="invisible"
      sitekey={SITE_KEY}
      onChange={onChange}
    />
  );
};

export default Captcha;
