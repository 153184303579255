import { useCallback } from 'react';

import { useRecoilState } from 'recoil';
import { toastAtom } from 'recoils';

import { Toast } from 'components';
import { AnimatePresence } from 'framer-motion';

import * as styles from './ToastContainer.style';


const ToastContainer = () => {
  const [toastList, setToastList] = useRecoilState(toastAtom);


  const handleClickClose = useCallback((id: string) => {
    setToastList(prev => prev.filter(item => item.id !== id));
  }, [setToastList]);


  return (
    <styles.Wrapper>
      <AnimatePresence initial={false}>
        {toastList.map(item => (
          <Toast
            {...item}
            key={item.id}
            onClose={handleClickClose}
          />
        ))}
      </AnimatePresence>
    </styles.Wrapper>
  );
};

export default ToastContainer;
