import GalacticPunksLotteryRandomIllust from 'assets/illust/lottery-random-galactic-punks.png';
import LunabullsLotteryRandomIllust from 'assets/illust/lottery-random-lunabulls.png';
import TerrabotsLotteryRandomIllust from 'assets/illust/lottery-random-terrabots.png';
import MinePoolLogo from 'assets/logo/mine-pool.png';
import UstPoolLogo from 'assets/logo/ust-pool.png';
import { Tooltip } from 'components';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Draw } from '@pylon-protocol/sdk/lib/pylon-api';

import PreviousLotteryCard from './PreviousLotteryCard';

type Props = {
  symbol?: 'ust' | 'mine';
  name?: string;
  nftName?: string;
  nftSymbol: string;
  drawAt?: string;
  chancePerTicket: number;
  chainID?: string;
  previousDraws?: Draw[];
  children?: React.ReactNode;
  hasPoolEnded: boolean;
};
const NftLotteryCard: React.FC<Props> = ({
  symbol,
  name,
  nftName,
  nftSymbol,
  drawAt,
  previousDraws,
  chainID = 'columbus-5',
  children,
  hasPoolEnded,
}) => {
  const drawTime = dayjs.utc(drawAt);
  const dropFrequency = useMemo(
    () =>
      nftSymbol === 'galactic-punks'
        ? '5 days'
        : nftSymbol === 'lunabulls' || nftSymbol === 'terrabots'
        ? '4 days'
        : '1 day',
    [nftSymbol],
  );

  return (
    <Container>
      <Header
        style={{
          flexDirection: nftSymbol === 'lunabulls' ? 'column' : 'row',
        }}
      >
        {nftSymbol === 'lunabulls' ? (
          <ImageRow>
            <Image nftSymbol={nftSymbol} src={LunabullsLotteryRandomIllust} />
            <Image
              nftSymbol={nftSymbol}
              src="https://cloudflare-ipfs.com/ipfs/QmdJQub8TRScEKp2VbRaPyLLDHDMCMEtnczKq2t5eahyzP"
            />
          </ImageRow>
        ) : (
          <Image
            nftSymbol={nftSymbol}
            src={
              nftSymbol === 'deviants-factions'
                ? 'https://deviants.mypinata.cloud/ipfs/QmYoDXs6vpJdJ424hVyme2wE8PySsWJAQSx7yGLErpGC5W'
                : nftSymbol === 'terrabots'
                ? TerrabotsLotteryRandomIllust
                : GalacticPunksLotteryRandomIllust
            }
          />
        )}

        <PreviousLotteryList
          style={{
            marginTop: nftSymbol === 'lunabulls' ? 24 : undefined,
            marginLeft: nftSymbol === 'lunabulls' ? 0 : undefined,
          }}
        >
          {previousDraws?.[0] && (
            <PreviousLotteryCard
              nftSymbol={nftSymbol}
              variant="primary"
              chainID={chainID}
              {...previousDraws[0]}
            />
          )}
          {previousDraws?.[1] && (
            <PreviousLotteryCard
              nftSymbol={nftSymbol}
              variant="secondary"
              chainID={chainID}
              {...previousDraws[1]}
            />
          )}
        </PreviousLotteryList>
      </Header>
      <PoolNameContainer>
        <PoolLogo
          src={symbol === 'ust' ? UstPoolLogo : MinePoolLogo}
          alt={`${name} logo`}
        />
        <PoolName>{name}</PoolName>
      </PoolNameContainer>
      <NftName>
        {nftSymbol === 'deviants-factions'
          ? 'Starter Pack NFT'
          : nftSymbol === 'terrabots'
          ? 'TerraBots NFT'
          : nftName}
      </NftName>

      <UntilLotteryStart nftSymbol={nftSymbol}>
        {!hasPoolEnded ? `${drawTime.fromNow(true)} Until Draw` : 'Pool Ended'}
      </UntilLotteryStart>
      <InformationRow>
        {!hasPoolEnded && (
          <React.Fragment>
            <InformationBlock
              title="Draw at"
              tooltip={`The Pool drops ${nftName} every ${dropFrequency}.`}
              description={drawTime.format('YYYY MMM DD HH:mm[Z]')}
            />
            {!['lunabulls', 'terrabots'].includes(nftSymbol) && (
              <InformationBlock
                title="Ticket counting starts at"
                description={
                  nftSymbol === 'galactic-punks'
                    ? '2021 Oct 28 13:00Z'
                    : nftSymbol === 'terrabots'
                    ? '2022 Mar 15 14:00Z'
                    : '2021 Nov 11 13:00Z'
                }
              />
            )}
          </React.Fragment>
        )}
      </InformationRow>
      <LineDivider />
      {children}
    </Container>
  );
};

export default NftLotteryCard;

const Container = styled.div`
  padding: 32px 28px 32px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.33);

  &:not(:last-child) {
    margin-bottom: 28px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1350px) and (min-width: 840px), (max-width: 600px) {
    flex-direction: column;
  }
`;

const ImageRow = styled.div`
  display: flex;

  & > img:not(:last-of-type) {
    margin-right: 16px;
  }
`;

type NftSymbolProps = {
  nftSymbol: string;
};
const Image = styled.img<NftSymbolProps>`
  width: 200px;
  height: 200px;

  border-radius: 8px;
  user-select: none;
  -webkit-user-drag: none;

  ${({ nftSymbol }) => {
    if (nftSymbol === 'galactic-punks') {
      return css`
        border: 2px solid #00eefa;
        box-shadow: 0px 8px 16px -3px rgba(0, 238, 250, 0.27);
        background: radial-gradient(
          90.04% 90.04% at 41.6% -5.66%,
          #164b78 0%,
          #11132a 100%
        );
      `;
    }
    if (nftSymbol === 'lunabulls') {
      return css`
        border: 2px solid #eb356d;
        box-shadow: 0px 8px 16px -3px rgba(235, 53, 109, 0.27);
        background: radial-gradient(
          90.04% 90.04% at 41.6% -5.66%,
          #541a2c 0%,
          #060506 100%
        );
      `;
    }
    if (nftSymbol === 'deviants-factions') {
      return css`
        border: 2px solid #df9445;
        box-shadow: 0px 8px 16px -3px rgba(211, 93, 54, 0.76);
        background: #1f0f14;
      `;
    }
  }};
`;
const PreviousLotteryList = styled.ul`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  list-style-type: none;

  @media (max-width: 840px) {
    & > li .details,
    & > li .winner {
      justify-content: flex-start;
    }
  }

  @media (max-width: 1350px) and (min-width: 840px), (max-width: 600px) {
    margin-left: 0;

    & > li {
      &:not(:first-of-type) {
        margin-top: 12px;
      }
    }
  }
`;

const PoolNameContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
`;
const PoolLogo = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;

  user-select: none;
  -webkit-user-drag: none;
`;
const PoolName = styled.h3`
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.65);
`;

const NftName = styled.h4`
  margin-top: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
`;

const UntilLotteryStart = styled.span<NftSymbolProps>`
  margin-top: 4px;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;

  ${({ nftSymbol }) => {
    if (nftSymbol === 'galactic-punks') {
      return css`
        text-shadow: 0px 2px 12px rgba(0, 238, 250, 0.4);

        /* fallback */
        color: #00eefa;

        /* text gradient */
        background: -webkit-linear-gradient(#1b6b80, #00eefa);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `;
    }
    if (nftSymbol === 'lunabulls') {
      return css`
        text-shadow: 0px 2px 12px rgba(235, 53, 109, 0.4);

        /* fallback */
        color: #eb356d;

        /* text gradient */
        background: linear-gradient(180deg, #5b2938 0%, #eb356d 78.65%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `;
    }
    if (nftSymbol === 'terrabots') {
      return css`
        color: #ffffff;
        text-shadow: 0px 2px 12px rgba(255, 255, 255, 0.4);
      `;
    }
    if (nftSymbol === 'deviants-factions') {
      return css`
        text-shadow: 0px 2px 12px rgba(211, 93, 54, 0.4);

        /* fallback */
        color: #df9445;

        /* text gradient */
        background: -webkit-linear-gradient(#df9445, #ff7245);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `;
    }
  }};
`;

const InformationRow = styled.div`
  margin-top: 16px;

  & > div {
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
  }
`;

type InformationBlockProps = {
  title: string;
  description: string;
  tooltip?: string;
};
const InformationBlock: React.FC<InformationBlockProps> = ({
  title,
  description,
  tooltip,
}) => {
  return (
    <InformationBlockContainer>
      <InformationBlockHeading>
        {title} {!!tooltip && <Tooltip>{tooltip}</Tooltip>}
      </InformationBlockHeading>
      <InformationBlockDescription>{description}</InformationBlockDescription>
    </InformationBlockContainer>
  );
};
const InformationBlockContainer = styled.div`
  margin-bottom: 16px;
`;
const InformationBlockHeading = styled.p`
  margin-bottom: 8px;
  min-height: 22px;

  font-size: 18px;
  line-height: 18px;
  color: white;

  @media all and (max-width: 640px) {
    margin-bottom: 6px;

    font-size: 16px;
    line-height: 16px;
  }
`;
const InformationBlockDescription = styled.p`
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);

  @media all and (max-width: 640px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

const LineDivider = styled.div`
  margin-bottom: 36px;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
`;
