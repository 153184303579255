import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PopupContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;

  width: 100%;
  min-width: 200px;
  min-height: 56px;

  margin-top: 10px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  text-align: left;
`;

export const popupAddress = css`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
`;

export const addressActionItem = css`
  margin: 12px 0;

  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: inherit;

  opacity: 0.5;
  transition: 0.2s opacity ease;

  &:hover {
    opacity: 1;
  }
`;

export const walletOption = css`
  ${addressActionItem}

  display: block;
  margin: 0;
  padding: 12px 0;
`;

export const walletIcon = css`
  vertical-align: middle;
  height: 16px;
  margin-right: 8px;
`;

export const walletInstallationDivider = css`
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background: rgba(255, 255, 255, 0.18);
`;

export const hideAtMobile = css`
  @media all and (max-width: 640px) {
    display: none;
  }
`;

export const subtitle = css`
  margin: 20px 0 8px 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  opacity: 0.75;
`;

export const currencyItem = css`
  margin: 0;
  padding: 10px 0;
  font-size: 16px;
  line-height: 16px;

  border-top: 1px solid rgba(255, 255, 255, 0.2);

  &:last-of-type {
    border-bottom: none;
  }
`;

export const sendButton = css`
  width: 100%;
  height: 36px;

  margin-top: 10px;
  background: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 6px;
`;
