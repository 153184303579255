const toFixed = (value: number, to = 0) => {
  const [intInString = '0', decimalInString] = `${value}`.split('.');

  let val = intInString;
  if (decimalInString) {
    const decimal = `.${decimalInString.slice(0, to)}`;
    val += decimal;
  }

  return parseFloat(val);
};

export default toFixed;
