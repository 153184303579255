import { useCallback, useEffect } from 'react';

const EventProperty = {
  execute: 'gateway.captcha.execute',
  onchange: 'gateway.captcha.onchange',
} as const;

type TokenType = string | null | undefined;
type CaptchaEvent = CustomEvent<{ token: TokenType }>;

declare global {
  interface WindowEventMap {
    'gateway.captcha.execute': CaptchaEvent;
    'gateway.captcha.onchange': CaptchaEvent;
  }
}

const useCaptcha = (
  listenerType?: keyof typeof EventProperty,
  listener = (_e: CaptchaEvent) => {},
) => {
  const trigger = useCallback(() => {
    window.dispatchEvent(new CustomEvent(EventProperty.execute));
  }, []);

  const onChange = useCallback((token?: string | null) => {
    window.dispatchEvent(
      new CustomEvent(EventProperty.onchange, {
        detail: { token },
      }),
    );
  }, []);

  useEffect(() => {
    if (!listenerType) {
      return () => {};
    }

    window.addEventListener(EventProperty[listenerType], listener);

    return () => {
      window.removeEventListener(EventProperty[listenerType], listener);
    };
  }, [listenerType, listener]);

  return {
    trigger,
    onChange,
  };
};

export default useCaptcha;
