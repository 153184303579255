import get from 'lodash.get';
import React, { useEffect } from 'react';
import { decimalToNumber, getShortenAddress, Terrascope, toFixed } from 'utils';

import { Fee } from '@terra-money/terra.js';
import { ConnectedWallet, TxResult, useConnectedWallet } from '@terra-money/wallet-provider';

import { Backdrop, Spinner } from '../';
// import BroadcastingImage from './ani_broadcasting.png';
import * as styles from './TxBroadcastingModal.style';

interface Props {
  isTransacting?: boolean;
  type: 'success' | 'failure';
  details?: Omit<TxResult, 'msgs'>;
  onClose: () => void;
}

const ReceiptList = [
  {
    key: 'result.txhash',
    name: 'Tx Hash',
    format: (data: string, intent: Props['type'], wallet: ConnectedWallet) => (
      <styles.Link
        intent={intent}
        to={Terrascope.getTransactionLink(wallet?.network.chainID, data)}
      >
        {getShortenAddress(data, 10)}
      </styles.Link>
    ),
  },
  {
    key: 'fee',
    name: 'Fee',
    format: (data: Fee) => {
      const gasInString = data.amount.get('uusd')?.amount.toString();
      const gas = toFixed(decimalToNumber(gasInString ?? '0'), 3);

      return `${gas} UST`;
    },
  },
];

const TxBroadcastingModal = ({
  isTransacting = false,
  type,
  details,
  onClose,
}: Props) => {
  const wallet = useConnectedWallet()!;

  useEffect(() => {
    if (type === 'success') {
      onClose();
    }
  }, [type, onClose]);

  if (isTransacting) {
    return (
      <>
        <Spinner css={styles.spinner} />
        <Backdrop />
      </>
    );
  }

  if (type === 'success') {
    return null;
  }

  return (
    <>
      <styles.Container>
        <styles.Modal>
          <styles.Header>
            <p css={styles.title}>Transaction Failed</p>
            <p css={styles.description}>
              There was an error while processing the transaction.
            </p>
          </styles.Header>
          {/* {type === 'success' && (
            <styles.Image
              alt='Sending Transactions'
              src={BroadcastingImage}
            />
          )} */}
          <styles.Receipt>
            {ReceiptList.map(({ key, name, format }) => {
              const data = get(details ?? {}, key);
              if (!data) {
                return null;
              }

              return (
                <li key={key}>
                  <div css={styles.listHeader}>{name}</div>
                  <div>{format(data, type, wallet)}</div>
                </li>
              );
            })}
          </styles.Receipt>
          {type === 'failure' && details?.result.raw_log && (
            <styles.ErrorLog>{details?.result.raw_log}</styles.ErrorLog>
          )}
          <styles.CloseButton type="button" intent={type} onClick={onClose}>
            Close
          </styles.CloseButton>
        </styles.Modal>
      </styles.Container>
      <Backdrop />
    </>
  );
};

export default TxBroadcastingModal;
