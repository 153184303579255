import { ReactComponent as FlaskIcon } from 'assets/dashboard/ic-flask.svg';
import { ReactComponent as SwapIcon } from 'assets/dashboard/ic-swap.svg';
import dayjs from 'dayjs';
import { usePoolInfo } from 'queries';
import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PylonGatewayPool } from '@pylon-protocol/sdk';

import { ProjectStats } from '../queries/getProjectStats';

type StatsProps = {
  stats?: ProjectStats;
};
export const ConfigCard: React.FC<StatsProps> = ({ stats }) => {
  if (!stats) {
    return null;
  }

  return (
    <Card className="config-card">
      <Title>Config</Title>
      <Divider />
      {stats?.project.yieldToken.symbol === 'aUST' && (
        <Row>
          <Field>Yield From</Field>
          <Value>Anchor Protocol</Value>
        </Row>
      )}
      <Row>
        <Field>Deposit Token</Field>
        <Value>UST</Value>
      </Row>
      <Row>
        <Field>Yield Token</Field>
        <Value>UST</Value>
      </Row>
    </Card>
  );
};

export const ProjectYieldCard: React.FC<StatsProps> = ({ stats }) => {
  if (!stats) {
    return null;
  }
  return (
    <Card className="project-yield-card">
      <Title>Project Yield</Title>
      <Divider />
      <Row>
        <Field>aUST Balance</Field>
        <Value>{`${stats?.project.yieldTokenBalance.toLocaleString()} aUST`}</Value>
      </Row>
      <Row>
        <Field>aUST Exchange Rate</Field>
        <Value>{`$${stats?.project.yieldToken.exchangeRateFromUst.toLocaleString()} UST`}</Value>
      </Row>
      <Row>
        <Field>DP Supply</Field>
        <Value>{`${stats?.project.dpTokenSupply.toLocaleString()} DP`}</Value>
      </Row>
      <Row>
        <Field>Yield Generated</Field>
        <Value>{`$${stats?.project.claimableYieldBalance.toLocaleString()} UST`}</Value>
      </Row>
    </Card>
  );
};

type UserYieldCardProps = {
  type?: 'nft' | 'token' | 'dao';
  children: React.ReactNode;
  symbol: string;
  formattedTotalYield: string;
  formattedTotalValueFromUST: string;
  formattedTotalValueExchanged: string;
};
export const UserYieldCard: React.FC<UserYieldCardProps> = ({
  type,
  children,
  symbol,
  formattedTotalYield,
  formattedTotalValueFromUST,
  formattedTotalValueExchanged,
}) => {
  return (
    <Card className="user-yield-card">
      <Title>User Yield</Title>
      <Divider />
      {type === 'nft' ? (
        <Description>This project is launched by NFT Lottery Pools</Description>
      ) : (
        <React.Fragment>
          {children}
          <Row
            css={css`
              margin-top: 80px;
            `}
          >
            <Field>Total</Field>
            <Value>{`${formattedTotalYield} ${symbol}`}</Value>
          </Row>
          {/* <Row>
            <Field>Total (UST)</Field>
            <Value>{`$${formattedTotalValueFromUST} UST`}</Value>
          </Row> */}
          {/* <Row>
            <Field>Value Exchanged</Field>
            <Value>{`$${formattedTotalValueExchanged} UST`}</Value>
          </Row> */}
        </React.Fragment>
      )}
    </Card>
  );
};

type UserYieldPoolContentProps = {
  stats?: ProjectStats;
  pool: PylonGatewayPool | null;
  symbol: string;
  index: number;
};

export const UserYieldPoolContent: React.FC<UserYieldPoolContentProps> = ({
  pool,
  symbol,
  index,
}) => {
  const info = usePoolInfo({ pool, symbol });

  const isPoolClosed = useMemo(() => {
    if (!pool) {
      return false;
    }
    const endTime = pool.depositTime[0].end;
    if (!endTime) {
      return false;
    }
    return dayjs().isAfter(dayjs.utc(endTime));
  }, [pool]);

  return (
    <Wrapper>
      <PoolNameContainer>
        <PoolName>{`Pool ${index + 1}`}</PoolName>
        <FlaskIcon />
      </PoolNameContainer>
      <ContentRow>
        <ContentItem>
          <ContentField>Total Tokens Distributed</ContentField>
          <ContentValue>
            {`${info.data.distributedSupply.toLocaleString()} ${symbol}`}
          </ContentValue>
        </ContentItem>
        <ContentItem>
          {isPoolClosed ? (
            <React.Fragment>
              <ContentValue style={{ marginTop: 'auto' }}>
                Pool Closed
              </ContentValue>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ContentField
                css={css`
                  text-align: right;
                `}
              >
                APY
              </ContentField>
              <ContentValue
                css={css`
                  text-align: right;
                `}
              >
                {`${info.data.apy.toLocaleString()}%`}
              </ContentValue>
            </React.Fragment>
          )}
        </ContentItem>
      </ContentRow>
      <BottomDivider />
    </Wrapper>
  );
};

type UserYieldSwapContentProps = {
  swap: {
    totalTokensDistributed: number;
    roi: number;
  };
  symbol: string;
};
export const UserYieldSwapContent: React.FC<UserYieldSwapContentProps> = ({
  swap,
  symbol,
}) => {
  return (
    <Wrapper>
      <PoolNameContainer>
        <PoolName>Swap</PoolName>
        <SwapIcon />
      </PoolNameContainer>
      <ContentRow>
        <ContentItem>
          <ContentField>Total Tokens Distributed</ContentField>
          <ContentValue>
            {`${swap.totalTokensDistributed.toLocaleString()} ${symbol}`}
          </ContentValue>
        </ContentItem>
      </ContentRow>
      <BottomDivider />
    </Wrapper>
  );
};

const Card = styled.div`
  padding: 22px 20px 24px;
  flex: 1;

  display: flex;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.22);
  border: 1px solid rgba(0, 238, 250, 0.18);
  border-radius: 8px;
  backdrop-filter: blur(38px);
`;

const Title = styled.h3`
  font-weight: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: rgba(0, 238, 250, 0.75);
`;

const Divider = styled.div`
  margin: 12px 0;
  width: 100%;
  height: 1px;
  background: rgba(0, 238, 250, 0.18);
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;
const Field = styled.span`
  font-size: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
`;
const Value = styled.span`
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  text-align: right;
`;

const Description = styled(Field)`
  width: 200px;
  margin: 0 auto;
  text-align: center;
  line-height: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PoolNameContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;
const PoolName = styled.span`
  margin-right: 8px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 238, 250, 1);
`;
const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentField = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
`;
const ContentValue = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(255, 255, 255, 1);
`;
const BottomDivider = styled(Divider)`
  background: rgba(255, 255, 255, 0.2);
`;
