import { css } from '@emotion/react';
import styled from '@emotion/styled';


export const Container = styled.nav<{
  hasScrolled: boolean;
}>(({ hasScrolled }) => `
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  padding: 32px;

  transition: .25s all ease;
  z-index: 10;

  ${hasScrolled ? `
    background: #171717;
    padding: 16px 32px;

    border-bottom: 1px solid black;
  ` : ''}

  @media all and (max-width: 1024px) {
    padding: 24px 24px;

    ${hasScrolled ? `
      padding: 14px 24px;
    ` : ''}
  }
`)

export const container = css`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  padding: 32px;

  z-index: 10;
`;

export const wrapper = css`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  font-size: 0;

  > div {
    flex-grow: 1;
    flex-shrink: 0;

    &:last-of-type {
      text-align: right;
    }
  }
`;

export const logo = css`
  width: auto;
  height: 40px;

  @media all and (max-width: 1024px) {
    height: 36px;
  }

  @media all and (max-width: 640px) {
    height: 32px;
  }
`;


export const WalletButton = styled.button<{
  connected?: boolean
}>(({ connected }) => `
  display: inline-flex;
  align-items: center;

  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  font-size: 16px;
  line-height: 16px;
  color: white;

  transition: 0.2s all ease;

  ${connected ? `
    background: #333333;
  ` : ''}

  @media all and (max-width: 640px) {
    display: none;
  }
`);

export const walletIcon = css`
  margin-right: 8px;
`;

export const walletAddress = css`
  margin-right: 10px;
`;


export const menu = css`
  display: flex;
  justify-content: flex-end;
`;

export const txObserver = css`
  margin-right: 8px;
`;
