import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ProjectDefinition } from '@pylon-protocol/sdk/lib/pylon-api';

type ProjectSelectorProps = {
  selected: string;
  setSelected: (value: string) => void;
  projects: ProjectDefinition[];
};
export const MobileProjectSelector: React.FC<ProjectSelectorProps> = ({
  selected,
  setSelected,
  projects,
}) => {
  return (
    <Wrapper>
      <SelectionList>
        {projects.map((project) => {
          const symbol = project.type === 'nft' ? project.name : project.symbol;
          const isSelected = selected === project.symbol;

          return (
            <ProjectContainer
              key={project.symbol}
              selected={isSelected}
              onClick={() => setSelected(project.symbol)}
            >
              <ProjectSymbol>{symbol}</ProjectSymbol>
            </ProjectContainer>
          );
        })}
      </SelectionList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
  }
`;

const SelectionList = styled.div`
  margin-top: 16px;
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &,
  * {
    transition: all 0.2s ease-in-out;
    user-select: none;
  }
`;

type ProjectContainerProps = {
  selected: boolean;
};
const ProjectContainer = styled.div<ProjectContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  ${({ selected }) =>
    selected &&
    css`
      && > span {
        color: #04edfa;
      }

      border-color: #04edfa;
      background-color: #0d4347;
    `};
`;

const ProjectSymbol = styled.span`
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
`;
