import { css } from '@emotion/react';

export const container = css`
  @media all and (max-width: 640px) {
    margin-bottom: 64px;
  }
`;

export const loadingIndicator = css`
  margin-bottom: 56px;
  width: 100%;
  height: 256px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const subgrid = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 64px;
  grid-row-gap: 84px;
  margin-bottom: 144px;

  > a {
    min-width: 0;
    min-height: 0;
  }

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

export const upcomingList = css`
  display: flex;
  overflow: auto;

  & > article {
    margin-right: 48px;
    min-width: 320px;
  }
`;

export const title = css`
  margin-bottom: 32px;

  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    margin-bottom: 16px;
    margin-left: 12px;

    font-size: 22px;
    line-height: 22px;
  }
`;

export const featuredBanner = css`
  position: relative;

  margin-bottom: 32px;

  border-radius: 32px;
  overflow: hidden;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0.6;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255) 25.52%,
      rgba(255, 255, 255, 0) 92.71%
    );
  }

  @media all and (max-width: 640px) {
    margin-bottom: 60px;
    border-radius: 24px;
  }
`;

export const featuredBannerPrimary = css`
  margin-bottom: 64px;
`;

export const upcomingFeaturedBanner = css`
  position: relative;

  border-radius: 32px;
  overflow: hidden;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0.6;
    background: linear-gradient(
      180deg,
      #ffffff 34.38%,
      rgba(255, 255, 255, 0) 92.71%
    );
  }
`;

export const contentArea = css`
  position: relative;

  padding: 48px 48px 0;

  z-index: 1;

  @media all and (max-width: 1024px) {
    padding: 40px 40px 0;
  }

  @media all and (max-width: 640px) {
    padding: 24px 28px 200px;
    text-align: center;
  }
`;

export const upcomingContentArea = css`
  position: relative;

  padding: 24px 24px 32px;

  z-index: 1;
`;

export const badgeContainer = css`
  margin-bottom: 44px;
`;

export const upcomingBadgeContainer = css`
  background: rgba(255, 255, 255, 0.33);
  color: rgba(0, 0, 0, 0.66);

  &:before {
    display: none;
  }

  margin-bottom: 32px;

  @media all and (max-width: 640px) {
    margin-bottom: 24px;
  }
`;

export const featuredSubheading = css`
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #2a537d;

  @media all and (max-width: 1024px) {
    font-size: 16px;
    line-height: 16px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 4px;

    font-size: 14px;
    line-height: 14px;
  }
`;

export const featuredHeading = css`
  margin-bottom: 18px;

  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  color: #113764;

  @media all and (max-width: 1024px) {
    font-size: 56px;
    line-height: 56px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 8px;

    font-size: 36px;
    line-height: 36px;
  }
`;

export const upcomingFeaturedSubheading = css`
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #29567d;

  @media all and (max-width: 1024px) {
    font-size: 16px;
    line-height: 16px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 4px;

    font-size: 14px;
    line-height: 14px;
  }
`;

export const upcomingFeaturedHeading = css`
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  color: #133964;

  @media all and (max-width: 1024px) {
    font-size: 36px;
    line-height: 36px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 8px;

    font-size: 28px;
    line-height: 28px;
  }
`;

export const featuredDescription = css`
  margin-right: 120px;
  max-width: 480px;

  margin-bottom: 32px;

  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);

  @media all and (max-width: 1024px) {
    margin-right: 200px;
  }

  @media all and (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 18px;
    max-width: none;

    font-size: 13px;
    line-height: 20px;
  }
`;

export const secondaryDescription = css`
  margin-right: 0;
`;

export const upcomingFeaturedDescription = css`
  margin: 0 0 16px;
  max-width: 100%;
  max-width: 272px;

  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);

  // @media all and (max-width: 1024px) {
  //   margin-right: 200px;
  // }

  @media all and (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 18px;
    max-width: none;

    font-size: 13px;
    line-height: 20px;
  }
`;

export const featuredParticipate = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 200px;
  padding: 0 20px;
  height: 42px;
  margin-bottom: 64px;

  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);

  background: #00eefa;
  border-radius: 6px;

  box-shadow: 0px 8px 40px #00eefa, 0px 32px 120px rgba(0, 0, 0, 0.05);

  span {
    margin-right: 8px;
  }

  svg {
    width: auto;
    height: 16px;

    path {
      stroke: rgba(0, 0, 0, 0.75);
    }
  }

  @media all and (max-width: 640px) {
    width: 150px;
    height: 34px;

    font-size: 14px;
    box-shadow: 0px 4px 20px #00eefa, 0px 16px 60px rgba(0, 0, 0, 0.05);

    span {
      margin-right: 6px;
    }

    svg {
      height: 12px;
    }
  }
`;

export const tierContainer = css`
  display: block;

  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;

  li {
    display: inline-block;
    margin-right: 32px;
  }

  @media all and (min-width: 1024px) {
    font-size: 14px;
  }

  @media all and (max-width: 1024px) {
    font-size: 16px;
  }

  @media all and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -24px 0 40px 0;

    * {
      opacity: 1 !important;
      color: rgba(255, 255, 255, 1);
    }

    li {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 1;
      margin: 0 0 8px 0;
    }
  }
`;

export const tiersVesting = css`
  @media all and (max-width: 640px) {
    display: none;
  }
`;

export const poolTitle = css`
  margin-bottom: 8px;

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);

  @media all and (max-width: 640px) {
    margin: 2px 16px 0 0;
  }
`;

export const tokenIllust = css`
  position: absolute;
  top: -20px;
  right: -40px;
  height: 570px;
  z-index: -1;

  @media all and (max-width: 1024px) {
    right: -100px;
    height: 480px;
  }

  @media all and (max-width: 640px) {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    height: 280px;
    transform: translateX(-50%);
  }
`;

export const secondaryTokenIllust = css`
  position: absolute;
  top: 0;
  right: -30px;
  height: 270px;
  z-index: -1;

  @media all and (max-width: 1024px) {
    right: -100px;
    height: 480px;
  }

  @media all and (max-width: 640px) {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    height: 280px;
    transform: translateX(-50%);
  }
`;

export const upcomingIllustNeb = css`
  position: absolute;
  top: 8px;
  right: -10px;
  width: 186px;
  height: 186px;
  object-fit: contain;
  z-index: -1;

  @media all and (max-width: 640px) {
    top: -18px;
  }
`;

export const upcomingIllustTwd = css`
  position: absolute;
  top: 24px;
  right: 48px;
  width: 114px;
  opacity: 0.55;

  @media all and (max-width: 1024px) {
    right: 16px;
    width: 108px;
  }

  @media all and (max-width: 640px) {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    width: 128px;
    transform: translateX(-50%);
  }
`;

export const upcomingIllustLoop = css`
  position: absolute;
  top: 42px;
  right: 48px;
  width: 128px;

  @media all and (max-width: 1024px) {
    right: 16px;
    width: 96px;
  }

  @media all and (max-width: 640px) {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    width: 144px;
    transform: translateX(-50%);
  }
`;
