import styled from '@emotion/styled';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  max-width: 360px;
  font-size: 18px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
`;

const NotFoundPage = () => (
  <Container>
    <div>
      <Title>
        Page Not found
      </Title>
      <Paragraph>
        The page you're looking for could not be found. <br />
        It might have been removed, renamed, or did not exist in the first place.
      </Paragraph>
    </div>
  </Container>
);

export default NotFoundPage;
