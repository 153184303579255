import { useQuery } from 'react-query';

import { PylonGatewayPool, PylonGatewayProject, PylonSwap } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

export type PoolDepositLog = {
  type: 'pool';
  balanceInUst: number;
  rewardTokens: number;
  pool: PylonGatewayPool;
  poolName: string;
  bdpTokenContract?: string;
};

export type SwapDepositLog = {
  type: 'swap';
  balanceInUst: number;
  rewardTokens: number;
  remainingTokens: number;
  swap: PylonSwap;
};

const getRewardsOfPool = async (
  address: string,
  pools: PylonGatewayPool[],
): Promise<PoolDepositLog[]> => {
  let rewards: PoolDepositLog[] = [];

  await Promise.all(
    pools.map(async (pool, index) => {
      if (!!pool.derivativeToken) {
        console.log(pool.derivativeToken);
        const status = await pool.statusOf(address).catch(() => undefined);
        console.log({ status });
        rewards.push({
          type: 'pool' as const,
          balanceInUst: status?.stakedUst ?? 0,
          rewardTokens: status?.rewardTokens ?? 0,
          pool,
          poolName: `Liquid Pool ${index + 1}`,
          bdpTokenContract: pool.derivativeToken.address,
        });
        return;
      }
      const status = await pool.statusOf(address).catch(() => undefined);
      rewards.push({
        type: 'pool' as const,
        balanceInUst: status?.stakedUst ?? 0,
        rewardTokens: status?.rewardTokens ?? 0,
        pool,
        poolName: `Pool ${index + 1}`,
      });
    }),
  );

  return rewards.filter(
    (item) => item.balanceInUst > 0 || item.rewardTokens > 0,
  );
};

const getRewardsOfSwaps = async (
  address: string,
  swaps: PylonSwap[],
): Promise<SwapDepositLog[]> => {
  let rewards = [];

  for (const swap of swaps) {
    const status = await swap.statusOf(address);
    if (!status) {
      continue;
    }
    rewards.push({
      type: 'swap' as const,
      balanceInUst: status.swappedUst,
      rewardTokens: status.claimableRewards,
      remainingTokens: status.totalRewards - status.claimableRewards,
      swap,
    });
  }

  return rewards.filter(
    (item) =>
      item.balanceInUst > 0 &&
      (item.rewardTokens > 0 || item.remainingTokens > 0),
  );
};

const useDepositLog = (project?: PylonGatewayProject | null) => {
  const wallet = useConnectedWallet();

  const address = wallet?.terraAddress;
  const queryKey = [
    'depositLog',
    project?.projectDefinition?.poolContract ?? '-',
    address ?? '-',
  ];

  return useQuery(
    queryKey,
    async () => {
      if (!address || !project) {
        return undefined;
      }
      try {
        const pools = Object.values(project.pools);
        const rewardsByPool = await getRewardsOfPool(address, pools).catch(
          (error) => {
            console.log(error);
            return [];
          },
        );
        const rewardsBySwap = await getRewardsOfSwaps(
          address,
          project.swaps,
        ).catch((error) => {
          console.log(error);
          return [];
        });

        return {
          pool: rewardsByPool,
          swap: rewardsBySwap,
        };
      } catch (err) {
        console.error(err);
        return undefined;
      }
    },
    { suspense: true },
  );
};

export default useDepositLog;
