import { Captcha, DisclaimerBlock, InfoBlock, Spinner } from 'components';
import {
  TokenPageCurrentDeposit, TokenPageEarn, TokenPageHeader, TokenPageInfo, TokenPageTrade
} from 'containers/token-page';
import NotFoundPage from 'pages/not-found-page';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import * as RecoilState from 'recoils/ProjectRecoil';

import { css, Global } from '@emotion/react';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import * as styles from './TokenPage.style';
import { ValkyrieReferralNoticeBanner } from './ValkyrieReferralNoticeBanner';

type Params = {
  symbol: string;
};

const TokenPage = () => {
  const wallet = useConnectedWallet();

  const { symbol: baseSymbol } = useParams<Params>();

  const [symbol, setSymbol] = useRecoilState(RecoilState.symbolAtom);
  const forceUpdate = useSetRecoilState(RecoilState.forceUpdateAtom);
  const projectLoadable = useRecoilValueLoadable(
    RecoilState.projectSelector(baseSymbol),
  );

  const isValkyrieReferralPool = useMemo(() => {
    return symbol.toLowerCase() === 'mine';
  }, [symbol]);

  useEffect(() => {
    setSymbol(baseSymbol);

    return () => {
      forceUpdate({});
    };
  }, [baseSymbol]); // eslint-disable-line

  useEffect(() => {
    if (
      projectLoadable.state === 'hasValue' &&
      projectLoadable.contents?.projectDefinition.symbol
    ) {
      const nextSymbol = projectLoadable.contents.projectDefinition.symbol;
      setSymbol(nextSymbol);
    }
  }, []); // eslint-disable-line

  if (!symbol || projectLoadable.state === 'loading') {
    return <Spinner css={styles.loadingSpinner} size={30} />;
  }

  if (
    projectLoadable.state === 'hasError' ||
    (projectLoadable.state === 'hasValue' && !projectLoadable.contents)
  ) {
    return <NotFoundPage />;
  }

  const swaps = projectLoadable.contents?.swaps ?? [];

  return (
    <>
      {isValkyrieReferralPool && <ValkyrieReferralNoticeBanner />}
      <div css={styles.container}>
        <React.Suspense
          fallback={<Spinner css={styles.loadingSpinner} size={30} />}
        >
          {/* token information section */}
          <section css={styles.introductionSection}>
            <TokenPageHeader swap={swaps[0]} />
            <TokenPageInfo />
          </section>

          {/* Swap Section */}
          <section css={styles.tradeSection}>
            {symbol.toLowerCase() === 'gfi' ? (
              <article css={styles.sideContainer}>
                <h2 css={styles.sideHeading}>{`Burn $MINE to Deposit $UST`}</h2>
                <React.Suspense fallback={<></>}>
                  <TokenPageEarn />
                  <DisclaimerBlock
                    css={[
                      styles.disclaimer,
                      css`
                        margin-top: 16px;
                      `,
                    ]}
                    disclaimerField="Please note"
                  >
                    When you deposit UST to Gateway Fund I, you will receive the
                    $GFI token, which represents ownership over the
                    corresponding locked UST in Gateway Fund I on top of the
                    retrospectively vested project token rewards. By trading the
                    $GFI token, you are also trading the right to claim the
                    initial UST deposit at the maturity of the pool as well as a
                    portion of the linearly vested project token rewards in the
                    future.
                  </DisclaimerBlock>
                  <DisclaimerBlock
                    css={[
                      styles.disclaimer,
                      css`
                        margin-top: 8px;
                      `,
                    ]}
                    disclaimerPrefix={'APR'}
                  >
                    The APRs displayed here are estimates supplied by the
                    participating projects based on the discounted token price
                    being offered. Pylon Gateway is not responsible for
                    providing accurate project valuations and APR may fluctuate
                    upon token listing on market exchanges. All decisions to
                    deposit into Pylon Pools is at the discretion of the
                    user-investor.
                  </DisclaimerBlock>
                </React.Suspense>
              </article>
            ) : symbol.toLowerCase() === 'psi' ? (
              <article css={styles.sideContainer}>
                <h2 css={styles.sideHeading}>
                  {`Earn $${symbol} via Liquid Pylon Pool`}
                </h2>
                <React.Suspense fallback={<></>}>
                  <TokenPageEarn />
                  <DisclaimerBlock
                    css={[
                      styles.disclaimer,
                      css`
                        margin-top: 16px;
                      `,
                    ]}
                    disclaimerField="Please note"
                  >
                    When you deposit UST to Liquid Pylon Pool for $Psi, you will
                    receive the bPsiDP-24m token, which represents ownership
                    over the deposited UST in the Liquid Pylon Pool for $Psi
                    with the accruing $Psi token rewards. By trading the
                    bPsiDP-24m token, you are also trading the right to claim
                    both the $Psi token rewards and the deposited UST into the
                    Liquid Pylon Pool for $Psi at the maturity of the pool.
                  </DisclaimerBlock>
                  <DisclaimerBlock
                    css={[
                      styles.disclaimer,
                      css`
                        margin-top: 8px;
                      `,
                    ]}
                    disclaimerPrefix={'APR'}
                  >
                    The APRs displayed here are estimates supplied by the
                    participating projects based on the discounted token price
                    being offered. Pylon Gateway is not responsible for
                    providing accurate project valuations and APR may fluctuate
                    upon token listing on market exchanges. All decisions to
                    deposit into Pylon Pools is at the discretion of the
                    user-investor.
                  </DisclaimerBlock>
                </React.Suspense>
              </article>
            ) : (
              <React.Fragment>
                <article css={styles.sideContainer}>
                  <h2 css={styles.sideHeading}>{`Earn $${symbol}`}</h2>
                  <React.Suspense fallback={<></>}>
                    <TokenPageEarn />
                    <DisclaimerBlock
                      css={[
                        styles.disclaimer,
                        css`
                          margin-top: 16px;
                        `,
                      ]}
                      disclaimerPrefix={'APR'}
                    >
                      The APRs displayed here are estimates supplied by the
                      participating projects based on the discounted token price
                      being offered. Pylon Gateway is not responsible for
                      providing accurate project valuations and APR may
                      fluctuate upon token listing on market exchanges. All
                      decisions to deposit into Pylon Pools is at the discretion
                      of the user-investor.
                    </DisclaimerBlock>
                  </React.Suspense>
                </article>
                {!!swaps.length && (
                  <>
                    <article css={styles.sideContainer}>
                      <h2 css={styles.sideHeading}>
                        {`Trade $${symbol} via Pylon Swap`}
                      </h2>
                      <React.Suspense fallback={<></>}>
                        {swaps.map((swap) => (
                          <TokenPageTrade key={swap.address} swap={swap} />
                        ))}
                      </React.Suspense>
                    </article>
                    <Captcha />
                  </>
                )}
                {symbol.toLowerCase() === 'sayve' && (
                  <article css={styles.sideContainer}>
                    <h2 css={styles.sideHeading}>Sayve Swap</h2>

                    <InfoBlock>
                      Sayve Swap has ended. Only visible for participants at{' '}
                      <a
                        href="https://prefund.sayve.money"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'rgba(255,255,255,0.85)' }}
                      >
                        prefund.sayve.money
                      </a>
                    </InfoBlock>
                  </article>
                )}
              </React.Fragment>
            )}

            {wallet?.terraAddress && (
              <article css={styles.sideContainer}>
                <h2 css={styles.sideHeading}>Current Deposits</h2>
                <DisclaimerBlock css={styles.disclaimer} symbol={symbol} />
                <React.Suspense fallback={<Spinner size={12} />}>
                  <TokenPageCurrentDeposit />
                </React.Suspense>
              </article>
            )}
          </section>
        </React.Suspense>
      </div>
      <Global
        styles={[
          styles.globalStyle,
          styles.pageBackground[symbol.toLowerCase()],
        ].filter(Boolean)}
      />
    </>
  );
};

export default TokenPage;
