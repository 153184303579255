export const VALKYRIE_ADDRESSES = {
  campaign: {
    'columbus-5': 'terra13swrxgswau5mxvkl0jvqf9lcles2z39g5zqlgy',
    'bombay-12': 'terra1acqr5ydq35zyq4n088nls5g58xlsq8yfnvg4ls',
  },
  qualifier: {
    'columbus-5': 'terra1f9qmvzl98xvhn444jd5cqawg7pfjt3hu6v259v',
    'bombay-12': 'terra1ss2ujzzgdlsczqkhyugg6cdk5fj02k285rysl2',
  },
  vkr: {
    'columbus-5': 'terra1dy9kmlm4anr92e42mrkjwzyvfqwz66un00rwr5',
    'bombay-12': 'terra1a8hskrwnccq0v7gq3n24nraaqt7yevzy005uf5',
  },
  gateway: {
    'columbus-5': 'terra19vnwdqz4um0z8f69pc8y0z4ncrcxm4cjf3gevz',
    'bombay-12': 'terra1d0xafepxdepndknrave6xqjdsjc6zqfzw9n6wh',
  }
}
