import { Tooltip } from 'components';
import { usePoolInfo } from 'queries';
import React from 'react';
import { ceil, toFixed } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';

import * as styles from './TokenPageLiquidPoolOption.style';

export const TokenPagePoolAPY = ({
  pool,
  symbol,
  tokenPrice,
}: {
  pool: PylonGatewayPool | null;
  symbol: string;
  tokenPrice: number;
}) => {
  const { data } = usePoolInfo({ pool, symbol });

  const lowerCaseSymbol = symbol.toLowerCase();
  const isMINE = lowerCaseSymbol === 'mine';

  // FIXME: divide post/pre token launch
  const hasLaunchedToken = [
    'psi',
    'vkr',
    'loop',
    'twd',
    'whale',
    'orion',
  ].includes(lowerCaseSymbol);

  return (
    <>
      <span css={[!isMINE && styles.apyText]}>
        {`${data?.apy.toLocaleString() ?? '-'}% APR`}
      </span>
      {!isMINE && (
        <Tooltip>
          {hasLaunchedToken
            ? `The current APR is calculated based on the circulating market price estimate of 1 ${symbol} = ${toFixed(
                tokenPrice,
                3,
              )} UST`
            : `The current APR is calculated based on the estimate of 1 ${symbol} = ${toFixed(
                tokenPrice,
                3,
              )} UST`}
        </Tooltip>
      )}
    </>
  );
};

export type TokenPagePoolDetailProps = {
  totalDepositsInUst?: number;
  distributedSupply?: number;
  totalSupply?: number;
};

// view structure for pool detail page
export const TokenPagePoolDetail = ({
  totalDepositsInUst,
  distributedSupply,
  totalSupply,
}: TokenPagePoolDetailProps) => {
  return (
    <ul css={styles.detailList}>
      <li>
        <p css={styles.detailHeading}>Total Deposits</p>
        <p>{`${totalDepositsInUst?.toLocaleString() ?? '-'} UST`}</p>
      </li>
      <li css={styles.detailTokenDistributedColumn}>
        <p css={styles.detailHeading}>Tokens Distributed</p>
        <p>
          {`${
            typeof distributedSupply === 'number'
              ? Math.max(distributedSupply, 0).toLocaleString()
              : '-'
          } / ${
            typeof totalSupply === 'number'
              ? ceil(totalSupply, 8).toLocaleString()
              : '-'
          }`}
        </p>
      </li>
    </ul>
  );
};

// query pool detail info
export const TokenPageDetailQuery = ({
  pool,
  symbol,
}: {
  pool: PylonGatewayPool | null;
  symbol: string;
}) => {
  const { data } = usePoolInfo({ pool, symbol });
  const isPsi = symbol.toLowerCase() === 'psi';

  return (
    <TokenPagePoolDetail
      totalDepositsInUst={data?.totalDepositsInUst}
      distributedSupply={data?.distributedSupply}
      totalSupply={isPsi ? 200_000_000 : data?.totalSupply}
    />
  );
};
