import { getNftCollections } from 'api/project/getNftCollections';
import { Spinner } from 'components';
import {
  NftCollections, NftLotteryCard, NftLotteryPoolStake, NftPageHeader, NftPageInfo
} from 'containers/nft-page';
import NotFoundPage from 'pages/not-found-page';
import { useDepositLog } from 'queries';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { chainIdAtom } from 'recoils/ChainIdRecoil';
import * as ProjectRecoil from 'recoils/ProjectRecoil';

import { Global } from '@emotion/react';

import * as styles from './NftPage.style';

type Params = {
  symbol: string;
};

const NftPage = () => {
  const chainID = useRecoilValue(chainIdAtom);

  const { symbol: baseSymbol } = useParams<Params>();
  const [symbol, setSymbol] = useRecoilState(ProjectRecoil.symbolAtom);
  const forceUpdate = useSetRecoilState(ProjectRecoil.forceUpdateAtom);
  const projectLoadable = useRecoilValueLoadable(
    ProjectRecoil.projectSelector(baseSymbol),
  );

  const { data: collections } = useQuery(
    ['list', chainID, symbol],
    getNftCollections,
  );

  const { data: deposits } = useDepositLog(projectLoadable.contents);
  const poolDeposits = deposits?.pool;

  useEffect(() => {
    setSymbol(baseSymbol);

    return () => {
      forceUpdate({});
    };
  }, [baseSymbol]); // eslint-disable-line

  useEffect(() => {
    if (
      projectLoadable.state === 'hasValue' &&
      projectLoadable.contents?.projectDefinition.symbol
    ) {
      const nextSymbol = projectLoadable.contents.projectDefinition.symbol;
      setSymbol(nextSymbol);
    }
  }, [projectLoadable.state]); // eslint-disable-line

  const numberOfDistributedPunks = useMemo(() => {
    if (!collections) {
      return undefined;
    }
    if (symbol === 'deviants-factions') {
      return 200;
    }
    return collections.reduce((acc, item) => {
      if (!!item.owner) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [collections, symbol]);

  if (!symbol || projectLoadable.state === 'loading') {
    return <Spinner css={styles.loadingSpinner} size={30} />;
  }

  if (
    projectLoadable.state === 'hasError' ||
    (projectLoadable.state === 'hasValue' && !projectLoadable.contents)
  ) {
    return <NotFoundPage />;
  }

  const projectDetail = projectLoadable.contents;

  return (
    <>
      <div css={styles.container}>
        <div css={styles.topContainer}>
          <React.Suspense
            fallback={<Spinner css={styles.loadingSpinner} size={30} />}
          >
            {/* NFT information section */}
            <section css={styles.introductionSection}>
              <NftPageHeader
                numberOfDistributedPunks={numberOfDistributedPunks}
                numberOfTotalPunks={
                  symbol === 'deviants-factions'
                    ? 200
                    : collections
                    ? collections.length
                    : undefined
                }
              />
              <NftPageInfo />
            </section>

            {/* Earn Section */}
            <section css={styles.tradeSection}>
              <article css={styles.sideContainer}>
                <h2 css={styles.sideHeading}>Stake to Play</h2>
                {/* FIXME: refactor nft lottery pools */}
                {projectDetail?.projectDefinition.nftLotteryPools?.map(
                  (lotteryPool) => {
                    const chancePerTicket =
                      (1 / (lotteryPool.ticketInfo?.totalTicketsStaked || 1)) *
                      100;
                    const depositInformation = poolDeposits?.find(
                      (v) => v.pool.address === lotteryPool.contract,
                    );

                    const stakingTokenSymbol =
                      lotteryPool.ticketInfo?.stakingToken;
                    const hasPoolEnded =
                      projectDetail.projectDefinition.symbol ===
                        'deviants-factions' ||
                      (projectDetail.projectDefinition.symbol ===
                        'galactic-punks' &&
                        stakingTokenSymbol === 'mine');

                    return (
                      <NftLotteryCard
                        key={lotteryPool.name}
                        chainID={chainID}
                        symbol={lotteryPool.ticketInfo?.stakingToken}
                        name={lotteryPool.name}
                        nftSymbol={symbol}
                        nftName={projectDetail.projectDefinition.name}
                        drawAt={lotteryPool.upcomingDraw?.at}
                        chancePerTicket={chancePerTicket}
                        previousDraws={lotteryPool.previousDraw}
                        hasPoolEnded={hasPoolEnded}
                      >
                        <NftLotteryPoolStake
                          projectDetail={projectDetail}
                          pool={lotteryPool}
                          depositInformation={depositInformation}
                          hasPoolEnded={hasPoolEnded}
                        />
                      </NftLotteryCard>
                    );
                  },
                )}
              </article>
            </section>
          </React.Suspense>
        </div>

        {symbol !== 'deviants-factions' && (
          <section>
            <h2 css={styles.sideHeading}>Collections</h2>
            <NftCollections
              tokenName={
                symbol === 'galactic-punks'
                  ? 'Punks'
                  : symbol === 'lunabulls'
                  ? 'LunaBulls'
                  : 'Bots'
              }
              collections={collections}
            />
          </section>
        )}
      </div>
      <Global styles={[styles.pageBackground[symbol]].filter(Boolean)} />
    </>
  );
};

export default NftPage;
