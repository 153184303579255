import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { transactionHistoryAtom } from 'recoils';
import { getShortenAddress, Terrascope, useOutsideClick } from 'utils';

import * as styles from './TxHistory.style';

interface Props {
  onClose: () => void;
}

const TxHistory = ({ onClose }: Props) => {
  const container = useRef<HTMLDivElement>(null);

  const transactionHistory = useRecoilValue(transactionHistoryAtom);

  useOutsideClick(container, () => {
    onClose();
  });

  useEffect(() => {
    if (!transactionHistory.length) {
      onClose();
    }
  }, [transactionHistory, onClose]);

  return (
    <styles.Container ref={container}>
      <styles.Heading>Pending Transactions</styles.Heading>
      <styles.List>
        {transactionHistory.map((item) => (
          <li key={item.id}>
            <styles.Card
              to={Terrascope.getTransactionLink(item.chainID, item.hash)}
            >
              <styles.Action>{item.action}</styles.Action>
              <styles.Link>{getShortenAddress(item.hash, 10)}</styles.Link>
            </styles.Card>
          </li>
        ))}
      </styles.List>
    </styles.Container>
  );
};

export default TxHistory;
