import { css } from '@emotion/react';


export const container = css`
  position: relative;

  padding: 24px 32px;
  margin-bottom: 32px;

  background: rgba(0, 0, 0, 0.33);
  border-radius: 8px;

  @media all and (max-width: 1024px) {
    padding: 20px 18px;
  }

  @media all and (max-width: 640px) {
    padding: 16px 20px;
  }
`;

export const disclamer = css`
  margin-bottom: 16px;
`;

export const disclamerBlock = css`
  display: block;
  padding: 16px 32px;
  margin-bottom: 8px;

  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);

  background: rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  @media all and (max-width: 640px) {
    padding: 8px 12px;
    font-size: 13px;
    line-height: 18px;
  }
`;

export const swapDescription = css`
  position: absolute;
  top: 32px;
  right: 32px;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: right;

  pointer-events: none;
  user-select: none;

  @media all and (min-width: 1250px) {
    top: 30px;
    font-size: 16px;
    line-height: 16px;
  }

  @media all and (max-width: 1250px) {
    top: 28px;
    max-width: 120px;
  }

  @media all and (max-width: 840px) {
    max-width: none !important;
  }

  @media all and (max-width: 640px) {
    top: 22px;
    max-width: none !important;
  }
`;

export const waitingForSwapStart = css`
  opacity: 0.5;
`;

export const tab = css`
  margin-bottom: 24px;

  @media all and (max-width: 640px) {
    margin-bottom: 12px;
  }
`;

export const formContainer = css`
  margin-bottom: 2px;
`;

export const inputContainer = css`
  margin-bottom: 8px;

  @media all and (max-width: 1024px) {
    margin-bottom: 6px;
  }
`;

export const descriptionContainer = css`
  display: flex;

  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 1024px) {
    margin-bottom: 12px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const description = css`
  flex-grow: 1;
  margin-right: 20px;
`;
export const launchWaitDescription = css`
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.75);

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const exclusive = css`
  margin-left: auto;
  height: 30px;

  font-size: 20px;
  line-height: 20px;
  text-align: right;
  color: #ffffff;
`;

export const availableUST = css`
  flex-shrink: 0;
  align-self: flex-start;

  color: inherit;
  font-size: inherit;
  text-decoration: underline;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const errorMessage = css`
  color: #ff0f49;
`;



export const infoContainer = css`
  display: flex;

  margin-top: 12px;
  margin-bottom: 24px;

  font-size: 16px;
  line-height: 16px;

  > div {
    margin-right: 32px;
  }

  @media all and (max-width: 1024px) {
    margin-top: 8px;
    margin-bottom: 16px;

    > div {
      margin-right: 24px;
    }
  }

  @media all and (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const warningContainer = css`
  font-size: 16px;
  line-height: 24px;

  margin-top: 16px;
  margin-bottom: 24px;
  opacity: 0.75;

  @media all and (max-width: 640px) {
    font-size: 13px;
    line-height: 18px;
  }
`;


export const subheading = css`
  margin-bottom: 8px;

  color: rgba(255, 255, 255, 0.66);

  @media all and (max-width: 640px) {
    margin-bottom: 4px;
  }
`;

export const distributionPercent = css`
  margin-left: 6px;

  color: rgba(255, 255, 255, 0.5);
`;


export const submitButton = css`
  &:disabled {
    opacity: 0.5;
  }

  @media all and (max-width: 640px) {
    width: 100% !important;
  }
`;
