import { Spinner } from 'components';
import React from 'react';

import styled from '@emotion/styled';

import NftCollectionItem from './NftCollectionItem';

type NftCollectionsProps = {
  tokenName: string;
  collections: Nft[] | undefined;
};

const NftCollections: React.FC<NftCollectionsProps> = ({
  tokenName,
  collections,
}) => {
  if (!collections) {
    return (
      <LoadingContainer>
        <Spinner size={64} />
      </LoadingContainer>
    );
  }

  return (
    // TODO: implement trait filter
    <Container>
      {collections.map((token, index) => {
        return (
          <NftCollectionItem
            key={`${token.tokenId}-${index}`}
            tokenName={tokenName}
            {...token}
            serialNumber={
              token.attributes?.find((v) => v.trait_type === 'serial_number')
                ?.value
            }
            attributes={token.attributes?.filter(
              (v) => v.trait_type !== 'serial_number',
            )}
            soldout={!!token.owner}
          />
        );
      })}
    </Container>
  );
};

export default NftCollections;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 28px;

  @media all and (max-width: 1350px) {
    grid-column-gap: 20px;
    grid-row-gap: 24px;
  }

  @media all and (max-width: 1024px) {
    grid-column-gap: 16px;
    grid-row-gap: 18px;
  }

  @media all and (max-width: 880px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media all and (max-width: 590px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 14px;
    grid-row-gap: 16px;
  }
`;

const LoadingContainer = styled.div`
  height: 400px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
