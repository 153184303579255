const copy = (text: string) => {
  const textarea = document.createElement('textarea');
  Object.assign(textarea, { value: text });

  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, `${text}`.length);

  document.execCommand('copy');
  document.body.removeChild(textarea);

  return true;
};

export default copy;
