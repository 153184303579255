import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';


const mediaQuery = `
  height: 50px;

  font-size: 20px;
  line-height: 20px;

  @media all and (max-width: 1024px) {
    height: 46px;

    font-size: 18px;
    line-height: 18px;
  }

  @media all and (max-width: 640px) {
    height: 42px;

    font-size: 16px;
    line-height: 16px;
  }
`;


const Container = styled.div<{
  hasFocus: boolean;
}>`
  display: flex;

  width: 100%;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  overflow: hidden;
  transition: .25s all ease;

  ${props => props.hasFocus ? `
    background: white;
  ` : ''}

  ${mediaQuery}
`;

export const InputBase = styled.input`
  flex-grow: 1;
  padding: 0 20px;

  background: transparent;
  border: none;
  color: white;

  &::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }

  &:focus {
    color: rgba(0, 0, 0, 0.75);

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }
  }

 ${mediaQuery}

 ${props => props.disabled ? `
  cursor: not-allowed;
 ` : ''}
`;

export const Label = styled.div<{
  hasFocus: boolean;
}>`
  display: flex;
  align-items: center;

  padding-left: 25px;
  padding-right: 10px;

  color: rgba(255, 255, 255, 0.5);

  ${props => props.hasFocus ? `
    color: rgba(0, 0, 0, 0.33);
  ` : ''}

  & + ${InputBase} {
    padding-left: 10px;
  }

  @media all and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 8px;

    & + ${InputBase} {
      padding-left: 6px;
    }
  }

  @media all and (max-width: 640px) {
    padding-left: 16px;
    padding-right: 4px;

    & + ${InputBase} {
      padding-left: 4px;
    }
  }

  ${mediaQuery}
`;


interface Props extends React.ComponentProps<typeof InputBase> {
  label?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, Props>(({
  className,
  label,
  ...props
}, ref) => {
  const [hasFocus, setHasFocus] = useState(false);


  const handleFocusInput = useCallback((
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    setHasFocus(true);
    props.onFocus?.(e);
  }, [props.onFocus]); // eslint-disable-line

  const handleBlurInput = useCallback((
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    setHasFocus(false);
    props.onBlur?.(e);
  }, [props.onBlur]); // eslint-disable-line

  return (
    <Container
      className={className}
      hasFocus={hasFocus}
    >
      {label && (
        <Label hasFocus={hasFocus}>
          {label}
        </Label>
      )}
      <InputBase
        {...props}
        ref={ref}
        onBlur={handleBlurInput}
        onFocus={handleFocusInput}
      />
    </Container>
  );
});

export default Input;
