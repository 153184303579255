import { css } from '@emotion/react';


export const container = css`
  margin-bottom: 40px;
`;

export const section = css`
  margin-bottom: 32px;
`;


export const subInfo = css`
  display: flex;
  align-items: center;

  margin-bottom: 8px;

  @media all and (max-width: 640px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const badge = css`
  flex-shrink: 0;

  @media all and (max-width: 640px) {
    margin-bottom: 16px;
  }
`;

export const symbol = css`
  display: block;
  flex-grow: 1;

  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.9);

  @media all and (max-width: 640px) {
    margin-bottom: 6px;

    font-size: 18px;
    line-height: 18px;
  }
`;

export const title = css`
  margin-bottom: 12px;

  font-weight: 600;
  font-size: 64px;
  line-height: 64px;
  color: white;

  @media all and (max-width: 1024px) {
    margin-bottom: 14px;

    font-size: 50px;
    line-height: 50px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 16px;

    font-size: 36px;
    line-height: 36px;
  }
`;

export const description = css`
  font-size: 20px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.9);

  @media all and (max-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }
`;


export const depositContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-column-gap: 24px;
`;

export const subheading = css`
  margin-bottom: 8px;

  font-size: 18px;
  line-height: 18px;
  color: white;

  @media all and (max-width: 640px) {
    margin-bottom: 6px;

    font-size: 16px;
    line-height: 16px;
  }
`;

export const subdescription = css`
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);

  @media all and (max-width: 640px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const progressbarDescription = css`
  display: flex;
  align-items: center;

  margin-bottom: 12px;

  > p {
    flex-grow: 1;
    flex-shrink: 0;

    &:last-of-type {
      text-align: right;
    }
  }

  @media all and (max-width: 640px) {
    margin-bottom: 16px;
  }
`;

export const progressbarContainer = css`
  width: 100%;
  height: 2px;

  background: rgba(255, 255, 255, 0.25);

  transition: 0.18s width ease;
`;

export const progressbarStatus = css`
  height: 2px;

  background: white;
`;


export const outlinkContainer = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.9);

  li {
    display: inline-block;
    margin-right: 24px;
  }

  a {
    transition: 0.25s color ease;

    &:hover {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  @media all and (max-width: 640px) {
    font-size: 16px;
    line-height: 16px;

    li {
      margin-right: 30px;
    }
  }
`;
