import { Button, Input } from 'components';
import { useGovStakedAmount } from 'queries/useGovStakedAmount';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { getNumber, humanizeBalance } from 'utils';

import { PylonSwap, PylonSwapDepositor } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import TokenPageSwapInfo from '../token-page-swap-info';
import * as styles from '../token-page-trade/TokenPageTrade.style';

interface Props {
  isSwapStarted?: boolean;
  isLockupFinished?: boolean;
  maximumCapInUst?: number;
  depositor: PylonSwapDepositor | null;
  symbol: string;
  totalClaims: number;
  swap: PylonSwap;
  // fixedSwap?: boolean;
  onSubmit: (value: number) => void;
}

const TokenPageReverseSwap = ({
  isSwapStarted = false,
  isLockupFinished = false,
  // fixedSwap = false,
  depositor,
  maximumCapInUst,
  symbol,
  totalClaims,
  swap,
  onSubmit,
}: Props) => {
  const govStakedAmount = useGovStakedAmount();

  const input = useRef<HTMLInputElement>(null);
  const wallet = useConnectedWallet();

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');

  const maximumAllocation = useMemo(
    () =>
      Math.max(
        maximumCapInUst ?? Infinity,
        swap.depositCapStrategy.maxDepositCap,
      ),
    [maximumCapInUst, swap.depositCapStrategy.maxDepositCap],
  );

  const maximumBalance = useMemo(() => {
    const balance = depositor?.totalRewards ?? 0;
    const price = swap.priceInUst;

    return humanizeBalance(balance * price);
  }, [depositor, swap.priceInUst]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = getNumber(e.target.value);
      setInputValue(e.target.value);

      if (result.type !== 'success') {
        setValue(result.data);
        setError(result.message);
        return;
      }

      if (result.data > maximumBalance) {
        setValue(result.data);
        setError(`Exceed available ${symbol}.`);

        return;
      }

      setValue(result.data);
      setError('');
    },
    [symbol, maximumBalance],
  );

  const handleClickAllAmount = useCallback(() => {
    setInputValue(`${maximumBalance}`);
    setValue(maximumBalance);
    setError('');
  }, [maximumBalance]);

  const handleClickSubmit = async () => {
    const amount = isLockupFinished ? depositor?.claimableRewards ?? 0 : value;

    onSubmit(amount);
  };

  const isDisabled = totalClaims >= swap.totalAmount || isLockupFinished;

  const isSubmitDisabled = isLockupFinished
    ? !wallet || !depositor?.totalRewards
    : !wallet || !inputValue || !!error;

  return (
    <>
      {!isDisabled && (
        <div css={styles.formContainer}>
          <Input
            ref={input}
            css={styles.inputContainer}
            type="number"
            disabled={!wallet}
            label={symbol}
            value={inputValue}
            placeholder="0.00"
            onChange={handleChangeInput}
          />
          <div css={styles.descriptionContainer}>
            <p css={[styles.description, !!error && styles.errorMessage]}>
              {error}
            </p>
            <button
              type="button"
              css={styles.availableUST}
              disabled={!wallet}
              onClick={handleClickAllAmount}
            >
              {`${maximumBalance.toLocaleString()} ${symbol} available`}
            </button>
          </div>
        </div>
      )}
      {isLockupFinished && (
        <TokenPageSwapInfo
          symbol={symbol}
          isSwapStarted={isSwapStarted}
          isLockupFinished={isLockupFinished}
          finishDate={swap.finish}
          vestingFinishDate={swap.vesting[0]?.releaseFinishTime}
          lockupFinishDateFromApi={swap.finish.toDateString()}
          totalSold={totalClaims}
          totalSaleAmount={swap.totalAmount}
          minRequirement={swap.depositCapStrategy.minDeposit}
          minRequirementInMine={
            swap.depositCapStrategy.isMineGovernanceAware
              ? swap.depositCapStrategy.calculateMaxCapByStakedBalance(
                  govStakedAmount ?? 0,
                )
              : undefined
          }
          maxAllocation={
            maximumAllocation === Infinity
              ? 'Unlimited'
              : maximumAllocation || swap.depositCapStrategy.maxDepositCap
          }
          // maxAllocationDescription={
          //   (info as FixedSwapInfo)?.maxAllocationDescription
          // }
        />
      )}
      {!isLockupFinished && (
        <p css={styles.warningContainer}>
          {`If you swap your $${symbol} to $UST before the initial lock-up period is concluded, you may not receive your original $UST amount in full.`}
        </p>
      )}
      {!isLockupFinished && (
        <Button
          background="#ad2211"
          color="white"
          css={styles.submitButton}
          disabled={isSubmitDisabled}
          onClick={error ? undefined : handleClickSubmit}
        >
          {`Trade $${symbol} to $UST`}
        </Button>
      )}
    </>
  );
};

export default TokenPageReverseSwap;
