export const Terrascope = {
  getTransactionLink: (chainID = 'columbus-5', txHash: string) =>
    `https://terrasco.pe/${
      chainID === 'columbus-5' ? 'classic' : 'classic'
    }/tx/${txHash.toUpperCase()}`,
  getAddressLink: (chainID = 'columbus-5', address: string) =>
    `https://terrasco.pe/${
      chainID === 'columbus-5' ? 'classic' : 'classic'
    }/address/${address.toLowerCase()}`,
};
