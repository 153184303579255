import styled from '@emotion/styled';


export const Container = styled.footer`
  padding-bottom: 80px;
  margin: 0 auto;

  max-width: 1000px;

  font-size: 16px;
  line-height: 1;

  @media all and (max-width: 1024px) {
    padding: 0 8% 80px;

    font-size: 14px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media all and (max-width: 640px) {
    display: block;
  }
`;

const List = styled.ul`
  li {
    display: inline-block;
    opacity: 0.66;

    transition: 0.18s opacity ease;

    &:hover {
      opacity: 0.33;
    }
  }
`;

export const SNSList = styled(List)`
  li {
    margin-right: 32px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 12px;

    li {
      margin-right: 30px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const OutlinkList = styled(List)`
  text-align: right;

  li {
    margin-left: 24px;
  }

  @media all and (max-width: 640px) {
    text-align: left;

    li {
      margin-left: 0;
      margin-right: 20px;
    }
  }
`;

