import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from '@emotion/styled';

import { ReactComponent as CaretIcon } from 'assets/ic_caret.svg';
import { css } from '@emotion/react';

interface Props {
  attributes: NftAttributes[],
}

const NFTAttributesTooltip: React.FC<Props> = ({ attributes }) => {
  const [isOpened, setIsOpened] = useState(false);


  const handleFocusToggler = useCallback((
    nextIsOpened: typeof isOpened,
  ) => () => {
    setIsOpened(nextIsOpened);
  }, []);


  return (
    <Container>
      <Toggler
        type='button'
        onBlur={handleFocusToggler(false)}
        onFocus={handleFocusToggler(true)}
        onMouseEnter={handleFocusToggler(true)}
        onMouseLeave={handleFocusToggler(false)}
      >
        Traits <CaretIcon css={[caret, isOpened && caretRotated]} />
      </Toggler>
      <AnimatePresence initial={false}>
        {isOpened && (
          <AttributeContainer
            variants={variants}
            initial='initial'
            animate='animate'
            exit='exit'
            layout
          >
            <AttributeList>
              {attributes.map(({ trait_type: traitType, value }) => (
                <Attribute key={`${traitType}-${value}`}>
                  <TraitType>{traitType}</TraitType>
                  <AttributeValue>{value}</AttributeValue>
                </Attribute>
              ))}
            </AttributeList>
          </AttributeContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default NFTAttributesTooltip;

const variants = {
  initial: {
    opacity: 0,
    marginTop: '16px',
  },
  animate: {
    opacity: 1,
    marginTop: 0,
    transition: { duration: 0.12 },
  },
  exit: {
    opacity: 0,
    marginTop: '16px',
    transition: { duration: 0.12 },
  },
};

const Container = styled.div`
  position: relative;

  display: inline-block;
  vertical-align: middle;
`;

const Toggler = styled.button`
  opacity: 0.75;
  transition: 0.25s opacity ease;

  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);

  &:hover, &:focus-visible {
    opacity: 0.33;
  }
`;

const AttributeContainer = styled(motion.div)`
  position: absolute;
  left: 0;

  width: 300px;
  z-index: 50;
`;

const AttributeList = styled.div`
  padding: 16px;
  list-style-type: none;

  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(16px);
  border: 1px solid #00EEFA;
  border-radius: 8px;
`;

const Attribute = styled.li`
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  line-height: 14px;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`
const TraitType = styled.span`
  width: 100px;
  color: #00EEFA;
`
const AttributeValue = styled.span``

const caret = css`
  transition: .25s all ease;
`;
const caretRotated = css`
  transform: rotate(180deg);
`;
