import styled from '@emotion/styled';
import ValkyrieProfileImage from '../../assets/illust/valkyrie-profile.jpeg';

export const ValkyrieReferralNoticeBanner = () => {
  return (
    <Wrapper>
      <Container>
        <ProfileImage alt="Valkyrie Protocol" src={ValkyrieProfileImage} />
        <Paragraph>
          <White>Share to earn campaign of Pylon Protocol is now on live!</White>
          <br />
          Meet the campaign conditions and receive additional rewards.
        </Paragraph>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 32px;

  @media (max-width: 840px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`
const Container = styled.div`
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: -80px;
  max-width: 600px;
  width: 100%;

  display: block;
  padding: 16px 12px;

  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.65);
  border-radius: 4px;

  background: rgba(131, 117, 45, 0.65);
  border: 1px solid rgba(255, 220, 80, 0.85);
  box-shadow: 0px 8px 24px rgba(157, 128, 13, 0.65);
  text-align: center;

  @media all and (max-width: 640px) {
    padding: 8px 12px;
    font-size: 13px;
    line-height: 18px;
  }
`

const ProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;

  user-select: none;
  -webkit-user-drag: none;
`

const Paragraph = styled.p`
  margin-top: 8px;
`

const White = styled.span`
  color: rgba(255, 255, 255, 0.9);
`