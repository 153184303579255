import { useQuery } from 'react-query';

import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';

const usePoolWithdraw = (pool: PylonGatewayPool | null, amount: number) => {
  const wallet = useConnectedWallet();

  return useQuery(
    ['poolWithdraw', { amount }],
    async () => {
      try {
        if (!pool) {
          return null;
        }

        if (!wallet) {
          throw new Error();
        }

        const data = await pool.withdraw(wallet?.terraAddress!, amount);
        return data;
      } catch {
        return null;
      }
    },
    {
      enabled: !!pool,
      cacheTime: 0,
    },
  );
};

export default usePoolWithdraw;
