import { Button, Countdown, Input, Tab, TabContainer } from 'components';
import * as styles from 'containers/token-page/token-page-trade/TokenPageTrade.style';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { balanceAtom } from 'recoils';
import { projectSelector, symbolAtom } from 'recoils/ProjectRecoil';
import { getNumber, humanizeBalance, usePylon } from 'utils';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PylonGatewayProject } from '@pylon-protocol/sdk';
import { NftLotteryPoolDefinition } from '@pylon-protocol/sdk/lib/pylon-api';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import NftLotteryPoolConfirm from '../nft-lottery-pool-confirm';
import StakeInfo from './StakeInfo';

enum PoolStakeTab {
  stake = 'Stake',
  unstake = 'Unstake',
}

type Props = {
  projectDetail: PylonGatewayProject;
  pool: NftLotteryPoolDefinition;
  depositInformation?: { balanceInUst: number };
  hasPoolEnded: boolean;
};

const NftLotteryPoolStake: React.FC<Props> = ({
  projectDetail,
  pool,
  depositInformation: deposits,
  hasPoolEnded,
}) => {
  const pylon = usePylon();
  const input = useRef<HTMLInputElement>(null);
  const wallet = useConnectedWallet();

  const symbol = useRecoilValue(symbolAtom);
  const project = useRecoilValue(projectSelector(symbol));

  const [tab, setTab] = useState(PoolStakeTab.stake);
  const handleChangeTab = useCallback(
    (nextTab: PoolStakeTab) => () => {
      setTab(nextTab);
    },
    [],
  );

  const [stakeConfirm, setStakeConfirm] = useState<boolean>(false);
  const [unstakeConfirm, setUnstakeConfirm] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');

  const balance = useRecoilValue(balanceAtom);
  const stakingTokenSymbol = pool.ticketInfo.stakingToken;

  const [stakedAmount, setStakedAmount] = useState<number>(0);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [depositStartAt, setDepositStartAt] = useState<Date | undefined>(
    undefined,
  );

  const isDataFetched = useRef<boolean>(false);

  useEffect(() => {
    if (!wallet?.terraAddress || isDataFetched.current) {
      return;
    }
    isDataFetched.current = true;

    if (stakingTokenSymbol === 'mine') {
      pylon?.governance //
        .totalStaked()
        .then(setTotalStaked)
        .catch(console.log);
      pylon?.governance
        .stakedBalance(wallet.terraAddress)
        .then(setStakedAmount)
        .catch(console.log);
      return;
    }

    // TODO: Implement multiple lottery pools
    const lockupPool = Object.values(project?.pools ?? {})[0];
    if (!lockupPool) {
      return;
    }
    lockupPool
      .totalDepositAmount() //
      .then(setTotalStaked)
      .catch(console.log);
    lockupPool
      .statusOf(wallet.terraAddress)
      .then((status) => {
        console.log(lockupPool, status);
        setStakedAmount(status.stakedUst);
      })
      .catch(console.log);

    setDepositStartAt(lockupPool.depositTime[0].start);
  }, [project?.pools, pylon, stakingTokenSymbol, wallet?.terraAddress]);

  const [currentTime, setCurrentTime] = useState(dayjs.utc());
  const handleSyncCurrentTime = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const isDepositStarted = (() => {
    let isDepositStarted = stakingTokenSymbol === 'mine';
    if (depositStartAt) {
      isDepositStarted = currentTime.isAfter(depositStartAt);
    }
    return isDepositStarted;
  })();

  // FIXME: Replace with updated SDK
  const isPoolTabEnded = tab === 'Stake' && hasPoolEnded;

  const StartCountdown = () => (
    <Countdown
      to={dayjs.utc(depositStartAt).toDate()}
      onChange={handleSyncCurrentTime}
      onEnd={() => {
        window.location.reload();
      }}
    />
  );

  const isSubmitDisabled = !wallet || !!error || !isDepositStarted;

  const maximumBalance = useMemo(() => {
    if (tab === PoolStakeTab.stake) {
      return Math.max(
        humanizeBalance(
          stakingTokenSymbol === 'ust' ? balance.ust : balance.mine,
        ) - 1,
        0,
      );
    } else {
      return stakedAmount;
    }
  }, [tab, balance, stakingTokenSymbol, stakedAmount]);

  const exchangeRatio = pool.ticketInfo.exchangeRatio;
  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = getNumber(e.target.value);
      setInputValue(e.target.value);

      if (result.type !== 'success') {
        setValue(result.data);
        setError(result.message);
        return;
      }

      if (result.data > maximumBalance) {
        setValue(result.data);
        setError(`Exceed available ${stakingTokenSymbol.toUpperCase()}.`);
        return;
      }

      const minRequirementInUst = exchangeRatio ?? 0;
      if (
        tab === PoolStakeTab.stake &&
        minRequirementInUst &&
        result.data < minRequirementInUst
      ) {
        setValue(minRequirementInUst);
        setError(
          `Minimum deposit is $${minRequirementInUst.toLocaleString()}.`,
        );

        return;
      }

      setValue(result.data);
      setError('');
    },
    [tab, maximumBalance, exchangeRatio, stakingTokenSymbol],
  );

  const handleClickMaximumBalance = useCallback(() => {
    setValue(maximumBalance);
    setInputValue(maximumBalance.toString());
    setError('');

    if (input.current) {
      input.current.value = `${maximumBalance}`;
    }
  }, [maximumBalance]);

  return (
    <React.Fragment>
      <StartCountdown />
      {!isDepositStarted &&
      projectDetail.projectDefinition.symbol === 'deviants-factions' &&
      pool.ticketInfo.stakingToken !== 'mine' ? (
        <p
          css={[
            styles.description,
            styles.launchWaitDescription,
            css`
              margin-top: -20px;
            `,
          ]}
        >
          * Pool will open at Nov 10, 2021 13:00Z
        </p>
      ) : (
        <React.Fragment>
          <StakeTabs>
            <Tab
              current={tab === PoolStakeTab.stake}
              onClick={handleChangeTab(PoolStakeTab.stake)}
            >
              {PoolStakeTab.stake}
            </Tab>
            <Tab
              current={tab === PoolStakeTab.unstake}
              onClick={handleChangeTab(PoolStakeTab.unstake)}
            >
              {PoolStakeTab.unstake}
            </Tab>
          </StakeTabs>
          <div css={styles.formContainer}>
            <Input
              ref={input}
              css={styles.inputContainer}
              type="number"
              disabled={!wallet || !isDepositStarted || isPoolTabEnded}
              label={stakingTokenSymbol.toUpperCase()}
              value={inputValue}
              placeholder={
                exchangeRatio === 1 ? '0.00' : exchangeRatio.toString()
              }
              onChange={handleChangeInput}
            />
            <div css={styles.descriptionContainer}>
              <p css={[styles.description, !!error && styles.errorMessage]}>
                {error}
              </p>
              <button
                type="button"
                css={styles.availableUST}
                disabled={!wallet || isPoolTabEnded || isSubmitDisabled}
                onClick={handleClickMaximumBalance}
              >
                {`${maximumBalance.toLocaleString()} ${stakingTokenSymbol.toUpperCase()} available`}
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
      <StakeInfo
        stakedAmount={stakedAmount}
        totalStaked={totalStaked}
        stakingTokenSymbol={stakingTokenSymbol}
        exchangeRatio={exchangeRatio}
        showTickets={symbol !== 'lunabulls'}
      />
      <Button
        background="#00eefa"
        onClick={() =>
          tab === 'Stake' ? setStakeConfirm(true) : setUnstakeConfirm(true)
        }
        disabled={isSubmitDisabled || isPoolTabEnded}
        css={styles.submitButton}
      >
        {!isPoolTabEnded
          ? `${tab} $${stakingTokenSymbol.toUpperCase()}`
          : `Pool Closed`}
      </Button>
      {(stakeConfirm || unstakeConfirm) && (
        <React.Suspense fallback={<></>}>
          <NftLotteryPoolConfirm
            deposit={value}
            confirmAction={!!stakeConfirm ? 'stake' : 'unstake'}
            stakingTokenSymbol={stakingTokenSymbol}
            symbol={symbol}
            pool={pool}
            onClose={() => {
              setValue(0);
              setInputValue('0');
              setStakeConfirm(false);
              setUnstakeConfirm(false);
            }}
          />
        </React.Suspense>
      )}
    </React.Fragment>
  );
};

export default NftLotteryPoolStake;

const StakeTabs = styled(TabContainer)`
  margin-bottom: 24px;

  @media all and (max-width: 640px) {
    margin-bottom: 12px;
  }
`;
