import axios, { AxiosRequestConfig } from 'axios';

export const Endpoint = {
  production: 'https://api.pylon.money/',
  develop: 'https://api.dev.pylon.rocks/',
};

const successInterceptor = (response: any) => response;
const failureInterceptor = (error: any) => {
  console.log(error);
  return Promise.resolve(
    error?.response ?? {
      status: 404,
    },
  );
};

const client = (chainID: string, config?: AxiosRequestConfig) => {
  const axiosConfig = {
    ...config,
    baseURL: config?.baseURL ?? getBaseURL(chainID),
  };
  const http = axios.create(axiosConfig);

  http.interceptors.request.use(successInterceptor, failureInterceptor);
  http.interceptors.response.use(successInterceptor, failureInterceptor);

  return http;
};

export const getBaseURL = (chainID: string) => {
  if (chainID.startsWith('columbus-')) {
    return Endpoint.production;
  }

  return Endpoint.develop;
};

export default client;
