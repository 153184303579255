import { atom, selectorFamily } from 'recoil';
import { pylonAtom } from './Pylon';
import { PylonGatewayProject } from '@pylon-protocol/sdk';


const createKey = (key: string) => `@project/${key}`;


export const forceUpdateAtom = atom({
  key: createKey('force-update'),
  default: {},
});

export const symbolAtom = atom({
  key: createKey('symbol'),
  default: '',
});

export const projectSelector = selectorFamily<PylonGatewayProject | undefined | null, string>({
  key: createKey('project-detail'),
  get: (baseSymbol) => async ({ get }) => {
    get(forceUpdateAtom);
    const pylon = get(pylonAtom);

    const symbol = baseSymbol || get(symbolAtom);
    if (!symbol) {
      return null;
    }

    const project = await pylon?.gateway.getProject(symbol);
    return project
  },
});
