import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { usePrevious, useLCDClient } from 'utils';


const LCDManager = () => {
  const queryClient = useQueryClient();
  const client = useLCDClient();
  const prevNetwork = usePrevious(client.config.chainID);


  useEffect(() => {
    if (!prevNetwork) {
      return;
    }

    if (prevNetwork !== client.config.chainID) {
      queryClient.removeQueries();
    }
  }, [client.config.chainID]); // eslint-disable-line

  return null;
};

export default LCDManager;
