import { useQuery } from 'react-query';
import { getDefaultGas, usePylon } from 'utils';

import { Coin } from '@terra-money/terra.js';
import { useConnectedWallet } from '@terra-money/wallet-provider';

import { fetchGas } from './useFetchGas';

export const getStake = async (
  pylon: ReturnType<typeof usePylon>,
  address: string,
  amount: number,
) => {
  try {
    if (!pylon) {
      return;
    }

    const [data, gasPrice] = await Promise.all([
      await pylon.governance.stake(address, amount),
      await fetchGas(),
    ]);

    const fee = Math.round(getDefaultGas() * gasPrice);
    const transactionFee = new Coin('uusd', fee);

    return {
      ...data,
      transactionFee,
    };
  } catch {
    return undefined;
  }
};

type UseStakeParams = {
  amount: number;
  enabled: boolean;
};
const useStake = (options: UseStakeParams) => {
  const { amount } = options;
  const pylon = usePylon();
  const wallet = useConnectedWallet();

  const address = wallet?.terraAddress ?? '';
  const enabled = !!amount && !!wallet?.terraAddress && options.enabled;

  return useQuery(
    ['@staking/stake/stake', { amount }],
    async () => getStake(pylon, address, amount),
    {
      enabled,
      cacheTime: 0,
    },
  );
};

export default useStake;
