import { useEffect, useMemo } from 'react';
import { atom, useSetRecoilState } from 'recoil';

import { Pylon } from '@pylon-protocol/sdk';
import { Options } from '@pylon-protocol/sdk/lib/options';
import { useLCDClient } from 'utils';

export const pylonAtom = atom<Pylon | null>({ key: 'pylon', default: null });

export const PylonSubscriber = () => {
  const lcd = useLCDClient();
  const pylon = useMemo(() => {
    try {
      let options: Partial<Options> | undefined;
      const isLocalTerra =
        process.env.NODE_ENV === 'development' &&
        lcd.config.chainID === 'localterra';

      if (isLocalTerra) {
        options = {
          // overrideAddressesWith: {},
        };
      }

      return new Pylon(lcd, options);
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [lcd]);

  const setPylonState = useSetRecoilState(pylonAtom);
  useEffect(() => setPylonState(pylon), [pylon, setPylonState]);

  return null;
};
