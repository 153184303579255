import client, { Endpoint } from 'api/client';
import { QueryFunction } from 'react-query';

export type ProjectStats = {
  project: {
    yieldToken: {
      symbol: string;
      contract: string;
      exchangeRateFromUst: number;
    };
    yieldTokenBalance: number;
    dpTokenSupply: number;
    claimableYieldBalance: number;
  };
  pools: {
    id: number;
    name: string;
    totalTokensDistributed: number;
  }[];
  swap: {
    totalTokensDistributed: number;
    roi: number;
  };
};

export const getProjectStats: QueryFunction<ProjectStats | null> = async ({
  queryKey,
}) => {
  const [, symbol] = queryKey as [string, string];
  const { data } = await client('columbus-5', {
    baseURL: Endpoint.production,
  }).get<ProjectStats>(`api/gateway/v1/projects/${symbol}/stats`);

  if ('error' in data) {
    return null;
  }

  return data;
};
