import React from 'react';

import { AnimateSharedLayout } from 'framer-motion';

import * as styles from './Tab.style';


export interface Props extends React.ComponentProps<typeof styles.TabBase> {
  current?: boolean;
  children: React.ReactNode;
}


export const TabContainer = ({
  children,
  ...props
}: React.ComponentProps<typeof styles.Container>) => (
  <styles.Container {...props}>
    <AnimateSharedLayout>
      {children}
    </AnimateSharedLayout>
  </styles.Container>
);


const Tab = ({
  current = false,
  children,
  ...props
}: Props) => (
  <styles.TabBase
    {...props}
    type='button'
    current={current}
  >
    {children}
    <styles.Label current={current}>
      {children}
    </styles.Label>
    {current && (
      <styles.FocusArea
        layoutId='Tab__focusArea'
        initial={false}
        transition={styles.spring}
      />
    )}
  </styles.TabBase>
);

export default Tab;
