import { ReactComponent as CaretIcon } from 'assets/ic_caret.svg';
import { ReactComponent as CheckIcon } from 'assets/ic_check_circle_filled.svg';
import { ReactComponent as CloseIcon } from 'assets/ic_close_circle_outline.svg';
import { ReactComponent as Symbol } from 'assets/ic_symbol.svg';
import { Countdown, Tooltip } from 'components';
import dayjs from 'dayjs';
import { AnimatePresence } from 'framer-motion';
import { usePylonPool } from 'queries';
import { useGovStakedAmount } from 'queries/useGovStakedAmount';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { toFixed } from 'utils';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { MsgExecuteContract } from '@terra-money/terra.js';

import {
  TokenPageDetailQuery, TokenPagePoolAPY, TokenPagePoolDetail
} from './TokenPagePoolOption.query';
import * as styles from './TokenPagePoolOption.style';
import { useValkyrieCampaign } from './useValkyrieCampaign';
import { useValkyrieReferral } from './useValkyrieReferral';

// FIXME: hardcoded mine requirement thresholds
const MINE_REQUIRES: {
  [key: string]: number;
} = {
  orion: 1_000,
  xdefi: 10_000,
  arts: 10_000,
};

interface Props {
  disabled?: boolean;
  symbol: string;
  startsAt?: string | Date;
  tokenPrice: number;
  pool: PylonGatewayPool;
  availableAllocation?: number;
  onClick?: (pool: number, transactions?: MsgExecuteContract[]) => void;
  value: number;
  poolID: number;
}

const isTerraAddress = (value: string): boolean =>
  value.startsWith('terra') && value.length === 44;

const TokenPagePoolOption = ({
  disabled = false,
  symbol,
  startsAt,
  tokenPrice,
  pool,
  availableAllocation,
  onClick,
  value,
  poolID,
}: Props) => {
  const lockupPool = usePylonPool(symbol, poolID);

  const hasMineRequires = ['orion', 'xdefi'].includes(symbol.toLowerCase());

  const [currentTime, setCurrentTime] = useState(dayjs.utc());
  const isPoolStarted = dayjs(pool.depositTime[0].start).isBefore(currentTime);

  const isVestingEnded = !!pool.withdrawTime.length
    ? dayjs(pool.withdrawTime[0].end).isBefore(currentTime)
    : true;
  const isPoolClosed = isVestingEnded || !pool.isDepositable();

  const totalVestingInMonths = !!pool.withdrawTime.length
    ? Math.round(
        dayjs(pool.withdrawTime[0].end).diff(
          pool.withdrawTime[0].start,
          'month',
          true, // round
        ),
      )
    : NaN;

  const cliffPeriodInMonths =
    !!pool.rewardClaimTime.length && !!pool.withdrawTime.length
      ? Math.round(
          dayjs(pool.rewardClaimTime[0].start).diff(
            pool.withdrawTime[0].start,
            'month',
            true, // round
          ),
        )
      : NaN;

  const [referral] = useQueryParam('referral', StringParam);
  const referrer = useMemo(() => {
    if (!referral) {
      return null;
    }
    if (isTerraAddress(referral)) {
      return { address: referral };
    }
    return { compressed: referral };
  }, [referral]);

  const deposit = useValkyrieCampaign({
    referrer,
  });

  const isValkyrieReferralPool = useMemo(() => {
    return symbol.toLowerCase() === 'mine' && totalVestingInMonths === 18;
  }, [symbol, totalVestingInMonths]);

  const valkyrieReferralConditions = useValkyrieReferral({
    skip: !isValkyrieReferralPool,
  });

  const [isDetailOpened, setDetailOpened] = useState<boolean>(false);
  const govStakedAmount = useGovStakedAmount();

  const handleToggleDetail = useCallback(() => {
    setDetailOpened((prev) => !prev);
  }, []);

  const handleSyncCurrentTime = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const handleClickDeposit = useCallback(() => {
    onClick?.(poolID);
  }, [poolID, onClick]);

  const handleClickCampaignDeposit = useCallback(async () => {
    const transactions = await deposit();
    onClick?.(poolID, transactions);
  }, [poolID, deposit, onClick]);

  const isOneMonth = totalVestingInMonths <= 1;

  const isCampaignButtonDisabled = useMemo(
    () =>
      disabled ||
      Object.values(valkyrieReferralConditions).some((v) => !v) ||
      value < 200,
    [disabled, valkyrieReferralConditions, value],
  );

  const stakedMineRequired = useMemo(() => {
    return MINE_REQUIRES[symbol.toLowerCase()];
  }, [symbol]);

  return (
    <>
      <styles.Container
        tier={poolID}
        css={
          isValkyrieReferralPool &&
          css`
            background: rgba(130, 108, 0, 0.65) !important;
            border: 2px solid rgb(255, 223, 96);
            box-shadow: 0px 12px 24px rgba(157, 128, 13, 0.65);

            .tier__symbol {
              path {
                fill: #fef55c;
              }
            }
          `
        }
      >
        <Symbol className="tier__symbol" />
        <div css={styles.contentContainer}>
          <div css={styles.infoContainer}>
            <div css={styles.info}>
              <p css={styles.tierTitle}>{`Pool ${poolID + 1}`}</p>
              {/* FIXME: */}
              <p>
                {(pool as any).description || (
                  <>
                    {`${totalVestingInMonths.toLocaleString()} months total vesting.`}
                    <br />
                    {`Rewards claimable starting after ${cliffPeriodInMonths} month${
                      isOneMonth ? '' : 's'
                    }.`}
                  </>
                )}
              </p>
            </div>
            {!isValkyrieReferralPool ? (
              <styles.DepositButton
                width={160}
                tier={poolID}
                // disable on pool close
                disabled={disabled || isPoolClosed}
                onClick={handleClickDeposit}
              >
                <Symbol />
                <span>Deposit via Pylon</span>
              </styles.DepositButton>
            ) : (
              <div css={styles.campaignButtons}>
                <styles.DepositButton
                  width={160}
                  tier={poolID}
                  disabled={disabled}
                  onClick={handleClickDeposit}
                >
                  <Symbol />
                  <span>Deposit via Pylon</span>
                </styles.DepositButton>
                <styles.CampaignButton
                  width={200}
                  tier={poolID}
                  disabled={isCampaignButtonDisabled}
                  onClick={handleClickCampaignDeposit}
                  css={css`
                    margin-top: 8px;
                  `}
                >
                  <Symbol />
                  <span>Deposit with Campaign</span>
                </styles.CampaignButton>
              </div>
            )}
          </div>
          {hasMineRequires && typeof availableAllocation !== 'undefined' && (
            <div
              css={styles.availableAllocationContainer}
              style={{ marginBottom: isPoolStarted ? 12 : 0 }}
            >
              <span css={styles.availableAllocation}>
                {`${toFixed(
                  govStakedAmount || 0,
                  2,
                )} / ${stakedMineRequired.toLocaleString()}`}
                <span css={styles.availableAllocationField}>
                  Required $MINE Staked
                </span>
                <Tooltip>
                  {`Requires at least ${stakedMineRequired.toLocaleString()} MINE staked to participate in the pool.`}
                </Tooltip>
              </span>
            </div>
          )}
          {isValkyrieReferralPool && (
            <React.Fragment>
              <button css={styles.detailsButton} type="button">
                <span>Conditions for the Campaign</span>
              </button>
              <ReferralConditions>
                <ReferralConditionItem
                  status={valkyrieReferralConditions.qualifiable}
                >
                  <ReferralConditionIcon
                    status={valkyrieReferralConditions.qualifiable}
                  />
                  <span>
                    {`Wallet address is ${
                      valkyrieReferralConditions.qualifiable
                        ? 'qualifiable'
                        : 'unqualifiable'
                    }`}
                  </span>
                </ReferralConditionItem>
                <ReferralConditionItem
                  status={valkyrieReferralConditions.hasAtLeast50vkr}
                >
                  <ReferralConditionIcon
                    status={valkyrieReferralConditions.hasAtLeast50vkr}
                  />
                  <span>
                    {valkyrieReferralConditions.hasAtLeast50vkr
                      ? `Possesses at least 50 $VKR`
                      : `At least 50 $VKR must be available in wallet`}
                  </span>
                </ReferralConditionItem>
                <ReferralConditionItem
                  status={valkyrieReferralConditions.hasStakedAtLeast500mine}
                >
                  <ReferralConditionIcon
                    status={valkyrieReferralConditions.hasStakedAtLeast500mine}
                  />
                  <span>
                    {valkyrieReferralConditions.hasStakedAtLeast500mine
                      ? `Staked at least 500 $MINE at governance`
                      : `At least 500 $MINE must be staked at governance`}
                  </span>
                </ReferralConditionItem>
              </ReferralConditions>
            </React.Fragment>
          )}
          {isPoolStarted && (
            <div css={styles.apyContainer}>
              <div>
                <button
                  css={styles.detailsButton}
                  type="button"
                  onClick={handleToggleDetail}
                >
                  <span>Details</span>
                  <CaretIcon
                    css={[styles.caret, isDetailOpened && styles.caretRotated]}
                  />
                </button>
              </div>
              <div>
                <div css={styles.apy}>
                  <Suspense fallback={<>-</>}>
                    <TokenPagePoolAPY
                      pool={pool}
                      symbol={symbol}
                      tokenPrice={tokenPrice}
                      isPoolClosed={isPoolClosed}
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          )}
          <AnimatePresence initial={false}>
            {isDetailOpened && (
              <styles.DetailContainer
                variants={styles.variants}
                initial="initial"
                animate="animate"
                exit="exit"
                layout
              >
                <Suspense fallback={<TokenPagePoolDetail />}>
                  <TokenPageDetailQuery pool={lockupPool} symbol={symbol} />
                </Suspense>
              </styles.DetailContainer>
            )}
          </AnimatePresence>
        </div>
      </styles.Container>
      <Countdown to={startsAt} onChange={handleSyncCurrentTime} />
    </>
  );
};

export default TokenPagePoolOption;

const ReferralConditions = styled.ul`
  margin: 0;
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 0;
  list-style-type: none;
`;

type StatusProps = {
  status?: boolean;
};
const ReferralConditionItem = styled.li<StatusProps>`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  line-height: 1;

  & > span {
    margin-left: 4px;
  }

  ${({ status }) =>
    status
      ? css`
          color: rgb(255, 223, 96);
        `
      : css`
          color: #ff8686;
        `};
`;
const ReferralConditionIcon: React.FC<
  StatusProps & React.SVGProps<SVGSVGElement>
> = ({ status, ...props }) =>
  status ? <CheckIcon {...props} /> : <CloseIcon {...props} />;
