import { Spinner } from 'components';
import { LCD } from 'modules';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toastAtom, transactionHistoryAtom } from 'recoils';

import { LCDClient, TxInfo } from '@terra-money/terra.js';

import * as styles from './TxObserver.style';

interface Props {
  onToggle: () => void;
}

const lcd = {
  [LCD.mainnet.chainID]: new LCDClient({
    URL: LCD.mainnet.lcd,
    chainID: LCD.mainnet.chainID,
    isClassic: true,
  }),
};

const TxObserver = ({ onToggle }: Props) => {
  const [transactionHistory, setTransactionHistory] = useRecoilState(
    transactionHistoryAtom,
  );
  const setToastList = useSetRecoilState(toastAtom);

  const txRef = useRef(transactionHistory);
  const interval = useRef(-1);

  const fetchHistories = useCallback(
    async (history: typeof transactionHistory) => {
      const txInfoList: TxInfo[] = await Promise.all(
        history.map((item) =>
          lcd[item.chainID]?.tx.txInfo(item.hash).catch((e) => e),
        ),
      );

      const nextHistory = history.reduce<typeof transactionHistory>(
        (arr, item, i) => {
          const tx = txInfoList[i];
          if (tx instanceof Error) {
            return arr.concat(item);
          }

          // FIXME: Anti Pattern
          item.callback?.(tx);

          const toast = {
            id: item.id,
            data: item,
            intent: tx.code ? ('failure' as const) : ('success' as const),
          };

          setToastList((prev) => [toast, ...prev]);
          return arr;
        },
        [],
      );

      setTransactionHistory(nextHistory);
    },
    [setTransactionHistory, setToastList],
  );

  useEffect(() => {
    txRef.current = transactionHistory;
  }, [transactionHistory]);

  useEffect(() => {
    fetchHistories(txRef.current);

    interval.current = window.setInterval(() => {
      fetchHistories(txRef.current);
    }, 1000 * 10);

    return () => {
      window.clearInterval(interval.current);
    };
  }, [fetchHistories]);

  if (!transactionHistory.length) {
    return null;
  }

  return (
    <styles.Container type="button" onClick={onToggle}>
      <Spinner size={12} />
      <span css={styles.label}>{`${transactionHistory.length} Pending`}</span>
    </styles.Container>
  );
};

export default TxObserver;
