import React from 'react';

import MDX from '@mdx-js/runtime';
import { ReactComponent as ArrowIcon } from 'assets/ic_arrow_forward.svg';

import * as styles from '../../token-page/token-page-info/TokenPageInfo.style';
import { useRecoilValue } from 'recoil';
import { projectSelector, symbolAtom } from 'recoils/ProjectRecoil';


const components = {
  Link: ({ children, ...props }: React.ComponentProps<typeof styles.Link>) => (
    <styles.Link {...props}>
      <span>
        {children}
      </span>
      <ArrowIcon />
    </styles.Link>
  ),
  OutlinkContainer: styles.OutlinkContainer,
  OptionalLinkList: styles.OptionalLinkList,
  ImageContainer: styles.ImageContainer,
};


const TokenPageInfo = () => {
  const symbol = useRecoilValue(symbolAtom);
  const project = useRecoilValue(projectSelector(symbol));
  const projectDefinition = project?.projectDefinition

  return (
    <article css={styles.markdown}>
      <MDX components={components}>
        {projectDefinition?.description}
      </MDX>
    </article>
  );
};

export default TokenPageInfo;
