import { fetchPrice, Pylon } from '@pylon-protocol/sdk';

const fakePriceProvider = (price: number) => ({
  async tokenPrice(): Promise<number> {
    return price;
  },
});

const FALLBACK_TOKEN_PRICE_UST = 0.05;

const createPriceProvider = (
  pylon: Pylon,
  chainID: string | undefined,
  tokenAddress: string | undefined,
) => {
  if (chainID !== 'columbus-5' || !tokenAddress) {
    return fakePriceProvider(0.05);
  }

  if (tokenAddress === 'terra1g0pm8xm5c2dq4qtv8j9a80hg4mhe5ndy8qad07') {
    // lunart
    return fakePriceProvider(0.017);
  }

  if (tokenAddress === 'terra1laczhlpxlgmrwr9un9ds74qxd2fj4754nf82dn') {
    // wcoin
    return fakePriceProvider(0.095);
  }

  return {
    async tokenPrice(): Promise<number> {
      let price = await fetchPrice(pylon.lcd, tokenAddress);
      if (!price) {
        // the reward token is not listed yet
        price = FALLBACK_TOKEN_PRICE_UST;
      }
      return price;
    },
  };
};

export default createPriceProvider;
