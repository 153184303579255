import { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';
import { forceUpdateAtom } from 'recoils/ProjectRecoil';

import { useConnectedWallet } from '@terra-money/wallet-provider';
import { useBalance } from 'queries';

const useRefetchUserInfo = () => {
  const wallet = useConnectedWallet();

  const refetchProjectDetail = useSetRecoilState(forceUpdateAtom);
  const { refetch: fetchBalance } = useBalance(
    wallet?.terraAddress ?? '',
    false,
  );

  const updater = useCallback(() => {
    refetchProjectDetail((prev) => ({ ...prev }));
    fetchBalance();
  }, [refetchProjectDetail, fetchBalance]);

  return {
    forceUpdate: updater,
  };
};

export default useRefetchUserInfo;
