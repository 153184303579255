import { css } from '@emotion/react';
import styled from '@emotion/styled';


export const modal = css`
  position: fixed;
  top: 50%;
  left: 50%;

  display: block;
  width: 640px;

  z-index: 210;

  @media all and (max-width: 840px) {
    width: 80%;
  }

  @media all and (max-width: 640px) {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const Container = styled.div<{
  type?: 'success' | 'failure';
}>`
  position: relative;

  width: 100%;
  padding: 40px 48px 36px;

  background: #010a1e;

  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  filter: drop-shadow(0px 20px 120px rgba(0, 0, 0, 0.6));
  transform: translate(-50%, -50%);

  overflow: hidden;

  @media all and (max-width: 840px) {
    padding: 40px 48px;
  }

  @media all and (max-width: 640px) {
    padding: 48px 24px 32px;

    transform: none;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${props => !!props.type ? `
    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 2px;

      ${props.type === 'success' ? `
        background: #00eefa;
      ` : ''}

      ${props.type === 'failure' ? `
        background: #fa19a9;
      ` : ''}
    }
  ` : ''}
`;

export const modalCloseButton = css`
  position: absolute;
  top: 20px;
  right: 18px;

  opacity: 0.2;
  transition: 0.2s all ease;

  svg {
    width: 32px;
    height: 32px;
  }

  &:hover {
    opacity: 1;
  }

  @media all and (max-width: 840px) {
    top: 16px;
    right: 16px;
  }

  @media all and (max-width: 640px) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
`;
