import { useMemo } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { chainIdAtom } from 'recoils/ChainIdRecoil';
import { projectSelector } from 'recoils/ProjectRecoil';
import { usePylon } from 'utils';

import { PylonGatewayPool } from '@pylon-protocol/sdk';

const Cache: Record<string, PylonGatewayPool> = {};

const usePylonPool = (
  symbol: string,
  poolId: number | undefined,
  skip: boolean = false,
) => {
  const pylon = usePylon();
  const chainID = useRecoilValue(chainIdAtom);
  const project = useRecoilValueLoadable(projectSelector(symbol));

  const uniqueID = useMemo(
    () => [symbol, poolId, chainID].join('$::$'),
    [symbol, poolId, chainID],
  );

  const pool = useMemo(() => {
    if (typeof poolId === 'undefined' || skip) {
      return null;
    }
    // // if there is a cache, load pool from cache
    // if (Cache[uniqueID]) {
    //   return Cache[uniqueID];
    // }

    // wait for loading project info
    if (project.state !== 'hasValue' || !project.contents) {
      return null;
    }

    // find pool that matches with `poolId`
    console.log(project.contents, project.contents.pools);
    const pool = Object.values(project.contents.pools)[poolId];
    if (!pool) {
      return null;
    }

    // store pool at cache and return it
    Object.assign(Cache, { [uniqueID]: pool });
    return pool;
  }, [project, pylon, uniqueID, poolId]); // eslint-disable-line

  return pool;
};

export default usePylonPool;
