import groupBy from 'lodash.groupby';
import React, { useMemo } from 'react';
import { LogoMap } from 'utils/logoMap';

import styled from '@emotion/styled';
import { ProjectDefinition } from '@pylon-protocol/sdk/lib/pylon-api';

type ProjectSelectorProps = {
  selected: string;
  setSelected: (value: string) => void;
  projects: ProjectDefinition[];
};
export const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  selected,
  setSelected,
  projects,
}) => {
  const projectsByGroup = useMemo(() => {
    const filtered = projects.filter((v) => v.symbol !== 'gfi');
    return groupBy(filtered, (v) => v.type);
  }, [projects]);

  const projectTypes = ['token', 'nft'];

  return (
    <Wrapper>
      <Subtitle>Projects</Subtitle>
      <Container>
        <SelectionList style={{ marginTop: -20 }}>
          {projectTypes.map((groupedType) => (
            <React.Fragment>
              {groupedType === 'dao' && (
                <ProjectGroupTitle>DAOs</ProjectGroupTitle>
              )}
              {groupedType === 'token' && (
                <ProjectGroupTitle>Project Tokens</ProjectGroupTitle>
              )}
              {groupedType === 'nft' && (
                <ProjectGroupTitle>NFTs</ProjectGroupTitle>
              )}
              {projectsByGroup[groupedType]?.map((project) => {
                const selectorTitle =
                  project.type === 'nft' ? project.name : project.symbol;
                const isSelected = selected === project.symbol;
                const logo = LogoMap[project.symbol.toLowerCase()];

                return (
                  <React.Fragment key={project.symbol}>
                    {isSelected && (
                      <SelectedContainer>
                        {logo && <ProjectLogo src={logo} alt={project.name} />}
                        <ProjectInformation>
                          <ProjectSymbol style={{ maxWidth: 100 }}>
                            {selectorTitle}
                          </ProjectSymbol>
                          {project.type !== 'nft' && (
                            <ProjectName>{project.name}</ProjectName>
                          )}
                        </ProjectInformation>
                      </SelectedContainer>
                    )}
                    {!isSelected && (
                      <DisabledProjectInformation
                        onClick={() => setSelected(project.symbol)}
                      >
                        <ProjectLogo
                          src={logo}
                          alt={project.name}
                          style={{ display: 'none' }} // for preloading
                        />
                        <DisabledProjectSymbol>
                          {selectorTitle}
                        </DisabledProjectSymbol>
                        {project.type !== 'nft' && (
                          <DisabledProjectName>
                            {project.name}
                          </DisabledProjectName>
                        )}
                      </DisabledProjectInformation>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        </SelectionList>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-right: 108px;
  max-width: 247px;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1440px) {
    margin-right: 84px;
  }

  @media screen and (max-width: 1100px) {
    margin-right: 48px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
`;
const ProjectGroupTitle = styled.h3`
  margin: 0;
  margin-top: 32px;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
`;

const Container = styled.div`
  margin-top: 28px;
  position: relative;

  display: flex;
  flex-direction: column;
`;

const SelectionList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  width: 210px;
  height: 520px;
  box-sizing: border-box;

  &,
  * {
    transition: all 0.2s ease-in-out;
    user-select: none;
  }
`;

const SelectedContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex: none;
  gap: 12px;
  width: 163px;
  height: 44px;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    width: 247px;
    height: 68px;
    left: -12px;
    top: -12px;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: absolute;
    backdrop-filter: blur(8px);
  }
`;

const ProjectLogo = styled.img`
  width: 42px;
  height: 42px;
  filter: drop-shadow(0px 8px 16px rgba(0, 153, 160, 0.08));
  object-fit: contain;
  margin-left: 16px;
`;

const ProjectInformation = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  flex: none;
  gap: 2px;
  min-width: 109px;
  height: 44px;
  box-sizing: border-box;
  cursor: pointer;
`;
const DisabledProjectInformation = styled(ProjectInformation)`
  width: 100%;

  &:hover {
    opacity: 0.45;
    transform: scale(0.98);
  }
`;

const ProjectSymbol = styled.span`
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
`;
const ProjectName = styled.span`
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 100%;
  text-align: left;
`;

const DisabledProjectSymbol = styled.span`
  color: rgba(255, 255, 255, 0.45);
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  text-align: left;
`;
const DisabledProjectName = styled.span`
  font-size: 18px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.65);
`;
