import { ReactComponent as CaretIcon } from 'assets/ic_caret.svg';
import { ReactComponent as Symbol } from 'assets/ic_symbol.svg';
import PsiBdpTokenLogo from 'assets/logo/psi-bdp-token.png';
import { Countdown, Tooltip } from 'components';
import dayjs from 'dayjs';
import { AnimatePresence } from 'framer-motion';
import { useGovStakedAmount } from 'queries/useGovStakedAmount';
import React, { Suspense, useCallback, useState } from 'react';
import { toFixed } from 'utils';

import styled from '@emotion/styled';
import { PylonGatewayPool } from '@pylon-protocol/sdk';
import { MsgExecuteContract } from '@terra-money/terra.js';

import {
  TokenPageDetailQuery, TokenPagePoolAPY, TokenPagePoolDetail
} from './TokenPageLiquidPoolOption.query';
import * as styles from './TokenPageLiquidPoolOption.style';

interface Props {
  poolID: number;
  disabled?: boolean;
  symbol: string;
  startsAt?: string | Date;
  tokenPrice: number;
  pool: PylonGatewayPool;
  availableAllocation?: number;
  onClick?: (pool: number, transactions?: MsgExecuteContract[]) => void;
  value: number;
}

const TokenPageLiquidPoolOption = ({
  poolID,
  disabled = false,
  symbol,
  startsAt,
  tokenPrice,
  pool,
  availableAllocation,
  onClick,
  value,
}: Props) => {
  // TODO: use if some liquid pools require mine to be staked
  const hasMineRequires = false;

  // FIXME:
  // const totalVestingInMonths = toFixed(pool.vestingPeriodInDays / 30, 0);
  const totalVestingInMonths = 6;

  const [isDetailOpened, setDetailOpened] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(dayjs.utc());
  const govStakedAmount = useGovStakedAmount();

  const handleToggleDetail = useCallback(() => {
    setDetailOpened((prev) => !prev);
  }, []);

  const handleSyncCurrentTime = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const handleClickDeposit = useCallback(() => {
    onClick?.(poolID);
  }, [poolID, onClick]);

  const isPoolStarted = currentTime.isAfter(dayjs.utc(startsAt));

  return (
    <>
      <styles.Container>
        <DpTokenLogo alt="Nexus Liquid" src={PsiBdpTokenLogo} />
        <div css={styles.contentContainer}>
          <div css={styles.infoContainer}>
            <div css={styles.info}>
              <p css={styles.tierTitle}>{`Pool ${poolID + 1}`}</p>
              <p>
                {(pool as any).description || (
                  <>
                    {`${totalVestingInMonths.toLocaleString()} months total vesting.`}
                  </>
                )}
              </p>
            </div>
            <styles.DepositButton
              width={160}
              disabled={disabled}
              onClick={handleClickDeposit}
            >
              <Symbol />
              <span>Deposit via Pylon</span>
            </styles.DepositButton>
          </div>
          {hasMineRequires && typeof availableAllocation !== 'undefined' && (
            <div
              css={styles.availableAllocationContainer}
              style={{ marginBottom: isPoolStarted ? 12 : 0 }}
            >
              <span css={styles.availableAllocation}>
                {`${toFixed(govStakedAmount || 0, 2)} / 1,000`}
                <span css={styles.availableAllocationField}>
                  Required $MINE Staked
                </span>
                <Tooltip>
                  Requires at least 1,000 MINE staked to participate in the
                  pool.
                </Tooltip>
              </span>
            </div>
          )}
          {isPoolStarted && (
            <div css={styles.apyContainer}>
              <div>
                <button
                  css={styles.detailsButton}
                  type="button"
                  onClick={handleToggleDetail}
                >
                  <span>Details</span>
                  <CaretIcon
                    css={[styles.caret, isDetailOpened && styles.caretRotated]}
                  />
                </button>
              </div>
              <div>
                <div css={styles.apy}>
                  <Suspense fallback={<>-</>}>
                    <TokenPagePoolAPY
                      pool={pool}
                      symbol={symbol}
                      tokenPrice={tokenPrice}
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          )}
          <AnimatePresence initial={false}>
            {isDetailOpened && (
              <styles.DetailContainer
                variants={styles.variants}
                initial="initial"
                animate="animate"
                exit="exit"
                layout
              >
                <Suspense fallback={<TokenPagePoolDetail />}>
                  <TokenPageDetailQuery pool={pool} symbol={symbol} />
                </Suspense>
              </styles.DetailContainer>
            )}
          </AnimatePresence>
        </div>
      </styles.Container>
      <Countdown to={startsAt} onChange={handleSyncCurrentTime} />
    </>
  );
};

export default TokenPageLiquidPoolOption;

const DpTokenLogo = styled.img`
  width: 42px;
  height: 42px;
`;
