import { LCD } from 'modules';
import { useMemo } from 'react';

import { LCDClient } from '@terra-money/terra.js';
import { useConnectedWallet } from '@terra-money/wallet-provider';

const useLCDClient = () => {
  const wallet = useConnectedWallet();
  const { chainProvider } = LCD.loader();

  const client = useMemo(() => {
    const { lcd: URL, chainID } = wallet?.network ?? chainProvider[0];
    return new LCDClient({ URL, chainID, isClassic: true });
  }, [wallet?.network]); // eslint-disable-line

  return client;
};

export default useLCDClient;
