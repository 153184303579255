import client, { Endpoint } from 'api/client';

type GetStatsOverviewResponse = {
  totalValuesTransferred: {
    usersToProjects: number,
    projectsToUsers: number,
  },
  totalValueLocked: {
    current: number,
    historical: {
      date: string, value: number,
    }[],
  },
}
export const getStatsOverview = async () => {
  const { status, data } = await client('columbus-5', {
      baseURL: Endpoint.production,
    }).get<GetStatsOverviewResponse>(`api/gateway/v1/stats`);

  return {
    status,
    response: data,
  };
};
