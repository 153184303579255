import { css } from '@emotion/react';

export const container = css`
  display: block;
  padding-bottom: 160px;
  margin: 0 auto;
  text-align: center;
`;

export const heading = css`
  margin-bottom: 32px;

  font-weight: 700;
  font-size: 32px;
  line-height: 32px;

  @media all and (max-width: 640px) {
    max-width: 240px;
    margin: 0 auto 24px;

    font-size: 22px;
    line-height: 24px;
  }
`;

export const formContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0;

  @media all and (max-width: 640px) {
    display: block;
  }
`;

export const input = css`
  flex-shrink: 0;

  width: 420px;
  height: 42px;
  padding-left: 16px;
  margin-right: 16px;

  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.66);

  border: none;

  background: rgba(255, 255, 255, 0.66);
  backdrop-filter: blur(14px);
  border-radius: 4px;

  transition: 0.25s all ease;

  &::placeholder {
    color: rgba(0, 0, 0, 0.33);
  }

  &:focus {
    background: white;
    color: rgba(0, 0, 0, 0.75);
  }

  @media all and (max-width: 640px) {
    display: block;

    margin-bottom: 4px;
    width: 100%;

    font-size: 14px;
    line-height: 14px;
  }
`;

export const submitButton = css`
  flex-shrink: 0;

  width: 200px;
  height: 42px;

  background: #00eefa;
  border-radius: 4px;

  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);

  @media all and (max-width: 640px) {
    display: block;
    width: 100%;

    font-size: 14px;
    line-height: 14px;
  }
`;

export const errorMessage = css`
  margin-top: 3px;

  font-size: 14px;
  line-height: 14px;
  color: #ff0f49;

  @media all and (max-width: 640px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const description = css`
  margin-top: 48px;

  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  @media all and (max-width: 640px) {
    margin-top: 32px;

    font-size: 14px;
    line-height: 14px;
  }
`;
