import React, { useCallback, useEffect } from 'react';

const useOutsideClick = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  callback: (e: MouseEvent) => void,
) => {
  const handleClickDocument = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback(e);
      }
    },
    [callback], // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener('click', handleClickDocument, true);
    return () => {
      document.removeEventListener('click', handleClickDocument, true);
    };
  }, [handleClickDocument]);
};

export default useOutsideClick;
