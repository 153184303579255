const RegularExpression = /[+-]?([0-9]*[.])?[0-9]+/;

const getNumber = (value: string) => {
  const val = value.trim();
  let float = parseFloat(val || '0');

  if (Number.isNaN(float) || (val && !RegularExpression.test(val))) {
    return {
      type: 'error' as const,
      message: 'Please enter only numbers.',
      data: 0,
    };
  }

  if (float < 0.1) {
    return {
      type: 'error' as const,
      message: 'Please enter integer (over 0.1)',
      data: 0,
    };
  }

  return {
    type: 'success' as const,
    data: float,
  };
};

export default getNumber;
