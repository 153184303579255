import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Lottie from '../lottie';

import lottieFile from './Spinner.lottie.json';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  color?: string;
}


const hexToRgb = (hexCode: string) => {
  /* 맨 앞의 "#" 기호를 삭제하기. */
  const hex = hexCode.trim().replace('#', '');

  /* rgb로 각각 분리해서 배열에 담기. */
  let rgb = hex.length === 3
    ? hex.match(/[a-f\d]/gi)
    : hex.match(/[a-f\d]{2}/gi);

  if (!rgb) {
    rgb = [];
  }

  return rgb.reduce<number[]>((arr, str) => {
    let item = str;
    if (str.length === 1) {
      item = `${str}${str}`;
    }

    return arr.concat(parseInt(item, 16));
  }, []);
};


const SpinnerContainer = styled.div<{
  size: number;
}>(({
  size,
}) => `
  display: inline-block;

  width: ${size}px;
  height: ${size}px;
  overflow: hidden;

  svg {
    width: ${size * 2}px !important;
    height: ${size * 2}px !important;
  }
`);

const Spinner = ({
  size = 16,
  color = '#ffffff',
  ...props
}: Props) => {
  const lottieData = useMemo(() => {
    const rgb = hexToRgb(color);
    const lottieRGB = [...rgb.map(item => item / 255), 1];

    const stringifiedJSON = JSON.stringify(lottieFile)
      .replace('"$$lottie-color$$"', JSON.stringify(lottieRGB));

    return JSON.parse(stringifiedJSON);
  }, [color]);


  return (
    <SpinnerContainer
      size={size}
      {...props}
    >
      <Lottie
        css={css`
          width: ${size}px;
          height: ${size}px;
          transform: translate(-50%, -50%);
        `}
        data={lottieData}
        autoplay
        loop
      />
    </SpinnerContainer>
  );
};

export default Spinner;
