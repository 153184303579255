import { ReactComponent as CaretIcon } from 'assets/ic_caret.svg';
import { ReactComponent as CopyIcon } from 'assets/ic_copy.svg';
import PsiBdpTokenLogo from 'assets/logo/psi-bdp-token.png';
import { Badge, Button, Countdown } from 'components';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import { copy } from 'utils';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PylonGatewayPool } from '@pylon-protocol/sdk';

import BDPTokenDisclaimerModal from './BDPTokenDisclaimerModal';
import * as styles from './TokenPageClaim.style';

const DateFormat = 'MMM DD, YYYY HH:mm[Z]';

interface Props {
  symbol: string;
  pool: PylonGatewayPool;
  poolName: string;
  rewardTokens: number;
  balanceInUst: number;
  onClaim: () => void;
  onWithdraw: () => void;
  bdpTokenContract?: string;
}

const TokenPageClaimPool = ({
  symbol,
  pool,
  poolName,
  rewardTokens,
  bdpTokenContract,
  balanceInUst: depositsInUst,
  onClaim,
  onWithdraw,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [currentTime, setCurrentTime] = useState(dayjs.utc());

  const rewardClaimingStartsAt = useMemo(
    () => dayjs(pool.rewardClaimTime[0].start),
    [pool.rewardClaimTime],
  );
  const isClaimAvailable = pool.isRewardClaimable();

  const vestingEndsAt = useMemo(
    () => (!!pool.withdrawTime.length ? dayjs(pool.withdrawTime[0].end) : null),
    [pool.withdrawTime],
  );
  const isVestingValid = useMemo(() => {
    if (!vestingEndsAt) {
      return false;
    }
    if (vestingEndsAt.isBefore(new Date())) {
      return false;
    }
    return true;
  }, [vestingEndsAt]);

  const vestingInMonths = useMemo(
    () =>
      vestingEndsAt === null
        ? NaN
        : Math.round(
            vestingEndsAt.diff(
              pool.withdrawTime[0].start,
              'month',
              true, // round
            ),
          ),
    [pool.withdrawTime, vestingEndsAt],
  );
  const isVestingEnded = useMemo(
    () =>
      [
        // emergency withdrawals
        'wcoin',
        'sayve',
        'glow',
        'mine',
        'loop',
        'psi',
        'twd',
        'vkr',
        'orion',
        'whale',
        'xdefi',
        'arts',
      ].includes(symbol.toLowerCase()) || currentTime.isAfter(vestingEndsAt),
    [currentTime, symbol, vestingEndsAt],
  );

  const withdrawStartAt = useMemo(
    () =>
      !!pool.withdrawTime.length ? null : dayjs(pool.withdrawTime[0]?.start),
    [pool.withdrawTime],
  );
  const withdrawEndAt = useMemo(
    () =>
      !!pool.withdrawTime.length ? null : dayjs(pool.withdrawTime[0]?.end),
    [pool.withdrawTime],
  );
  const isWithdrawEnabled = useMemo(
    () =>
      currentTime.isAfter(withdrawStartAt) &&
      currentTime.isBefore(withdrawEndAt) &&
      pool.isWithdrawable(),
    [currentTime, pool, withdrawEndAt, withdrawStartAt],
  );

  const counter = useMemo(
    () =>
      isWithdrawEnabled ? (
        <Countdown
          to={withdrawEndAt?.format()}
          onEnd={() => {
            window.location.reload();
          }}
        />
      ) : null,
    [isWithdrawEnabled, withdrawEndAt],
  );

  const handleSyncDate = useCallback((datetime: dayjs.Dayjs) => {
    setCurrentTime(datetime);
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpened((prev) => !prev);
  }, []);

  const handleClickClaim = useCallback(() => {
    onClaim();
  }, [onClaim]);

  const handleClickWithdraw = useCallback(() => {
    onWithdraw();
  }, [onWithdraw]);

  const restTimeInText = rewardClaimingStartsAt?.from(currentTime);

  const isPsi = symbol.toLowerCase() === 'psi';
  const [isBDPTokenDisclaimerModalVisible, setBDPTokenDisclaimerModalVisible] =
    useState<boolean>(false);
  const toggleBDPTokenDisclaimerModal = useCallback(() => {
    setBDPTokenDisclaimerModalVisible((x) => !x);
  }, []);

  return (
    <styles.Container active={isClaimAvailable}>
      <Countdown to={vestingEndsAt?.format()} onChange={handleSyncDate} />
      <div css={styles.header}>
        <p css={styles.heading}>
          {`${depositsInUst.toLocaleString()} UST deposited`}
        </p>
        <p css={styles.poolInfo}>
          {`${poolName} · Vesting for ${vestingInMonths} months`}
        </p>
      </div>
      <div
        css={[
          styles.claimSummaryContainer,
          css`
            display: flex;
            flex-direction: column;
          `,
        ]}
      >
        <div>
          <p css={styles.subheading}>Accumulated Rewards</p>
          <p>{`${rewardTokens.toLocaleString()} ${symbol}`}</p>
        </div>
        {bdpTokenContract && (
          <p
            css={[
              styles.subheading,
              css`
                margin-top: 8px;
                font-weight: normal;
                line-height: 120%;

                & > span {
                  display: inline-block;
                }

                & > span.badge {
                  display: flex;
                }
              `,
            ]}
          >
            To trade bPsiDP-24m tokens, you need to add the token address below{' '}
            <span>on terra station.</span> Click to copy.
            <br />
            <BDPTokenContractBadge
              className="badge"
              as="span"
              onClick={() => copy(bdpTokenContract)}
            >
              <CopyIcon />
              {bdpTokenContract}
            </BDPTokenContractBadge>
            <BDPTokenExchangeButton onClick={toggleBDPTokenDisclaimerModal}>
              <img src={PsiBdpTokenLogo} alt="Nexus Liquid" />
              <span>
                Trade your <strong>bPsiDP-24m</strong> tokens
              </span>
            </BDPTokenExchangeButton>
            {isBDPTokenDisclaimerModalVisible && (
              <BDPTokenDisclaimerModal
                onClose={toggleBDPTokenDisclaimerModal}
              />
            )}
          </p>
        )}
        <div>
          <Button
            css={styles.claimButton}
            disabled={!isClaimAvailable || rewardTokens <= 0}
            onClick={handleClickClaim}
          >
            {isClaimAvailable
              ? 'Claim Rewards'
              : `Available ${
                  restTimeInText?.startsWith('in') ? '' : 'at '
                }${restTimeInText}`}
          </Button>
        </div>
      </div>
      {(isWithdrawEnabled || isVestingEnded) && (
        <div
          css={[
            styles.withdrawContainer,
            isPsi &&
              css`
                justify-content: flex-end;

                & > button {
                  margin-right: 0;
                  min-width: 160px;
                }
              `,
            symbol.toLowerCase() === 'mine' &&
              css`
                & > button {
                  margin-right: 0;
                  border: 1px solid rgba(255, 255, 255, 0.4);
                  min-width: 160px;
                }
              `,
          ]}
        >
          {counter}
          <Button css={styles.withdrawButton} onClick={handleClickWithdraw}>
            Withdraw Deposit
          </Button>
          {isWithdrawEnabled && (
            <span css={styles.withdrawDescription}>
              {`Deposit withdrawable until ${withdrawEndAt?.format(
                'YYYY MMM DD HH:mm[Z]',
              )}`}
            </span>
          )}
        </div>
      )}
      <div>
        <button css={styles.detailsButton} type="button" onClick={handleToggle}>
          <span>Details</span>
          <CaretIcon css={[styles.caret, isOpened && styles.caretRotated]} />
        </button>
      </div>
      <AnimatePresence initial={false}>
        {isOpened && (
          <motion.ul
            css={styles.detailList}
            variants={styles.variants}
            initial="initial"
            animate="animate"
            exit="exit"
            layout
          >
            <li>
              <p css={styles.subheading}>Rewards claimable starting</p>
              <p>{rewardClaimingStartsAt.format(DateFormat)}</p>
            </li>
            {isVestingValid && (
              <li>
                <p css={styles.subheading}>Vesting ends at</p>
                <p>{vestingEndsAt?.format(DateFormat)}</p>
              </li>
            )}
          </motion.ul>
        )}
      </AnimatePresence>
    </styles.Container>
  );
};

export default TokenPageClaimPool;

const BDPTokenContractBadge = styled(Badge)`
  width: 100%;
  max-width: fit-content;
  font-weight: 500;
  margin-top: 8px;
  background-color: #ebcbd5;
  color: #eb6989;
  border: 1px solid #eb6989;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;

  & > svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  &:before {
    display: none;
  }

  &:hover {
    transform: scale(1.02);
  }
`;

const BDPTokenExchangeButton = styled.button`
  margin-top: 16px;
  padding: 8px 20px;

  border-radius: 8px;
  border: 1px solid #56d5ec;
  background-image: linear-gradient(
    to bottom,
    rgba(86, 213, 236, 0.35),
    rgba(137, 200, 216, 0.1)
  );
  box-shadow: 0px 12px 24px rgba(36, 62, 88, 0.25);
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin-right: 6px;
    height: 24px;
    width: 24px;
  }

  & > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.85);

    & > strong {
      font-weight: 500;
      color: white;
    }
  }
`;
