import React from 'react';
import styled from '@emotion/styled';


interface BaseProps {
  width?: number;
  size?: 'default' | 'large';
  background?: string;
  color?: string;
}

export const ButtonBase = styled.button<BaseProps>(({
  width,
  size = 'default',
  background = 'white',
  color = 'rgba(0, 0, 0, 0.75)',
  disabled,
}) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: 0.25s all ease;

  ${width ? `
    width: ${width}px;
  ` : ''}

  ${size === 'default' ? `
    padding: 0 20px;
    height: 32px;
    font-size: 16px;
    line-height: 16px;

    svg {
      height: 16px;
    }
  ` : ''}

  ${size === 'large' ? `
    padding: 0 24px;
    height: 42px;
    font-size: 18px;
    line-height: 18px;

    svg {
      height: 18px;
    }
  ` : ''}

  background: ${background};
  border-radius: 4px;

  font-weight: 500;
  color: ${color};

  svg {
    width: auto;
    margin-right: 10px;

    path {
      fill: ${color};
    }
  }

  ${disabled ? `
    cursor: not-allowed;
  ` : ''}
`);


interface Props extends React.ComponentProps<typeof ButtonBase> {
}

const Button = ({
  type = 'button',
  children,
  ...props
}: Props) => (
  <ButtonBase
    {...props}
    type={type}
  >
    {children}
  </ButtonBase>
);


export default Button;
