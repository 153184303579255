import styled from '@emotion/styled';

import { motion } from 'framer-motion';
import { ExternalLink } from '..';


export const Duration = {
  ANIMATE: 0.35,
  EXIT: 0.35,
  CLOSE: 10000,
};

export const Variants = {
  visible: {
    opacity: 1,
    marginTop: 0,
    transition: { duration: Duration.ANIMATE },
  },
  hidden: {
    opacity: 0,
    marginTop: -50,
    transition: { duration: Duration.EXIT },
  },
  initial: {
    opacity: 0,
    marginTop: -50,
  },
};


export const Container = styled(motion.div)`
  display: flex;
  align-items: flex-start;

  width: 300px;
  padding: 16px;
  margin-bottom: 20px;

  background: #010a1e;
  color: white;

  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);

  @media all and (max-width: 640px) {
    width: 100%;
  }
`;


export const IconArea = styled.div<{
  intent: 'success' | 'failure';
}>`
  flex-shrink: 0;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }

  ${props => props.intent === 'success' ? `
    svg {
      fill: #00eefa;
    }
  ` : ''}

  ${props => props.intent === 'failure' ? `
    svg {
      fill: #ff125d;
    }
  ` : ''}
`;


export const ContentArea = styled.div`
  flex-grow: 1;
  padding-top: 2px;
  padding-right: 20px;
`;

export const Link = styled(ExternalLink)`
  display: inline-block;
  margin-top: 4px;

  font-size: 14px;
  color: #0d72fd;

  transition: 0.18s opacity ease;

  &:hover,
  &:focus-visible {
    opacity: 0.66;
  }
`;

export const CloseButton = styled.button`
  flex-shrink: 0;

  opacity: 0.75;
  transition: 0.18s opacity ease;

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover,
  &:focus-visible {
    opacity: 0.33;
  }
`;


export const Title = styled.p`
  line-height: 14px;
  font-size: 14px;

  color: white;
`;
